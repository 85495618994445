import React from 'react';
import { Button } from '@material-ui/core';

import styles from 'modules/Project/components/NoOptionsButton/NoOptionsButton.module.scss';

interface NoOptionsButtonProps {
  label: string;
  onClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setter: (arg0: boolean) => void;
}

const NoOptionsButton = ({ label, onClickHandler, setter }: NoOptionsButtonProps) => (
  <Button
    className={styles.noOptionsButton}
    variant='contained'
    onClick={onClickHandler}
    onMouseEnter={(_e) => setter(true)}
    onMouseLeave={(_e) => setter(false)}
  >
    {`Add New ${label}`}
  </Button>
);

export default NoOptionsButton;
