import React from 'react';
import { Container } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './DashboardHeadingSkeleton.module.scss';

export const DashboardHeadingSkeleton: React.FC = () => (
  <Container className={styles.dashboardHeadingSkeleton}>
    <Skeleton height='40px' variant='rect' width='132px' />
    <Skeleton height='2px' variant='rect' width='236px' />
    <Skeleton height='24px' variant='rect' width='100px' />
    <Skeleton height='48px' variant='circle' width='48px' />
  </Container>
);
