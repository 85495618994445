import React, { useContext, useState } from 'react';
import { AnchorContext } from 'context/AnchorProvider';
import { noop } from 'lodash';

import { KTableRow } from 'components/KTable/KTable';
import { DefaultSortMenu } from 'components/Menus/DefaultSortMenu/DefaultSortMenu';
import { SortByNameMenu } from 'components/Menus/SortByNameMenu/SortByNameMenu';
import { PIFTable } from 'components/PIFTable/PIFTable';
import { TeamPosition } from 'types/Project/TeamPosition';
import { exists } from 'utils/generators/generators';
import { AbstractHeader, AbstractTableProps, asHeader, HeaderMenuCallback, rowsOf } from 'utils/tables/tableUtils';

import { TeamRosterAppraiserRow } from './TeamRosterAppraiserRow/TeamRosterAppraiserRow';

enum MenuAnchors {
  DEFAULT = 'default',
  BY_NAME = 'byName',
}

export const TeamRosterAppraiserTable: React.FC<AbstractTableProps<TeamPosition>> = ({
  data: teamMembers,
  currentPage,
  itemsPerPage,
  totalItems,
  onPaginationChange,
  onColumnSort = noop,
  onItemsPerPageChange,
}) => {
  const [columnName, updateColumnName] = useState<string | string[] | undefined>(undefined);
  const { anchorStatus, showMenu, closeMenu } = useContext(AnchorContext);

  const createMenuDispatch =
    (anchorName: string): HeaderMenuCallback =>
    (anchor, selectedColumnName) => {
      showMenu(anchorName, anchor);
      updateColumnName(selectedColumnName);
    };

  const projectTableAbstractHeaders: AbstractHeader[] = [
    {
      id: '1',
      label: 'Ksquarian',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.BY_NAME]) && columnName === 'ksquarian',
        onClick: createMenuDispatch(MenuAnchors.BY_NAME),
        columnFieldName: ['employeeProjectPositions.employee.firstName', 'employeeProjectPositions.employee.lastName'],
      },
      style: { minWidth: '184px' },
    },
    {
      id: '2',
      label: 'Position',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.BY_NAME]) && columnName === 'position',
        onClick: createMenuDispatch(MenuAnchors.BY_NAME),
        columnFieldName: 'position.name',
      },
      style: { minWidth: '192px' },
    },
    {
      id: '3',
      label: 'Weekly Forecasted Hours',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'weeklyForecastedHours',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'weeklyForecastedHours',
      },
      style: { minWidth: '184px' },
    },
    {
      id: '4',
      label: 'Cost Rate',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'costRate',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'costRate',
      },
      style: { minWidth: '184px' },
    },
    {
      id: '5',
      label: 'Bill Rate',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'billRate',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'billRate',
      },
      style: { minWidth: '248px' },
    },
    {
      id: '6',
      label: 'Time Frame',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'onboarding',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'startDate',
      },
      style: { minWidth: '200px' },
    },
    {
      id: '7',
      label: 'Business Days',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'businessDaysBetweenDates',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'businessDaysBetweenDates',
      },
      style: { minWidth: '200px' },
    },
  ];

  const asTeamMemberRow = (teamMember: TeamPosition): KTableRow => ({
    sectionRows: [<TeamRosterAppraiserRow key={teamMember.id} teamMember={teamMember} />],
    rowId: teamMember.id.toString(),
  });

  const handleChangeItemsPerPage = (items: number): void => {
    onItemsPerPageChange(items);
  };

  const handleChangePage = (nextPage: number): void => {
    onPaginationChange(nextPage);
  };

  return (
    <React.Fragment>
      <PIFTable
        currentPage={currentPage}
        headers={projectTableAbstractHeaders.map(asHeader)}
        itemsPerPage={itemsPerPage}
        rows={rowsOf(teamMembers, asTeamMemberRow)}
        totalItems={totalItems}
        typeShowed='job position'
        onChangeItemsPerPage={handleChangeItemsPerPage}
        onChangePage={handleChangePage}
      />
      <SortByNameMenu
        anchorEl={anchorStatus[MenuAnchors.BY_NAME]}
        id='positionsNameSortMenu'
        onClose={() => closeMenu(MenuAnchors.BY_NAME)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
      <DefaultSortMenu
        anchorEl={anchorStatus[MenuAnchors.DEFAULT]}
        id='positionsDefaultSortMenu'
        onClose={() => closeMenu(MenuAnchors.DEFAULT)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
    </React.Fragment>
  );
};
