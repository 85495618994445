import { PayloadAction } from '@reduxjs/toolkit';

import { CurrencyState } from 'store/currency/index';
import { Currency } from 'types/Currency';
import { RequestStatus } from 'types/RequestStatus';

const setCurrencyData = (state: CurrencyState, action: PayloadAction<Currency[]>): void => {
  state.currency = action.payload;
};

const setCurrencyRequestStatus = (state: CurrencyState, action: PayloadAction<RequestStatus>): void => {
  state.currencyRequestStatus = action.payload;
};

const reducers = {
  setCurrencyData,
  setCurrencyRequestStatus,
};

export default reducers;
