import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { FieldInputProps, FormikErrors, FormikValues, getIn } from 'formik';

import { TextFieldTypes } from 'constants/textFieldTypes';
import styles from 'modules/Project/components/KTextField/KTextField.module.scss';
import { IndexSignature } from 'types/utils';

interface KTextFieldProps {
  errors: FormikErrors<FormikValues>;
  label: string;
  maxLength?: number;
  multiline?: boolean;
  name: string;
  rows?: number;
  type: TextFieldTypes;
  values: FormikValues;
  getFieldProps: (field: string) => FieldInputProps<string | number>;
  setFieldValue: (field: string, value: string | null) => void;
}

const INPUT_REPLACE_VALUES: IndexSignature<RegExp> = {
  [TextFieldTypes.FLOAT]: /[^0-9.]/gi,
  [TextFieldTypes.INTEGER]: /[^0-9]/gi,
};

export const KTextField: FC<KTextFieldProps> = ({
  errors,
  label,
  maxLength,
  multiline,
  name,
  rows,
  type,
  values,
  getFieldProps,
  setFieldValue,
}: KTextFieldProps) => {
  const handleChange = (value: string): string => {
    if (value && (type === TextFieldTypes.FLOAT || type === TextFieldTypes.INTEGER)) {
      return value.replace(INPUT_REPLACE_VALUES[type], '');
    }
    return value;
  };

  const getInputValue = (value: string) => {
    if (value && (type === TextFieldTypes.FLOAT || type === TextFieldTypes.INTEGER)) {
      return (+value).toLocaleString();
    }
    return value;
  };

  return (
    <TextField
      {...getFieldProps(name)}
      InputLabelProps={{ className: styles.label }}
      InputProps={{
        className: clsx(multiline && styles.multilineInput),
        inputProps: { className: styles.input, maxLength },
      }}
      aria-label={label}
      className={styles.textField}
      error={!!getIn(errors, name)}
      fullWidth
      helperText={getIn(errors, name) ? getIn(errors, name) : ''}
      id={name}
      label={label}
      multiline={multiline}
      name={name}
      rows={rows}
      type='text'
      value={getInputValue(getIn(values, name) ?? '')}
      variant='outlined'
      onChange={(event) => setFieldValue(name, handleChange(event.target.value))}
    />
  );
};
