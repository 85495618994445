import React, { FC } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FieldInputProps, FormikErrors, FormikTouched, FormikValues, getIn } from 'formik';

import styles from 'modules/Project/components/KCalendarField/KCalendarField.module.scss';

interface KCalendarFieldProps {
  errors: FormikErrors<FormikValues>;
  label: string;
  maxValue?: string | null;
  minValue?: string | null;
  name: string;
  touched: FormikTouched<FormikValues>;
  values: FormikValues;
  getFieldProps: (field: string) => FieldInputProps<string | null>;
  setFieldValue: (field: string, value: string) => void;
}

export const KCalendarField: FC<KCalendarFieldProps> = ({
  errors,
  label,
  maxValue,
  minValue,
  name,
  values,
  getFieldProps,
  setFieldValue,
}: KCalendarFieldProps) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardDatePicker
      {...getFieldProps(name)}
      InputProps={{ classes: { input: styles.dateText } }}
      KeyboardButtonProps={{
        'aria-label': label,
      }}
      PopoverProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'left' },
      }}
      autoOk
      className={styles.date}
      disableToolbar
      error={!!getIn(errors, name)}
      format='MM/dd/yyyy'
      fullWidth
      helperText={getIn(errors, name) ? getIn(errors, name) : ''}
      id={label}
      inputVariant='outlined'
      label={label}
      margin='normal'
      maxDate={maxValue}
      minDate={minValue}
      name={name}
      type='button'
      value={getIn(values, name)}
      variant='inline'
      onChange={(value) => value && setFieldValue(name, value.toISOString())}
    />
  </MuiPickersUtilsProvider>
);
