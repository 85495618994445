import React, { FC, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination, { PaginationRenderItemParams } from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import { FormikProps, getIn } from 'formik';
import { has } from 'lodash';

import { ControlledAutoComplete } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { Rol } from 'constants/Credentials/Rol';
import { RequestStatus } from 'constants/requestStatus';
import { TextFieldTypes } from 'constants/textFieldTypes';
import styles from 'modules/Project/components/AddPositionModal/AddPositionModal.module.scss';
import { CloseEvent } from 'modules/Project/components/AddPositionModal/types';
import EmployeeProjectSelectAutocomplete from 'modules/Project/components/EmployeeProjectSelectAutocomplete/EmployeeProjectSelectAutocomplete';
import { KCalendarField } from 'modules/Project/components/KCalendarField/KCalendarField';
import { KMoneyField } from 'modules/Project/components/KMoneyField/KMoneyField';
import { KTextField } from 'modules/Project/components/KTextField/KTextField';
import { SelectAutoComplete } from 'modules/Project/components/SelectAutocomplete/SelectAutoComplete';
import { selectCurrencyData } from 'store/currency/selectors';
import { selectPositionData } from 'store/position/selectors';
import { getAllPositions } from 'store/position/thunk';
import {
  selectEmployeeAvailabilityRequestStatus,
  selectEmployeeAvailabilitySelectOptions,
} from 'store/projects/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import { selectTeamPositions } from 'store/teamPosition/selectors';
import { selectTechnologiesData } from 'store/technologies/selectors';
import { getAllTechnologies } from 'store/technologies/thunk';
import { PIFProject } from 'types/PIFProject';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { mapTechnologiesToOptions } from 'utils/parsers/technologies';
import { hasRole } from 'utils/roles';

interface AddPositionModalProps {
  editIndex: number;
  formik: FormikProps<PIFProject>;
  isEdited: boolean;
  onClose: (eventType: CloseEvent) => void;
  onCreateItem: (type: string, value: string) => void;
}

const AddPositionModal: FC<AddPositionModalProps> = ({
  formik,
  editIndex,
  isEdited,
  onClose,
  onCreateItem,
}: AddPositionModalProps) => {
  const [index, setIndex] = useState<number>(editIndex);
  const { dirty, isValid, values, setFieldValue } = formik;

  const formikTeamValues = values.projectTeam;

  const jobPosition = getIn(values, `projectTeam[${index}].jobPosition`);
  const onboarding = getIn(values, `projectTeam[${index}].startDate`);
  const currentTechnologies = getIn(values, `projectTeam[${index}].technologies`);

  const dispatch = useAppDispatch();

  const positionsData = useAppSelector(selectPositionData);
  const currencyData = useAppSelector(selectCurrencyData);
  const technologiesData = useAppSelector(selectTechnologiesData);
  const currentTeamPositions = useAppSelector(selectTeamPositions);

  const initialTeamPositionLength = currentTeamPositions.length;
  const [numberOfTeamPositions, setNumberOfTeamPositions] = useState<number>(initialTeamPositionLength);

  const roles = useAppSelector(selectSessionRoles);
  const isCoCreator = hasRole(roles, Rol.CO_CREATOR);

  //
  // Project Employee Availability
  // ----------------------------------------------------------------------

  const employeeAvailabilityRequestStatus = useAppSelector(selectEmployeeAvailabilityRequestStatus);
  const employeeAvailabilitySelectOptions = useAppSelector(selectEmployeeAvailabilitySelectOptions);

  useEffect(() => {
    dispatch(getAllPositions());
    dispatch(getAllTechnologies());
  }, [dispatch]);

  const handleAddNewTeamPosition = () => {
    setNumberOfTeamPositions(numberOfTeamPositions + 1);

    if (isEdited) {
      setIndex(numberOfTeamPositions);
    } else {
      setIndex(numberOfTeamPositions + 1);
    }

    const formikTeamValuesUpdated = [...formikTeamValues];
    formikTeamValuesUpdated.push({
      billRate: null,
      currencyId: values.currencyId,
      employeeId: null,
      jobPosition: null,
      endDate: null,
      startDate: null,
      skills: [],
      technologies: [],
      weeklyForecastedHours: null,
    });
    setFieldValue('projectTeam', formikTeamValuesUpdated);
  };

  const handleAddDuplicateTeamPosition = () => {
    setNumberOfTeamPositions(numberOfTeamPositions + 1);

    if (isEdited) {
      setIndex(numberOfTeamPositions);
    } else {
      setIndex(numberOfTeamPositions + 1);
    }

    const teamPositiontoDuplicate = formikTeamValues[index];

    const formikTeamValuesUpdated = [...formikTeamValues];
    formikTeamValuesUpdated.push({
      billRate: teamPositiontoDuplicate.billRate,
      currencyId: teamPositiontoDuplicate.currencyId,
      employeeId: null,
      jobPosition: teamPositiontoDuplicate.jobPosition,
      endDate: teamPositiontoDuplicate.endDate,
      startDate: teamPositiontoDuplicate.startDate,
      skills: teamPositiontoDuplicate.skills,
      technologies: teamPositiontoDuplicate.technologies,
      weeklyForecastedHours: teamPositiontoDuplicate.weeklyForecastedHours,
    });
    setFieldValue('projectTeam', formikTeamValuesUpdated);
  };

  const handleRemoveTeamPosition = () => {
    setNumberOfTeamPositions(numberOfTeamPositions - 1);

    if (index > 0) {
      setIndex(index - 1);
    } else {
      setIndex(0);
    }

    const formikTeamValuesUpdated = [...formikTeamValues];
    formikTeamValuesUpdated.splice(index, 1);
    setFieldValue('projectTeam', formikTeamValuesUpdated);
  };

  const handleCancel = () => {
    onClose(CloseEvent.CANCEL);
    if (formikTeamValues.length > initialTeamPositionLength) {
      const existingFormikTeamValues = formikTeamValues.filter((teamValue) => has(teamValue, 'id'));
      setFieldValue('projectTeam', existingFormikTeamValues);
    }
  };

  const handlePageChange = (_event: unknown, value: number) => {
    setIndex(value - 1);
  };

  const renderPaginationItem = (item: PaginationRenderItemParams) => {
    let className = '';
    if (item.type === 'page') {
      const formikErrors = formik.errors.projectTeam;

      if (index === item.page - 1) {
        className = styles.active;
      }

      if (formikErrors) {
        if (formikErrors[item.page - 1] !== undefined) {
          className = styles.filled;
        }
      }
    }

    return <PaginationItem {...item} className={className} />;
  };

  return (
    <div className={styles.modalContainer}>
      <Box display='flex' flexDirection='column' height='100%' mb='56px' mt='28px' mx='56px'>
        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <Typography className={styles.addNewPositionTitle} variant='h1'>
            New Position
          </Typography>
          <Box alignItems='center' display='flex' justifyContent='flex-end'>
            <Tooltip title='Delete Position'>
              <span>
                <IconButton
                  className={styles.icon}
                  disabled={isEdited ? numberOfTeamPositions === 1 : numberOfTeamPositions === 0}
                  onClick={handleRemoveTeamPosition}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Close'>
              <IconButton onClick={handleCancel}>
                <Close htmlColor='red' />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-start'>
          <Typography className={styles.subtitle} variant='h1'>
            Role Position
          </Typography>
        </Box>
        <Grid
          className={isCoCreator ? styles.roleContainerCocreator : styles.roleContainer}
          container
          justifyContent='center'
          spacing={0}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectAutoComplete
                {...formik}
                label='Job Position'
                name={`projectTeam[${index}].jobPosition`}
                tags={positionsData.map(({ name, id }) => ({ label: name, value: id }))}
                values={values}
                onCreateItem={onCreateItem}
              />
            </Grid>
            {jobPosition && (
              <Grid item xs={6}>
                <ControlledAutoComplete
                  defaultValues={mapTechnologiesToOptions(technologiesData, currentTechnologies)}
                  label='Technologies'
                  name={`projectTeam[${index}].technologies`}
                  options={technologiesData.map(({ id, name }) => ({ label: name, value: id }))}
                  onCreateItem={onCreateItem}
                  onSetFieldValue={setFieldValue}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {jobPosition && isCoCreator && (
          <Grid className={styles.ksquarianContainer} container spacing={2}>
            <Grid item xs={6}>
              <EmployeeProjectSelectAutocomplete
                {...formik}
                className={styles.selectAutocomplete}
                label='Select Ksquarian'
                loading={employeeAvailabilityRequestStatus === RequestStatus.LOADING}
                name={`projectTeam[${index}].employeeId`}
                options={employeeAvailabilitySelectOptions}
              />
            </Grid>
          </Grid>
        )}
        {jobPosition && (
          <React.Fragment>
            <Box display='flex' justifyContent='flex-start'>
              <Typography className={styles.subtitle} variant='h1'>
                Specs
              </Typography>
            </Box>
            <Grid className={styles.specsContainer} container justifyContent='center' spacing={0}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <KTextField
                    {...formik}
                    label='Weekly Hours Forecasted'
                    name={`projectTeam[${index}].weeklyForecastedHours`}
                    type={TextFieldTypes.INTEGER}
                  />
                </Grid>
                <Grid item xs={6}>
                  <KMoneyField
                    {...formik}
                    currencyName={`projectTeam[${index}].currencyId`}
                    currencyOptions={currencyData}
                    isDisabled
                    label='Bill Rate'
                    name={`projectTeam[${index}].billRate`}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KCalendarField
                      {...formik}
                      label='Onboarding Date'
                      maxValue={values.endDate}
                      minValue={values.startDate}
                      name={`projectTeam[${index}].startDate`}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KCalendarField
                      {...formik}
                      label='Offboarding Date'
                      maxValue={values.endDate}
                      minValue={onboarding}
                      name={`projectTeam[${index}].endDate`}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <Box display='flex' justifyContent='flex-start'>
          <Button
            className={clsx(styles.button, styles.buttonNewOrDuplicate)}
            variant='contained'
            onClick={handleAddNewTeamPosition}
          >
            New Position
          </Button>
          <Button
            className={clsx(styles.button, styles.buttonNewOrDuplicate)}
            variant='contained'
            onClick={handleAddDuplicateTeamPosition}
          >
            Duplicate Position
          </Button>
        </Box>
        <Box alignItems='center' display='flex' justifyContent='space-between' mt='auto'>
          <Button
            className={styles.buttonSaveHide}
            disabled={!(isValid && dirty)}
            variant='contained'
            onClick={() => onClose(CloseEvent.SAVE)}
          >
            Save
          </Button>
          <Pagination
            color='primary'
            count={isEdited ? numberOfTeamPositions : numberOfTeamPositions + 1}
            page={index + 1}
            renderItem={renderPaginationItem}
            shape='rounded'
            showFirstButton
            showLastButton
            onChange={handlePageChange}
          />
          <Button
            className={styles.buttonSave}
            disabled={!(isValid && dirty)}
            variant='contained'
            onClick={() => onClose(CloseEvent.SAVE)}
          >
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AddPositionModal;
