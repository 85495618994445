import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

import { selectIsAuth } from 'store/session/selectors';
import { useAppSelector } from 'utils/hooks/storeHooks';

interface IPrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps>;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const isAuthenticated = useAppSelector(selectIsAuth);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
