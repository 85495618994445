import React from 'react';
import NumberFormat from 'react-number-format';
import { Card, Grid } from '@material-ui/core';
import clsx from 'clsx';

import styles from './ProjectDetailsOverview.module.scss';

interface ProjectDetailsOverviewProps {
  budget: number;
  className?: string;
  cost: number;
  currencySymbol: string;
}

export const ProjectDetailsOverview: React.FC<ProjectDetailsOverviewProps> = ({
  className,
  cost,
  currencySymbol,
  budget,
}) => (
  <Grid className={clsx(styles.projectDetailsOverview, className)} item xs={12}>
    <Card className={styles.projectDetailsOverviewContent}>
      <span className={styles.projectDetailsOverviewContentTitle}>Overview</span>
      <div className={styles.projectDetailsOverviewContentCosts}>
        <div className={styles.projectDetailsOverviewContentCostsSection}>
          <span className={styles.projectDetailsOverviewContentCostsSectionTitle}>Budget</span>
          <NumberFormat
            className={styles.projectDetailsOverviewContentCostsSectionTotal}
            decimalScale={2}
            displayType='text'
            fixedDecimalScale
            prefix={currencySymbol}
            thousandSeparator
            value={budget}
          />
        </div>
        <div className={styles.projectDetailsOverviewContentCostsSectionSeparator} />
        <div className={styles.projectDetailsOverviewContentCostsSection}>
          <span className={styles.projectDetailsOverviewContentCostsSectionTitle}>Cost</span>
          <NumberFormat
            className={styles.projectDetailsOverviewContentCostsSectionTotal}
            decimalScale={2}
            displayType='text'
            fixedDecimalScale
            prefix={currencySymbol}
            thousandSeparator
            value={cost}
          />
        </div>
        <div className={styles.projectDetailsOverviewContentCostsSectionSeparator} />
        <div className={styles.projectDetailsOverviewContentCostsSection}>
          <span className={styles.projectDetailsOverviewContentCostsSectionTitle}>Profitability</span>
          <NumberFormat
            className={styles.projectDetailsOverviewContentCostsSectionTotal}
            decimalScale={2}
            displayType='text'
            fixedDecimalScale
            prefix={currencySymbol}
            thousandSeparator
            value={budget - cost}
          />
        </div>
      </div>
    </Card>
  </Grid>
);
