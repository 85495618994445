import { BeachAccess, Dashboard, FlightTakeoff, MonetizationOn, Restaurant, ShowChart } from '@material-ui/icons';

import { ReactComponent as Add } from 'assets/images/Add.svg';
import { ReactComponent as Bereavement } from 'assets/images/Bereavement.svg';
import { ReactComponent as BereavementWhite } from 'assets/images/BereavementWhite.svg';
import { ReactComponent as Burger } from 'assets/images/Burger.svg';
import { ReactComponent as Camera } from 'assets/images/Camera.svg';
import { ReactComponent as Christmas } from 'assets/images/Christmas.svg';
import { ReactComponent as CreditCard } from 'assets/images/CreditCard.svg';
import { ReactComponent as Door } from 'assets/images/Door.svg';
import { ReactComponent as DownArrow } from 'assets/images/DownArrow.svg';
import { ReactComponent as HappyFriday } from 'assets/images/HappyFriday.svg';
import { ReactComponent as Image } from 'assets/images/Image.svg';
import { ReactComponent as MedicalInsurance } from 'assets/images/MedicalInsurance.svg';
import { ReactComponent as NotificationMarkRead } from 'assets/images/NotificationMarkRead.svg';
import { ReactComponent as ParentalLeave } from 'assets/images/ParentalLeave.svg';
import { ReactComponent as ParentLeaveWhite } from 'assets/images/ParentalLeaveWhite.svg';
import { ReactComponent as Parenting } from 'assets/images/Parenting.svg';
import { ReactComponent as Rings } from 'assets/images/Rings.svg';
import { ReactComponent as SandClock } from 'assets/images/SandClock.svg';
import { ReactComponent as SandClockWhite } from 'assets/images/SandClockWhite.svg';
import { ReactComponent as Savings } from 'assets/images/savingsIcon.svg';
import { ReactComponent as SickLeave } from 'assets/images/SickLeave.svg';
import { ReactComponent as SickLeaveWhite } from 'assets/images/SickLeaveWhite.svg';
import { ReactComponent as SortAZ } from 'assets/images/sortAZ.svg';
import { ReactComponent as SortZA } from 'assets/images/sortZA.svg';
import { ReactComponent as ThumbDown } from 'assets/images/ThumbDown.svg';
import { ReactComponent as ThumbDownWhite } from 'assets/images/ThumbDownWhite.svg';
import { ReactComponent as ThumbUp } from 'assets/images/ThumbUp.svg';
import { ReactComponent as ThumbUpWhite } from 'assets/images/ThumbUpWhite.svg';
import { ReactComponent as Time } from 'assets/images/Time.svg';
import { ReactComponent as Timesheet } from 'assets/images/Timesheet.svg';
import { ReactComponent as TimeWhite } from 'assets/images/TimeWhite.svg';
import { ReactComponent as Training } from 'assets/images/Training.svg';
import { ReactComponent as UpArrow } from 'assets/images/UpArrow.svg';

const icons = {
  BeachAccess,
  Bereavement,
  Burger,
  Camera,
  Christmas,
  CreditCard,
  Dashboard,
  Door,
  FlightTakeoff,
  HappyFriday,
  Image,
  MedicalInsurance,
  MonetizationOn,
  NotificationMarkRead,
  ParentalLeave,
  Parenting,
  Restaurant,
  Rings,
  SandClock,
  Savings,
  ShowChart,
  SickLeave,
  ThumbDown,
  ThumbUp,
  Time,
  Timesheet,
  Training,
  SortAZ,
  SortZA,
  UpArrow,
  DownArrow,
  Add,
};

type DefaultIcons = keyof typeof icons;

const whiteIcons = {
  BeachAccess,
  Bereavement: BereavementWhite,
  Burger,
  Camera,
  Christmas,
  CreditCard,
  Dashboard,
  Door,
  FlightTakeoff,
  HappyFriday,
  Image,
  MedicalInsurance,
  MonetizationOn,
  NotificationMarkRead,
  ParentalLeave: ParentLeaveWhite,
  Parenting,
  Restaurant,
  Rings,
  SandClock: SandClockWhite,
  Savings,
  ShowChart,
  SickLeave: SickLeaveWhite,
  ThumbDown: ThumbDownWhite,
  ThumbUp: ThumbUpWhite,
  Time: TimeWhite,
  Timesheet,
  Training,
  SortZA,
  SortAZ,
  UpArrow,
  DownArrow,
  Add,
};

type WhiteIcons = keyof typeof whiteIcons;
export type IconVariant = 'default' | 'white';

export type KIcons = DefaultIcons | WhiteIcons;

interface getIconProps {
  name: KIcons;
  variant?: IconVariant;
}
// TODO: Implement default case
export const getIcon = ({ name, variant }: getIconProps) => (variant === 'white' ? whiteIcons[name] : icons[name]);
