/* eslint-disable camelcase */
import { Rol } from 'constants/Credentials/Rol';
import { Credentials } from 'types/Credentials/Credentials';
import { CredentialsModel } from 'types/Credentials/CredentialsModel';

export const asCredentials = ({ refresh_token, roles, token, user, expires }: CredentialsModel): Credentials => ({
  expires,
  token,
  user,
  roles: roles.map<Rol>(({ name }) => name as Rol),
  refreshToken: {
    ...refresh_token,
    expiresIn: refresh_token.expires_in,
  },
});
