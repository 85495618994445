import { createSlice } from '@reduxjs/toolkit';

import { Customer } from 'types/Project/Customer';
import { RequestStatus } from 'types/RequestStatus';

import reducers from './reducers';

export interface PositionState {
  customer: Customer[];
  customerRequestStatus: RequestStatus;
}

const initialState: PositionState = {
  customer: [],
  customerRequestStatus: 'loading',
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers,
});

export const { setCustomerData, setCustomerRequestStatus } = customerSlice.actions;

export default customerSlice.reducer;
