import React from 'react';
import { TableCell, Typography } from '@material-ui/core';

import styles from './RejectionDetails.module.scss';

interface RejectionDetailsProps {
  colSpan: number;
  message?: string;
}

export const RejectionDetails: React.FC<RejectionDetailsProps> = ({ message, colSpan }) => (
  <TableCell className={styles.rejectionDetails} colSpan={colSpan}>
    <div className={styles.rejectionDetailsContent}>
      <Typography className={styles.rejectionDetailsTitle}>Rejection Note:</Typography>
      <Typography className={styles.rejectionDetailsMessage}>{message}</Typography>
    </div>
  </TableCell>
);
