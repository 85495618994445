import { customerService } from 'services/customerService';
import { AppThunk } from 'store/store';
import { fieldSorter } from 'utils/fieldSorter';
import { mapCustomerModelToCustomer } from 'utils/parsers/customer';

import { setCustomerData, setCustomerRequestStatus } from '.';

export const getAllCustomers = (): AppThunk => async (dispatch) => {
  dispatch(setCustomerRequestStatus('loading'));
  try {
    const { data } = await customerService.getAll();
    const sortedCustomers = fieldSorter(data, 'name');
    const positions = mapCustomerModelToCustomer(sortedCustomers);

    dispatch(setCustomerData(positions));
    dispatch(setCustomerRequestStatus('success'));
  } catch (error) {
    dispatch(setCustomerRequestStatus('failed'));
  }
};
