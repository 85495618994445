import React, { FC } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { selectCurrencyData } from 'store/currency/selectors';
import { TeamPosition } from 'types/Project/TeamPosition';
import { Technology } from 'types/Project/Technology';
import { enFormatDate } from 'utils/formatters/date';
import { useAppSelector } from 'utils/hooks/storeHooks';

import styles from './TeamPositionDetails.module.scss';

interface TeamPositionDetailsProps {
  teamMember: TeamPosition;
}

export const TeamPositionDetails: FC<TeamPositionDetailsProps> = ({ teamMember }: TeamPositionDetailsProps) => {
  const currencyData = useAppSelector(selectCurrencyData);
  const getTechnologySet = (technologySet: Technology[]) => {
    const technologies = technologySet.map(({ name }) => name);
    return technologies.length > 1
      ? technologies
          .slice(0, technologies.length - 1)
          .join(', ')
          .concat(` and ${technologies[technologies.length - 1]}`)
      : technologies;
  };

  const currency = currencyData.find(({ id }) => id === teamMember.currencyId);

  return (
    <Grid container>
      <Grid className={styles.roleContainer} container item spacing={3}>
        <Grid item xs={12}>
          <Typography className={styles.subtitle}>Role Position</Typography>
        </Grid>
        <Grid item xs={5}>
          <Box>
            <Typography className={styles.description}>Job Position</Typography>
            <Typography variant='body2'>{teamMember.position?.name}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography className={styles.description}>Technologies</Typography>
            <Typography variant='body2'>{getTechnologySet(teamMember.technologies)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography className={styles.description}>Ksquarian</Typography>
          <Typography variant='body2'>
            {teamMember.employee
              ? `${teamMember.employee.firstName} ${teamMember.employee.lastName}`
              : 'No one assigned'}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.specsContainer} container item spacing={3}>
        <Grid item xs={12}>
          <Typography className={styles.subtitle}>Specs</Typography>
        </Grid>
        <Grid item xs={5}>
          <Box>
            <Typography className={styles.description}>Weekly Hours Forecasted</Typography>
            <Typography variant='body2'>{teamMember.weeklyForecastedHours}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography className={styles.description}>{`Bill Rate (Hrs/${currency?.currency})`}</Typography>
            <Typography variant='body2'>{`${currency?.currencySymbol} ${teamMember.billRate}`}</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Typography className={styles.description}>Onboarding Date</Typography>
          <Typography variant='body2'>{enFormatDate(teamMember.startDate)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.description}>Offboarding Date</Typography>
          <Typography variant='body2'>{enFormatDate(teamMember.endDate)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.description}>Business Days</Typography>
          <Typography variant='body2'>{teamMember.businessDaysBetweenDates}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
