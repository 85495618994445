import { createSlice } from '@reduxjs/toolkit';

import { Employee } from 'types/Employee';
import { RequestStatus } from 'types/RequestStatus';

import reducers from './reducers';

export interface EmployeeState {
  count: number;
  data: Employee;
  employees: Employee[];
  employeesRequestStatus: RequestStatus;
  requestStatus: RequestStatus;
}

const initialState: EmployeeState = {
  count: 0,
  data: {
    country: '',
    firstName: '',
    hiringDate: '',
    imageUrl: '',
    lastName: '',
    personalEmail: '',
    phone: '',
    position: '',
  },
  employees: [],
  employeesRequestStatus: 'loading',
  requestStatus: 'idle',
};

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers,
});

export const { setEmployeeCount, setEmployees, setEmployeesStatus, setEmployeeData, setEmployeeRequestStatus } =
  employeeSlice.actions;

export default employeeSlice.reducer;
