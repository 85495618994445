import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Avatar, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { FormikProps } from 'formik';
import { useSnackbar } from 'notistack';

import { PIFTooltip } from 'components/Tooltips/PIFTooltip';
import { Rol } from 'constants/Credentials/Rol';
import { DefaultPermissions } from 'constants/defaultPermissions';
import { RequestStatus } from 'constants/requestStatus';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import EmployeeProjectSelectAutocomplete from 'modules/Project/components/EmployeeProjectSelectAutocomplete/EmployeeProjectSelectAutocomplete';
import styles from 'modules/Project/components/TeamRosterTable/TeamRosterRow/TeamRosterRow.module.scss';
import { selectEmployeeCount } from 'store/employee/selectors';
import { selectResponseData } from 'store/errors/selectors';
import { selectProjectDetailsData } from 'store/projectInitiationForm/selectors';
import {
  selectEmployeeAvailabilityRequestStatus,
  selectEmployeeAvailabilitySelectOptions,
} from 'store/projects/selectors';
import { getEmployeeAvailabilityByProjectId } from 'store/projects/thunks';
import { selectSessionRoles } from 'store/session/selectors';
import { selectTeamPositions, selectTeamPositionUpdateStatus } from 'store/teamPosition/selectors';
import { updateJobPosition } from 'store/teamPosition/thunks';
import { Permissions } from 'types/Permissions';
import { PIFProject } from 'types/PIFProject';
import { ProjectStatus } from 'types/Project';
import { TeamPosition } from 'types/Project/TeamPosition';
import { Technology } from 'types/Project/Technology';
import { checkTeamPermissions } from 'utils/checkTeamPermissions';
import { titleCase } from 'utils/formatters/text';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';
import { renderTooltip } from 'utils/tables/tooltips';

interface TeamRosterRowProps {
  formik: FormikProps<PIFProject>;
  teamMember: TeamPosition;
  onClickMenu: (event: React.MouseEvent, projectId: string) => void;
  onClickView: (teamPosition: TeamPosition) => void;
}

export const TeamRosterRow: FC<TeamRosterRowProps> = ({ formik, teamMember, onClickMenu, onClickView }) => {
  const technologiesContainer = useRef<HTMLDivElement>(null);
  const positionContainer = useRef<HTMLDivElement>(null);
  const ksquarianContainer = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState<number>(0);
  const [currentPermissions, setCurrentPermissions] = useState<Permissions>(DefaultPermissions);
  const projectTeamData = useAppSelector(selectTeamPositions);
  const projectDetailsData = useAppSelector(selectProjectDetailsData);
  const roles = useAppSelector(selectSessionRoles);
  const errorResponse = useAppSelector(selectResponseData);
  const groupTechnologies = (technologies: Technology[]) => technologies?.map(({ name }) => name).join(', ');
  const getAcronym = (position: string) => position.charAt(0).toUpperCase();
  const ksquarian = teamMember.employee
    ? `${teamMember.employee.firstName} ${teamMember.employee.lastName}`
    : 'No one assigned';
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [employeeAssign, setEmployeeAssign] = useState(false);
  //
  // Project Employee Availability
  // ----------------------------------------------------------------------

  const employeeCount = useAppSelector(selectEmployeeCount);
  const employeeAvailabilityRequestStatus = useAppSelector(selectEmployeeAvailabilityRequestStatus);
  const employeeAvailabilitySelectOptions = useAppSelector(selectEmployeeAvailabilitySelectOptions);

  useEffect(() => {
    dispatch(getEmployeeAvailabilityByProjectId(projectDetailsData.id, '', employeeCount));
  }, [dispatch, employeeCount, projectDetailsData.id]);

  // ----------------------------------------------------------------------

  useEffect(() => {
    setIndex(projectTeamData.findIndex(({ id }) => id.toString() === teamMember.id.toString()));
  }, [projectTeamData, teamMember]);
  const updatePositionStatusRequest = useAppSelector(selectTeamPositionUpdateStatus);

  useEffect(() => {
    if (roles && projectDetailsData.creationStep) {
      setCurrentPermissions(checkTeamPermissions(roles, projectDetailsData.creationStep));
    }
  }, [roles, projectDetailsData]);

  const handleChange = (value: number) => {
    dispatch(updateJobPosition(teamMember.id, value));
    setEmployeeAssign(true);
  };

  useEffect(() => {
    if (updatePositionStatusRequest === RequestStatus.SUCCESS && roles.includes(Rol.CO_CREATOR) && employeeAssign) {
      enqueueSnackbar('Ksquarian has been added to the job position ', SnackbarVariant.SUCCESS);
    }
    if (updatePositionStatusRequest === RequestStatus.FAILED && roles.includes(Rol.CO_CREATOR)) {
      enqueueSnackbar(
        errorResponse ? errorResponse.data.data : 'Error trying to add ksquarian to the job position ',
        SnackbarVariant.ERROR,
      );
      formik.setFieldValue(`projectTeam[${index}].employeeId`, formik.initialValues.projectTeam[index].employeeId);
    }
  }, [employeeAssign, enqueueSnackbar, errorResponse, formik, index, roles, updatePositionStatusRequest]);

  const handleOnClickView = () => {
    onClickView(teamMember);
  };

  const renderKsquariansCell = () => {
    if (
      projectDetailsData.creationStep === ProjectStatus.REJECTED ||
      projectDetailsData.creationStep === ProjectStatus.APPRAISING ||
      projectDetailsData.creationStep === ProjectStatus.APPROVED
    ) {
      return (
        <TableCell className={styles.teamRosterRowCell} ref={ksquarianContainer}>
          <PIFTooltip kind='m' title={renderTooltip(ksquarianContainer, ksquarian)}>
            <span>{ksquarian}</span>
          </PIFTooltip>
        </TableCell>
      );
    }
    if (hasRole(roles, Rol.CO_CREATOR) && projectDetailsData.creationStep === ProjectStatus.PENDING) {
      return (
        <TableCell>
          <EmployeeProjectSelectAutocomplete
            {...formik}
            className={styles.selectAutocomplete}
            label='Select Ksquarian'
            loading={employeeAvailabilityRequestStatus === RequestStatus.LOADING}
            name={`projectTeam[${index}].employeeId`}
            options={employeeAvailabilitySelectOptions}
            onChange={handleChange}
          />
        </TableCell>
      );
    }
    return (
      <TableCell className={styles.teamRosterRowCell} ref={ksquarianContainer}>
        <PIFTooltip kind='m' title={renderTooltip(ksquarianContainer, ksquarian)}>
          <span>{ksquarian}</span>
        </PIFTooltip>
      </TableCell>
    );
  };

  const renderMoreCell = () => {
    if (currentPermissions.canEdit) {
      return (
        <TableCell className={styles.teamRosterRowCell}>
          <IconButton
            aria-label='more-actions'
            className={styles.moreIcon}
            onClick={(e) => onClickMenu(e, teamMember.id.toString())}
          >
            <MoreHoriz />
          </IconButton>
        </TableCell>
      );
    }
    return (
      <TableCell onClick={handleOnClickView}>
        <Typography className={styles.view}>View</Typography>
      </TableCell>
    );
  };

  return (
    <TableRow>
      <TableCell className={styles.teamRosterRowCell}>
        <Avatar className={styles.avatar}>{getAcronym(teamMember.position?.name || '')}</Avatar>
      </TableCell>
      <TableCell className={styles.teamRosterRowCell} ref={positionContainer}>
        <PIFTooltip kind='m' title={renderTooltip(positionContainer, titleCase(teamMember.position?.name || ''))}>
          <span>{titleCase(teamMember.position?.name || '')}</span>
        </PIFTooltip>
      </TableCell>
      {renderKsquariansCell()}
      <TableCell className={styles.teamRosterRowCell} ref={technologiesContainer}>
        <PIFTooltip
          kind='m'
          title={renderTooltip(
            technologiesContainer,
            titleCase(teamMember.technologies ? groupTechnologies(teamMember.technologies) : 'no data yet'),
          )}
        >
          <span>{titleCase(teamMember.technologies ? groupTechnologies(teamMember.technologies) : 'no data yet')}</span>
        </PIFTooltip>
      </TableCell>
      {renderMoreCell()}
    </TableRow>
  );
};
