import { createTheme } from '@material-ui/core/styles';
import type {} from '@material-ui/lab/themeAugmentation';

import variables from './partials/_variables.module.scss';

export const theme = createTheme({
  palette: {
    primary: {
      main: variables.primary700,
    },
    secondary: {
      main: variables.primary,
    },
  },
  typography: {
    fontFamily: `${variables.fontNunito}, sans-serif`,
    h1: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '1.75em',
    },
    h2: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
      fontSize: '1.25rem',
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: '1.5em',
    },
    h3: {
      fontFamily: `${variables.fontNunito}, sans-serif`,
      fontSize: '1.125rem',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h4: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
    },
    h5: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
    },
    h6: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
    },
    subtitle1: {
      fontFamily: `${variables.fontNunito}, sans-serif`,
      fontSize: '1.125rem',
      fontWeight: 'normal',
      letterSpacing: 0,
      lineHeight: 'auto',
    },
    subtitle2: {
      fontFamily: `${variables.fontRoboto}, sans-serif`,
      fontWeight: 'bold',
      letterSpacing: 0,
      lineHeight: 'auto',
    },
    body1: {
      fontFamily: `${variables.fontNunito}, sans-serif`,
      fontSize: '0.875rem',
      letterSpacing: 0,
      lineHeight: 'auto',
    },
    body2: {
      fontFamily: `${variables.fontNunito}, sans-serif`,
      fontSize: '1rem',
      letterSpacing: 0,
      lineHeight: 'auto',
    },
    caption: {
      fontFamily: `${variables.fontNunito}, sans-serif`,
      fontSize: '0.75rem',
      letterSpacing: 0,
      lineHeight: '1rem',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
        '& fieldset': {
          borderColor: variables.gray600,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: variables.gray600,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: variables.gray600,
          },
        },
        '&$focused $notchedOutline': {
          borderColor: variables.gray600,
          borderWidth: 1,
        },
        '&$error $notchedOutline': {
          borderColor: variables.redFieldError,
        },
      },
    },
    MuiInputBase: {
      root: {
        height: '46px',
      },
      input: {
        fontSize: '1rem',
      },
    },
    MuiFormLabel: {
      root: {
        color: variables.gray800,
        '&$focused': {
          color: variables.gray800,
        },
        '&$error': {
          color: variables.redFieldError,
        },
      },
      filled: {
        color: variables.gray800,
      },
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          color: variables.redFieldError,
          margin: '8px 0 4px 0',
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: '4px',
      },
      colorPrimary: {
        color: variables.primary,
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: variables.primary,
        boxShadow: 'none',
        color: variables.white,
        fontWeight: 'bold',
        padding: '8px 16px',
        '&:hover': {
          backgroundColor: variables.primary,
        },
        '&.Mui-disabled': {
          backgroundColor: variables.grayDisabledButton,
          color: variables.gray700,
        },
      },
      label: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        lineHeight: '24px',
      },
      outlined: {
        borderColor: variables.primary,
        borderWidth: 1,
        color: variables.primary,
        padding: '12px',
      },
      outlinedSecondary: {
        borderColor: variables.gray600,
        backgroundColor: variables.white,
        '&:hover': {
          backgroundColor: variables.primary200,
        },
      },
      root: {
        borderRadius: '8px',
        padding: '12px',
        textTransform: 'none',
        '&.Mui-disabled': {
          backgroundColor: variables.gray500,
          color: variables.white,
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&.MuiPickersCalendarHeader-iconButton': {
          backgroundColor: variables.gray100,
          color: variables.primary,
        },
        '&.MuiPickersDay-day': {
          borderRadius: '8px',
        },
        '&.MuiPickersDay-daySelected': {
          color: variables.white,
        },
        '&.MuiPaginationItem-root.Mui-selected': {
          color: variables.black,
          backgroundColor: variables.primary200,
          '&:hover': {
            backgroundColor: variables.primary,
          },
        },
      },
    },
    MuiCard: {
      root: {
        border: `solid 1px ${variables.gray300}`,
        borderRadius: '8px',
        boxShadow: `0 4px 24px 0 ${variables.grayTransparent}`,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: variables.gray400,
        height: '1.5px',
      },
    },
    MuiFormControl: {
      root: {
        '&.MuiTextField-root>.MuiInputBase-root': {
          backgroundColor: variables.white,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'unset',
        },
        '&:hover.MuiPickersDay-day': {
          backgroundColor: variables.primary200,
          color: variables.black,
        },
      },
    },
    MuiPaper: {
      root: {
        '&>.MuiPickersBasePicker-container': {
          backgroundColor: variables.gray100,
        },
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor: variables.graySkeleton,
      },
    },
    MuiSvgIcon: {
      colorPrimary: {
        color: variables.primary700,
      },
      colorSecondary: {
        color: variables.primary,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: variables.gray900,
        borderRadius: '8px',
        fontSize: '0.75rem',
        padding: '8px 16px',
        boxShadow: '0 4px 4px 0 rgba(22, 34, 64, 0.1)',
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: variables.primary700,
      },
      colorTextSecondary: {
        color: variables.gray600,
      },
      root: {
        '&.MuiPickersCalendarHeader-dayLabel': {
          color: variables.black,
          fontSize: '0.875rem',
          fontWeight: 'bold',
        },
      },
    },
    MuiTab: {
      root: {
        '&.MuiTab-root': {
          backgroundColor: variables.primary,
          color: variables.white,
          '&:hover:not(.Mui-selected)': {
            backgroundColor: variables.primary200,
            color: variables.primary700,
          },
        },
        '&.Mui-selected': {
          backgroundColor: variables.primary700,
          color: variables.white,
        },
      },
    },
    MuiCheckbox: {
      root: {
        '&.MuiCheckbox-root': {
          color: variables.primary,
        },
      },
    },
    MuiTableCell: {
      root: {
        '&.MuiTableCell-root': {
          fontSize: '0.875rem',
          borderBottom: 'none',
        },
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '8px',
        '&>.MuiPickersBasePicker-container': {
          '&>.MuiToolbar-root': {
            background: 'transparent',
            '&>.MuiButton-text>.MuiButton-label': {
              '&h6,h4': {
                color: variables.black,
              },
            },
          },
          '&>.MuiPickersBasePicker-pickerView > .MuiPickersYearSelection-container': {
            display: 'flex',
            maxWidth: '100%',
            flexWrap: 'wrap',
            '&>.MuiPickersYear-root': {
              flex: '0 0 25%',
            },
            '&>.MuiPickersYear-yearSelected': {
              margin: '0px',
            },
          },
        },
      },
    },
    MuiMenu: {
      paper: {
        '& ul': {
          padding: '0',
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: variables.gray800,
        fontSize: '1rem',
        '& svg': {
          marginRight: '8px',
        },
        '&.Mui-selected,&:hover': {
          color: variables.primary700,
          backgroundColor: variables.primary200,
          '& svg': {
            fill: variables.primary700,
          },
        },
        '&.Mui-disabled': {
          opacity: 1,
        },
        '&:not(:last-child)': {
          borderBottom: `1px solid ${variables.gray400}`,
        },
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `1px solid ${variables.grayTabBorder}`,
        margin: '16px 16px 0 16px',
        '& button.MuiButtonBase-root': {
          backgroundColor: variables.white,
          color: variables.gray800,
          textTransform: 'none',
          fontSize: '1.125rem',
        },
        '& .Mui-selected': {
          backgroundColor: variables.white,
          color: `${variables.primary} !important`,
          fontWeight: 'bold',
        },
      },
    },
  },
});
