import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Skill } from 'types/Project/Skill';

import { SkillState } from './index';

const setSkillsData = (state: SkillState, action: PayloadAction<Skill[]>): void => {
  state.skills = action.payload;
};

const setSkillsRequestStatus = (state: SkillState, action: PayloadAction<RequestStatus>): void => {
  state.skillsRequestStatus = action.payload;
};

const reducers = {
  setSkillsData,
  setSkillsRequestStatus,
};

export default reducers;
