import * as yup from 'yup';

import { FormFieldErrorMessages } from 'constants/FormFieldErrorMessages';
import {
  BillRateValidations,
  BudgetValidations,
  TechnologiesValidations,
  WeeklyForecastValidations,
} from 'constants/validations';

const validName = new RegExp(/(?!^\d+$)^.+$/);

export const PIFSchema = yup.object().shape({
  budgetForecasting: yup.number().min(BudgetValidations.MIN_VALUE, FormFieldErrorMessages.MinimumValue).typeError(''),
  businessUnit: yup.number().typeError('').required(FormFieldErrorMessages.Required),
  contractType: yup.number().typeError('').required(FormFieldErrorMessages.Required),
  comments: yup.string().max(1000).nullable(),
  currencyId: yup.number().required(''),
  customerId: yup.number().required(''),
  endDate: yup.date().min(yup.ref('startDate'), FormFieldErrorMessages.MinimumDate).typeError('').required(''),
  projectName: yup.string().matches(validName, FormFieldErrorMessages.TypeError).required(''),
  projectType: yup.number().typeError('').required(FormFieldErrorMessages.Required),
  startDate: yup.date().typeError('').required(''),
  approverId: yup.number().nullable(),
  totalForecastedHours: yup.number().nullable(),
  projectTeam: yup.array().of(
    yup.object({
      billRate: yup.number().min(BillRateValidations.MIN_VALUE, FormFieldErrorMessages.MinimumValue).typeError(''),
      currencyId: yup.number().required(''),
      startDate: yup.date().typeError('').required(),
      endDate: yup.date().min(yup.ref('startDate'), FormFieldErrorMessages.MinimumDate).typeError(''),
      technologies: yup
        .array()
        .of(yup.number().typeError(FormFieldErrorMessages.TypeError))
        .min(TechnologiesValidations.MIN_VALUE),
      weeklyForecastedHours: yup
        .number()
        .min(WeeklyForecastValidations.MIN_VALUE, FormFieldErrorMessages.MinimumValue)
        .max(WeeklyForecastValidations.MAX_VALUE, FormFieldErrorMessages.MaximumWeeklyForecast)
        .typeError('')
        .required(''),
    }),
  ),
});

export const BillRateSchema = yup
  .number()
  .min(BillRateValidations.MIN_VALUE, FormFieldErrorMessages.MinimumValue)
  .typeError('');
