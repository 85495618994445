import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, TableCell } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import clsx from 'clsx';

import { ExpandableCell } from 'components/Expandable/ExpandableCell/ExpandableCell';
import { ExpandableRow } from 'components/Expandable/ExpandableRow/ExpandableRow';
import { PIFTooltip } from 'components/Tooltips/PIFTooltip';
import { Rol } from 'constants/Credentials/Rol';
import { RejectionDetails } from 'modules/Project/components/ProjectsTable/ProjecRow/RejectionDetails/RejectionDetails';
import { StatusCell } from 'modules/Project/components/Status/StatusCell/StatusCell';
import { createProjectDetailsRouter } from 'modules/Project/ProjectRouter';
import { selectBusinessUnitsByRoleOptions } from 'store/businessUnit/selector';
import { selectSessionRoles } from 'store/session/selectors';
import { ProjectStatus } from 'types/Project';
import { Project } from 'types/Project/Project';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';
import { renderTooltip } from 'utils/tables/tooltips';

import styles from './ProjectRow.module.scss';

interface ProjectRowProps {
  colSpan: number;
  kind: 'slim' | 'plump';
  project: Project;
  onClickMenu: (event: React.MouseEvent, projectId: string, status: ProjectStatus) => void;
}

export const ProjectRow: React.FC<ProjectRowProps> = ({
  project: { id, projectName, creationStep, startDate, endDate, businessUnitId, rejectionNotes },
  onClickMenu,
  colSpan,
  kind,
}) => {
  const businessUnitOptions = useAppSelector(selectBusinessUnitsByRoleOptions);
  const roles = useAppSelector(selectSessionRoles);
  const [isRowExpanded, updateIsRowExpanded] = useState<boolean>(false);
  const projectNameContainer = useRef<HTMLDivElement>(null);
  const businessUnitContainer = useRef<HTMLDivElement>(null);

  const businessUnit = businessUnitOptions.find(({ value }) => value === businessUnitId)?.label || '';

  const toggleExpanded = (): void => {
    updateIsRowExpanded((prevState) => !prevState);
  };

  const className = clsx(
    styles.projectRowCell,
    kind === 'slim' ? styles.projectRowCellSlim : styles.projectRowCellPlump,
  );

  const renderMoreCell = () => {
    if (hasRole(roles, Rol.CO_CREATOR)) {
      return (
        <Link className={styles.link} to={createProjectDetailsRouter(id)}>
          {creationStep === ProjectStatus.PENDING ? 'Review' : 'View'}
        </Link>
      );
    }
    if (hasRole(roles, Rol.APPRAISER)) {
      return (
        <Link className={styles.link} to={createProjectDetailsRouter(id)}>
          {creationStep === ProjectStatus.APPRAISING ? 'Appraise' : 'View'}
        </Link>
      );
    }
    return (
      <IconButton
        aria-label='more-actions'
        className={styles.moreIcon}
        onClick={(e) => onClickMenu(e, id, creationStep)}
      >
        <MoreHoriz />
      </IconButton>
    );
  };

  return (
    <ExpandableRow
      expandedRowContent={<RejectionDetails colSpan={colSpan} message={rejectionNotes} />}
      isExpandable={creationStep === ProjectStatus.REJECTED}
      isExpanded={isRowExpanded}
    >
      <ExpandableCell
        className={className}
        isExpandable={creationStep === ProjectStatus.REJECTED}
        isExpanded={isRowExpanded}
        ref={projectNameContainer}
        onClick={() => toggleExpanded()}
      >
        <PIFTooltip kind='m' title={renderTooltip(projectNameContainer, projectName)}>
          <span>{projectName}</span>
        </PIFTooltip>
      </ExpandableCell>
      <TableCell className={className}>
        <StatusCell status={creationStep} />
      </TableCell>
      <TableCell className={className}>{startDate}</TableCell>
      <TableCell className={className}>{endDate}</TableCell>
      <TableCell className={styles.projectRowCell} ref={businessUnitContainer}>
        <PIFTooltip kind='m' title={renderTooltip(businessUnitContainer, businessUnit)}>
          <span>{businessUnit}</span>
        </PIFTooltip>
      </TableCell>
      <TableCell className={className}>{renderMoreCell()}</TableCell>
    </ExpandableRow>
  );
};
