import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import { getIcon } from 'assets/Icons';
import { KMenuProps } from 'components/Menus/types';
import { commonTableMenuProps, paperStylesOf } from 'components/Menus/utils';
import { Order } from 'types/Filter/Filter';
import { executeCommands } from 'utils/functions/functionUtils';

const UpArrow = getIcon({ name: 'UpArrow', variant: 'default' });
const DownArrow = getIcon({ name: 'DownArrow', variant: 'default' });

interface DefaultSortMenuProps {
  onSelected: (order: Order) => void;
}

export const DefaultSortMenu: React.FC<KMenuProps & DefaultSortMenuProps> = ({ id, anchorEl, onClose, onSelected }) => (
  <Menu
    anchorEl={anchorEl}
    classes={{ paper: paperStylesOf(anchorEl?.clientWidth) }}
    id={id}
    open={anchorEl !== undefined}
    onClose={onClose}
    {...commonTableMenuProps}
  >
    <MenuItem onClick={() => executeCommands(() => onSelected('ASC'), onClose)}>
      <UpArrow />
      <span>Sort Ascending</span>
    </MenuItem>
    <MenuItem onClick={() => executeCommands(() => onSelected('DESC'), onClose)}>
      <DownArrow />
      <span>Sort Descending</span>
    </MenuItem>
  </Menu>
);
