import { PositionModel } from 'types/models/PositionModel';
import { Position } from 'types/Project/Position';

export const mapPositionModelToPosition = (PositionModel: PositionModel[]): Position[] =>
  PositionModel.reduce((acc, { acronym, id, name }) => {
    if (id) {
      acc.push({
        acronym,
        id,
        name,
      });
    }
    return acc;
  }, [] as Position[]);
