import { ProjectAppraisal } from 'types/Project/ProjectAppraisal/ProjectAppraisal';
import { ProjectAppraisalModel } from 'types/Project/ProjectAppraisal/ProjectAppraisalModel';
import { IndexSignature } from 'types/utils';

export const mapProjectAppraisalModelToProjectAppraisal = (
  projectAppraisals: ProjectAppraisalModel[],
): ProjectAppraisal[] =>
  projectAppraisals.map(({ userFeedback, projectId }) => ({ userFeedback, projectId: projectId.toString() }));

export const rejectionNotesSignatureOf = (projectAppraisals: ProjectAppraisalModel[]): IndexSignature<string> =>
  projectAppraisals
    .filter((appraisal) => appraisal !== undefined)
    .reduce<IndexSignature<string>>(
      (acc, { projectId, userFeedback }) => ({ ...acc, [projectId]: userFeedback || '' }),
      {},
    );
