import { createSlice } from '@reduxjs/toolkit';

import { IndexSignature } from 'types/utils';

import reducers from './reducers';

export interface ProjectAppraisalState {
  projectAppraisals: IndexSignature<string>;
}

const initialState: ProjectAppraisalState = {
  projectAppraisals: {},
};

export const projectsSlice = createSlice({
  name: 'projectAppraisals',
  initialState,
  reducers,
});

export const { setProjectAppraisals } = projectsSlice.actions;

export default projectsSlice.reducer;
