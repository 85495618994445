import React, { FC, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, ClickAwayListener, IconButton, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import Error from '@material-ui/icons/Error';
import clsx from 'clsx';

import { BillRateSchema } from 'modules/Project/views/ProjectBuilder/PIFSchema';
import { selectCurrencyData } from 'store/currency/selectors';
import { ProjectStatus } from 'types/Project';
import { useAppSelector } from 'utils/hooks/storeHooks';

import styles from './BillRateCell.module.scss';

interface BillRateCellProps {
  billRateValue: number;
  currencyId: number;
  projectStatus: ProjectStatus;
  onConfirm: (value: number) => void;
}

interface NumberFormatterProps {
  decimalScale: number;
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumberFormatter = (props: NumberFormatterProps) => {
  const { inputRef, onChange, decimalScale = 0, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={decimalScale}
      fixedDecimalScale
      getInputRef={inputRef}
      isNumericString
      thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

export const BillRateCell: FC<BillRateCellProps> = ({
  billRateValue,
  currencyId,
  projectStatus,
  onConfirm,
}: BillRateCellProps) => {
  const currencyData = useAppSelector(selectCurrencyData);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [value, setValue] = useState(billRateValue);
  const [error, setError] = useState<string>('');
  const [initialValue, setInitialValue] = useState(billRateValue);

  const handleClickEdit = () => {
    setIsEdit(true);
  };

  const handleClickCancel = () => {
    setIsEdit(false);
    setValue(initialValue);
  };

  const handleClickConfirm = () => {
    setIsEdit(false);
    setInitialValue(value);
    onConfirm(value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as number);
  };

  useEffect(() => {
    BillRateSchema.validate(value, { abortEarly: false })
      .then(() => setError(''))
      .catch((error) => {
        setError(error.errors[0]);
      });
  }, [value]);

  const getFormatValue = () => (
    <NumberFormat
      className={styles.currentValue}
      decimalScale={2}
      displayType='text'
      fixedDecimalScale
      prefix={currency?.currencySymbol}
      thousandSeparator
      value={billRateValue}
    />
  );

  const currency = currencyData.find(({ id }) => id === currencyId);

  return isEdit && projectStatus === ProjectStatus.APPRAISING ? (
    <ClickAwayListener onClickAway={handleClickCancel}>
      <Box>
        <TextField
          InputProps={{
            inputComponent: NumberFormatter as never,
            classes: { root: styles.input },
            inputProps: { prefix: currency?.currencySymbol },
            endAdornment: (
              <InputAdornment classes={{ positionEnd: styles.adornment }} position='end'>
                {error ? (
                  <Tooltip
                    className={styles.tooltip}
                    classes={{ popper: styles.popper }}
                    placement='top-end'
                    title={error}
                  >
                    <Error className={styles.iconError} />
                  </Tooltip>
                ) : null}
              </InputAdornment>
            ),
          }}
          className={styles.textfield}
          error={!!error}
          name='billRate'
          placeholder={`${currency?.currencySymbol}0`}
          value={value}
          variant='outlined'
          onChange={handleChange}
        />
        <IconButton
          className={clsx(styles.icon, {
            [styles.iconConfirm]: !error,
            [styles.iconConfirmDisabled]: !!error || !value,
          })}
          disabled={!!error || !value}
          onClick={handleClickConfirm}
        >
          <Check />
        </IconButton>
        <IconButton className={clsx(styles.icon, styles.iconCancel)} onClick={handleClickCancel}>
          <Close />
        </IconButton>
      </Box>
    </ClickAwayListener>
  ) : (
    <Box display='flex' justifyContent={projectStatus === ProjectStatus.APPRAISING ? 'space-between' : 'flex-end'}>
      <span className={styles.value}>{getFormatValue()}</span>
      {projectStatus === ProjectStatus.APPRAISING && (
        <IconButton className={styles.iconEdit} onClick={handleClickEdit}>
          <CreateIcon />
        </IconButton>
      )}
    </Box>
  );
};
