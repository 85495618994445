import { BaseService, prepareParams, ResponseData, ServiceOptions } from 'services/baseService';
import { EmployeeModel } from 'types/models/EmployeeModel';
import { NotificationModel } from 'types/models/NotificationModel';

// This is an example of a service extending base service

class EmployeeService extends BaseService<EmployeeModel> {
  controllerName = 'employees';

  async setActive(id: number | string, isActive: boolean): Promise<ResponseData<EmployeeModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.put<ResponseData<EmployeeModel>>(`/${this.controllerName}/${id}/setActive`, {
      isActive,
    });
    return data;
  }

  async getSelfNotifications(options?: ServiceOptions): Promise<ResponseData<NotificationModel>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<NotificationModel>>(`/${this.controllerName}/self/notifications`, {
      params,
    });
    return data;
  }

  async getNotificationsByEmployeeId(id: number, options?: ServiceOptions): Promise<ResponseData<NotificationModel>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<NotificationModel>>(
      `/${this.controllerName}/${id}/notifications`,
      { params },
    );
    return data;
  }

  async updateAllNotificationAsRead(): Promise<ResponseData<NotificationModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.put<ResponseData<NotificationModel>>(
      `/${this.controllerName}/self/notifications/MarkAllAsRead`,
    );
    return data;
  }
}

export const employeeService = new EmployeeService();

// How to use it
/**
Import the employeeService as this class is extending the baseService, this instance can
use the functions defined inside baseService in the following way:

const res = await employeeService.create(newEmployeeData);
const res = await employeeService.update(id, employeeDataToUpdate);
const res = await employeeService.delete(id);
const res = await employeeService.update(id, employeeDataToUpdate);
const res = await employeeService.getById(id, optionalQuery);
const res = await employeeService.getAll(optionalQuery);

All responces (Except getAll) have the interface ResponseData<T>
getAll return the object with interface ListResponseData<T>

T is a generic to pass the type that you want. In the case of this service we are passing
EmployeeModel. 

In this service we are expecting that the endpoint in the back is called /employee
that's why the "contollerName" is "employee"

In a case that the backEnd has another URL or it needs other parameters we need 
to make a custom function to make the request. In this case the example is "setActive".
It is based on the functions that are in the baseService, so take a look on those to base
the new one that you need to create.

*/
