import React from 'react';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

interface LoaderProps {
  show: boolean;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loader: React.FC<LoaderProps> = ({ show }: LoaderProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={show}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default Loader;
