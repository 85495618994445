import { useMemo } from 'react';

import { selectCurrencyData } from 'store/currency/selectors';
import { Currency } from 'types/Currency';
import { useAppSelector } from 'utils/hooks/storeHooks';

export const useCurrency = (currencyId: number): Currency | undefined => {
  const currencyData = useAppSelector(selectCurrencyData);

  const currency = useMemo(() => currencyData.find(({ id }) => id === currencyId), [currencyId, currencyData]);

  return currency;
};
