import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import businessUnit from 'store/businessUnit';
import contractType from 'store/contractType';
import currency from 'store/currency';
import customer from 'store/customer';
import departments from 'store/departments';
import employee from 'store/employee';
import errors from 'store/errors';
import notifications from 'store/notifications';
import position from 'store/position';
import projectAppraisal from 'store/projectAppraisal';
import projectDashboard from 'store/projectDashboard';
import projectFilters from 'store/projectFilters';
import pif from 'store/projectInitiationForm';
import projects from 'store/projects';
import roles from 'store/roles';
import session from 'store/session';
import skills from 'store/skills';
import teamPosition from 'store/teamPosition';
import teamRosterFilter from 'store/teamRosterFilters';
import technologies from 'store/technologies';
import technologyTypes from 'store/technologyTypes';

export const store = configureStore({
  reducer: {
    businessUnit,
    contractType,
    currency,
    customer,
    departments,
    employee,
    notifications,
    projectAppraisal,
    pif,
    position,
    projectDashboard,
    projects,
    projectFilters,
    session,
    skills,
    teamPosition,
    teamRosterFilter,
    technologies,
    technologyTypes,
    roles,
    errors,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['errors.error'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
