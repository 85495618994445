import { PayloadAction } from '@reduxjs/toolkit';

import { Customer } from 'types/Project/Customer';
import { RequestStatus } from 'types/RequestStatus';

import { PositionState } from './index';

const setCustomerData = (state: PositionState, action: PayloadAction<Customer[]>): void => {
  state.customer = action.payload;
};

const setCustomerRequestStatus = (state: PositionState, action: PayloadAction<RequestStatus>): void => {
  state.customerRequestStatus = action.payload;
};

const reducers = {
  setCustomerData,
  setCustomerRequestStatus,
};

export default reducers;
