import * as yup from 'yup';

import { FormFieldErrorMessages } from 'constants/FormFieldErrorMessages';
import { Technology } from 'types/Project/Technology';

const validName = new RegExp(/(?!^\d+$)^.+$/);

export const TechnologySchemaFactory = (technologiesData: Technology[]) =>
  yup.object().shape({
    name: yup
      .string()
      .matches(validName, FormFieldErrorMessages.Required)
      .required('')
      .trim()
      .test('name', function isUnique(value) {
        if (!value) {
          return true;
        }

        const exists = technologiesData.find(
          ({ name }) => name.toLowerCase().replace(/\s+/g, '') === value.trim().toLowerCase().replace(/\s+/g, ''),
        );
        if (exists) {
          return this.createError({
            message: 'This technology already exists',
            path: 'name',
          });
        }
        return true;
      }),
    description: yup.string().matches(validName, FormFieldErrorMessages.Required).required(''),
    technologyTypesId: yup.number().required(''),
    departmentId: yup.number().required(''),
  });
