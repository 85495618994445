import React, { FC, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { FormikErrors, FormikValues } from 'formik';

import styles from 'modules/Project/components/ChipsRow/ChipsRow.module.scss';

interface ChipsOptions {
  label: string;
  value: number;
}

interface ChipsRowProps {
  disabled?: boolean;
  disabledChips?: number[];
  errors: FormikErrors<FormikValues>;
  name: string;
  options: ChipsOptions[];
  title?: string;
  values: FormikValues;
  setFieldValue: (field: string, value: number | null) => void;
}

const ChipsRow: FC<ChipsRowProps> = ({
  disabled,
  disabledChips,
  errors,
  name,
  options,
  title,
  values,
  setFieldValue,
}: ChipsRowProps) => {
  const [isTouched, setIsTouched] = useState(false);

  const handleClick = (value: number) => {
    setIsTouched(true);
    setFieldValue(name, values[name] === value ? null : value);
  };

  return (
    <div>
      <Typography className={clsx(styles.title, isTouched && errors[name] && styles.error)}>{title}</Typography>
      {options.map(({ label, value }) => {
        const isChipDisabled = disabledChips ? disabledChips.includes(value) : false;
        return (
          <Chip
            className={clsx({ [styles.activeChip]: values[name] === value, [styles.disabledChip]: disabled })}
            classes={{ root: styles.chipRoot }}
            clickable
            disabled={isChipDisabled}
            key={value}
            label={label}
            onClick={() => handleClick(value)}
          />
        );
      })}
      <Typography className={clsx(styles.helperText, styles.error)}>
        {isTouched && errors[name] ? errors[name] : ''}
      </Typography>
    </div>
  );
};

export default ChipsRow;
