import { BaseService, prepareParams, ServiceOptions } from 'services/baseService';
import { EmployeeProjectAvailability, ProjectDetails } from 'types/Project/Project';

import { ResponseData } from './authService';

class ProjectService extends BaseService<ProjectDetails> {
  controllerName = 'project';

  async getEmployeesProjectAvailabilityByProjectId(
    id: number | string,
    options?: ServiceOptions,
  ): Promise<ResponseData<EmployeeProjectAvailability[]>> {
    const params = prepareParams(options);
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.get<ResponseData<EmployeeProjectAvailability[]>>(
      `/${this.controllerName}/${id}/work-availability`,
      { params },
    );
    return data;
  }
}

export const projectService = new ProjectService();
