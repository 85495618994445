import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Skill } from 'types/Project/Skill';

import reducers from './reducers';

export interface SkillState {
  skills?: Skill[];
  skillsRequestStatus: RequestStatus;
}

const initialState: SkillState = {
  skills: [],
  skillsRequestStatus: RequestStatus.LOADING,
};

export const skillsSlice = createSlice({
  name: 'skill',
  initialState,
  reducers,
});

export const { setSkillsData, setSkillsRequestStatus } = skillsSlice.actions;

export default skillsSlice.reducer;
