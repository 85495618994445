import { authService } from 'services/authService';
import { userService } from 'services/userService';
import { AppThunk } from 'store/store';
import { Credentials } from 'types/Credentials/Credentials';
import { asCredentials } from 'utils/parsers/credentials';

import { selectSessionCredentials } from './selectors';
import {
  clearSavedCredentials,
  confirmSession,
  getSavedCredentials,
  logOut,
  SessionStatus,
  setCredentials,
  setErrorMessage,
  setHasError,
  setIsForgotPasswordSuccessful,
  setIsLoading,
  setIsRegisterSuccessful,
  setSavedCredentials,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
} from '.';

export const updateCredentials =
  (credentials: Credentials): AppThunk =>
  async (dispatch) => {
    setSavedCredentials(credentials);
    dispatch(setCredentials(credentials));
  };

export const closeSession = (): AppThunk => async (dispatch) => {
  try {
    await authService.logout();
  } catch (error) {
    console.error(error);
  } finally {
    clearSavedCredentials();
    dispatch(logOut());
  }
};

const populateUserSession = (): AppThunk => async (dispatch, getState) => {
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    throw new Error('No Credentials found');
  }
  try {
    const { data } = await userService.getSelfUser();
    dispatch(updateCurrentUser(data));
    dispatch(updateIsAuthenticated(true));
    dispatch(updateSessionStatus(SessionStatus.fulfilled));
  } catch (err) {
    dispatch(closeSession());
  }
};

export const logInWithEmail =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setHasError(false));
      const { data } = await authService.login(email, password);
      setSavedCredentials(asCredentials(data));
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
    dispatch(initSession());
  };

export const sendForgotPassword =
  (email: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setIsForgotPasswordSuccessful(false));
      dispatch(setHasError(false));
      await authService.forgot(email);
      dispatch(setIsForgotPasswordSuccessful(true));
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const resetPassword =
  (token: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setIsForgotPasswordSuccessful(false));
      dispatch(setHasError(false));
      const { data } = await authService.reset(token, password);
      setSavedCredentials(asCredentials(data));
      dispatch(setIsForgotPasswordSuccessful(true));
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
    dispatch(initSession());
  };

export const logInWithExchange =
  (token: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setHasError(false));
      const { data } = await authService.exchange(token);
      setSavedCredentials(asCredentials(data));
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
    dispatch(initSession());
  };

export const initSession = (): AppThunk => async (dispatch) => {
  const credentials = getSavedCredentials();

  if (!credentials) {
    dispatch(confirmSession());
    dispatch(updateSessionStatus(SessionStatus.logOut));
    return;
  }

  try {
    dispatch(setIsLoading(true));
    dispatch(updateCredentials(credentials));
    await dispatch(populateUserSession());
  } catch (error) {
    clearSavedCredentials();
    dispatch(logOut());
  } finally {
    dispatch(confirmSession());
    dispatch(setIsLoading(false));
  }
};

export const changeUserRoles =
  (requestedRol: number[]): AppThunk =>
  async (dispatch) => {
    const credentials = getSavedCredentials();

    if (!credentials) {
      dispatch(confirmSession());
      dispatch(updateSessionStatus(SessionStatus.logOut));
      return;
    }
    try {
      dispatch(setIsLoading(true));
      dispatch(setHasError(false));
      const { data } = await authService.changeRoles(requestedRol);
      setSavedCredentials(asCredentials(data));
    } catch (error) {
      dispatch(setHasError(true));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
    dispatch(initSession());
  };

const EMAIL_IN_USE_MESSAGE = 'This email is already registered.';
const GENERIC_ERROR_MESSAGE = 'Please review the information you typed in.';

export const registerWithemail =
  (name: string, email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      dispatch(setHasError(false));
      await authService.registerEmailUser(name, email, password);
      dispatch(setIsRegisterSuccessful(true));
    } catch (error) {
      const { response = {} } = error;
      const { message = '', data = [] } = response;

      // const response = error.response?.data;

      switch (message) {
        case 'Bad Request':
          if (data[0]?.error) {
            dispatch(setErrorMessage(data[0]?.error));
          } else {
            dispatch(setErrorMessage(GENERIC_ERROR_MESSAGE));
          }
          break;
        case 'Forbidden':
          if (data === 'email in use') {
            dispatch(setErrorMessage(EMAIL_IN_USE_MESSAGE));
          } else {
            dispatch(setErrorMessage(GENERIC_ERROR_MESSAGE));
          }
          break;
        default:
          dispatch(setErrorMessage(GENERIC_ERROR_MESSAGE));
          break;
      }

      dispatch(setHasError(true));
      dispatch(setIsRegisterSuccessful(false));
      console.error(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
