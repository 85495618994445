import React, { useEffect, useState } from 'react';

import { RefDimensions } from 'types/PreviewAttachment';

export const useRefDimensions = (ref: React.RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState<RefDimensions>({ height: 0, width: 0 });
  useEffect(() => {
    const handleResize = () => {
      const { current } = ref;
      if (current) {
        const boundingRect = current.getBoundingClientRect();
        const { width, height } = boundingRect;
        setDimensions({ height: Math.round(height), width: Math.round(width) });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);
  return dimensions;
};
