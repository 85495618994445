import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { businessUnitService } from 'services/businessUnitService';
import { AppThunk } from 'store/store';
import { Option } from 'types/Form/FormCommons';
import { mapBusinessUnitToFilterOptions } from 'utils/parsers/businessUnit';

import { setBusinessUnitOptions, setBusinessUnitStatus } from './index';

export type GetInstancePropertyAsFilterOptionsSettings = {
  serviceOptions?: ServiceOptions;
  dataReducer?: (payload: Option<number>[]) => {
    payload: Option<number>[];
    type: string;
  };
};
const getSettings = (settings: GetInstancePropertyAsFilterOptionsSettings) => {
  const mySettings: GetInstancePropertyAsFilterOptionsSettings = {};
  if (settings.serviceOptions) {
    mySettings.serviceOptions = settings.serviceOptions;
  }
  if (settings.dataReducer) {
    mySettings.dataReducer = settings.dataReducer;
  }
  return mySettings;
};

export const getBusinessUnits =
  (settings: GetInstancePropertyAsFilterOptionsSettings = {}): AppThunk =>
  async (dispatch) => {
    dispatch(setBusinessUnitStatus(RequestStatus.LOADING));

    const { serviceOptions, dataReducer } = getSettings(settings);

    try {
      const { data } = await businessUnitService.getAll(serviceOptions);

      if (dataReducer) {
        dispatch(dataReducer(mapBusinessUnitToFilterOptions(data)));
      } else {
        dispatch(setBusinessUnitOptions(mapBusinessUnitToFilterOptions(data)));
      }
      dispatch(setBusinessUnitStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setBusinessUnitStatus(RequestStatus.FAILED));
    }
  };
