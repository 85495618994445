import { OptionsObject } from 'notistack';

interface SnackbarVariants {
  ERROR: OptionsObject;
  INFO: OptionsObject;
  SUCCESS: OptionsObject;
  WARNING: OptionsObject;
}

export const SnackbarVariant: SnackbarVariants = {
  ERROR: {
    variant: 'error',
  },
  INFO: {
    variant: 'info',
  },
  SUCCESS: {
    variant: 'success',
  },
  WARNING: {
    variant: 'warning',
  },
};
