import { FileAttached } from 'types/PreviewAttachment';
import { ProjectStatus } from 'types/Project';
import { SelectOption } from 'types/SelectOption';

export interface Project {
  businessUnitId: number;
  creationStep: ProjectStatus;
  endDate: string;
  id: string;
  projectName: string;
  rejectionNotes?: string;
  startDate: string;
}

export interface ProjectDetails {
  approverId?: number;
  approverName: string;
  budgetForecasting: number;
  businessUnitId: number;
  businessUnitName: string;
  comments?: string;
  contractTypeId: number;
  contractTypeName: string;
  cost?: number;
  creationStep: ProjectStatus;
  currencyId: number;
  currencySymbol: string;
  customerId: number;
  customerName: string;
  endDate: string;
  id: number;
  projectAttachments?: FileAttached[];
  projectName: string;
  projectTypeId: number;
  projectTypeName: string;
  startDate: string;
  totalForecastedHours?: number;
}

export enum EmployeeWorkAvailability {
  FREE = 'Free',
  BUSY = 'Busy',
  UNAVAILABLE = 'Unavailable',
  WRONG_DATES = 'Wrong Dates',
}

export interface EmployeeProjectAvailability {
  availableFrom: Date | string;
  employeeId: number;
  fullName: string;
  projectsToCheck: string[];
  totalWeeklyForecastedHours: number | null;
  workAvailability: EmployeeWorkAvailability;
}

export type EmployeeAvailabilitySelectOption = SelectOption &
  Pick<EmployeeProjectAvailability, 'workAvailability' | 'availableFrom' | 'projectsToCheck'>;
