import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Option } from 'types/Form/FormCommons';

import reducers from './reducers';

export interface ContractTypeState {
  contractTypeOptions: Option<number>[];
  contractTypeStatus: RequestStatus;
}

const initialState: ContractTypeState = {
  contractTypeOptions: [],
  contractTypeStatus: RequestStatus.LOADING,
};

export const projectsSlice = createSlice({
  name: 'contractType',
  initialState,
  reducers,
});

export const { setContractTypeStatus, setContractTypeOptions } = projectsSlice.actions;

export default projectsSlice.reducer;
