import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Technology } from 'types/Project/Technology';

import { TechnologiesState } from './index';

const setTechnologiesData = (state: TechnologiesState, action: PayloadAction<Technology[]>): void => {
  state.technologies = action.payload;
};

const setTechnologiesRequestStatus = (state: TechnologiesState, action: PayloadAction<RequestStatus>): void => {
  state.technologiesRequestStatus = action.payload;
};

const reducers = {
  setTechnologiesData,
  setTechnologiesRequestStatus,
};

export default reducers;
