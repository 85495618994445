import React, { useRef, useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { FiberManualRecord, KeyboardArrowDown } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FormikValues, getIn } from 'formik';
import noop from 'lodash';

import style from 'modules/Project/components/EmployeeProjectSelectAutocomplete/EmployeeProjectSelectAutocomplete.module.scss';
import inheritedStyle from 'modules/Project/components/SelectAutocomplete/SelectAutoComplete.module.scss';
import { EmployeeAvailabilitySelectOption, EmployeeWorkAvailability } from 'types/Project/Project';
import { useRefDimensions } from 'utils/hooks/useRefDimensions';

interface ComponentProps {
  className?: string;
  disabled?: boolean;
  label: string;
  loading: boolean;
  name: string;
  noOptionsText?: string;
  options: EmployeeAvailabilitySelectOption[];
  values: FormikValues;
  onChange?: (value: number) => void;
  onOpen?: (value: boolean) => void;
  setFieldValue: (field: string, value: string) => void;
}

/**
 * Employees specific autocomplete selector with availability indicators (based
 * on a specific Project) that uses a Quick Find input
 */
const EmployeeProjectSelectAutocomplete: React.FC<ComponentProps> = ({
  className,
  disabled,
  label = '',
  loading,
  name,
  noOptionsText,
  onChange,
  onOpen,
  options,
  setFieldValue,
  values,
}) => {
  // Hooks
  const selectContainer = useRef<HTMLDivElement>(null);
  const { width } = useRefDimensions(selectContainer);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });

  // States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [textFieldValue, setTextFieldValue] = useState<string>('');

  const isShrink = Boolean(anchorEl) || Boolean(!anchorEl && getIn(values, name));

  //
  // Utilities
  // ----------------------------------------------------------------------

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!getIn(values, name) && onOpen) {
      onOpen(true);
    }

    setAnchorEl(event.currentTarget);
  };

  const getFieldText = () => {
    const employeeId = getIn(values, name);
    const inputText = options.find((option) => option.value === String(employeeId));

    return inputText !== undefined ? inputText.label : '';
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextFieldValue(e.target.value);
  };

  const handleClose = (_event: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }

    if (anchorEl) {
      anchorEl.focus();
    }

    if (!getIn(values, name) && onOpen) {
      onOpen(false);
    }

    setAnchorEl(null);
  };

  //
  // Virtualization
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  const LISTBOX_PADDING = 0;

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
      style: {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
      },
    });
  }

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: unknown) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            height={getHeight() + 2 * LISTBOX_PADDING}
            innerElementType='ul'
            itemCount={itemCount}
            itemData={itemData}
            itemSize={(index: string | number) => getChildSize(itemData[index as number])}
            outerElementType={OuterElementType}
            overscanCount={5}
            ref={gridRef}
            width='100%'
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  //
  // Component
  // ----------------------------------------------------------------------

  return (
    <React.Fragment>
      <FormControl className={inheritedStyle.selectInputContainer} innerRef={selectContainer} variant='outlined'>
        {label.length > 0 && (
          <InputLabel
            className={inheritedStyle.label}
            htmlFor={`outlined-${label}`}
            id={`outlined-${label}`}
            shrink={isShrink}
          >
            {label}
          </InputLabel>
        )}
        <OutlinedInput
          className={inheritedStyle.selectInput}
          classes={{ notchedOutline: inheritedStyle.outlinedBorder, root: inheritedStyle.selectInputBaseRoot }}
          disabled={disabled}
          endAdornment={
            !disabled && (
              <InputAdornment className={inheritedStyle.iconArrowContainer} position='end'>
                <KeyboardArrowDown className={inheritedStyle.iconArrow} />
              </InputAdornment>
            )
          }
          id={`outlined-${label}`}
          inputProps={{
            className: clsx(disabled ? inheritedStyle.textFieldDisabled : inheritedStyle.textField),
          }}
          label={label}
          name={name}
          notched={label.length > 0 && isShrink}
          readOnly
          value={getFieldText()}
          onClick={disabled ? noop : handleClick}
        />
      </FormControl>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={clsx(inheritedStyle.borderShadow, inheritedStyle.popper)}
        classes={{ paper: inheritedStyle.paperContainer }}
        id={`menu-${label}`}
        open={Boolean(anchorEl)}
      >
        <Box className={className} width={width}>
          <Autocomplete
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            classes={{
              listbox: clsx(
                inheritedStyle.borderBottom,
                inheritedStyle.listbox,
                inheritedStyle.scrollbar,
                options.length > 0 && inheritedStyle.listboxExpanded,
              ),
              noOptions: inheritedStyle.noOption,
              option: inheritedStyle.option,
              paper: inheritedStyle.paper,
              popperDisablePortal: inheritedStyle.popperDisablePortal,
            }}
            disableListWrap
            disablePortal
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, valueSelected) => option.value === valueSelected.value}
            loading={loading}
            noOptionsText={noOptionsText ?? 'No Employees Found'}
            open
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  endAdornment: null,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon className={inheritedStyle.iconSearch} />
                    </InputAdornment>
                  ),
                  className: inheritedStyle.input,
                }}
                autoFocus
                className={inheritedStyle.inputBase}
                placeholder='Quick Find'
                ref={params.InputProps.ref}
                value={textFieldValue}
                variant='outlined'
                onChange={handleChange}
              />
            )}
            renderOption={({ availableFrom, label, projectsToCheck, workAvailability }) => (
              <RenderOption
                availableFrom={availableFrom}
                label={label}
                projectsToCheck={projectsToCheck}
                workAvailability={workAvailability}
              />
            )}
            renderTags={() => null}
            onChange={(_event, newValue) => {
              if (newValue) setFieldValue(name, newValue.value);
              if (onChange && newValue?.value) onChange(parseInt(newValue.value, 10));
            }}
            onClose={handleClose}
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default EmployeeProjectSelectAutocomplete;

//
// Fragments
// ----------------------------------------------------------------------

interface RenderOptionProps {
  availableFrom: Date | string;
  label: string;
  projectsToCheck: string[];
  workAvailability: EmployeeWorkAvailability;
}

const RenderOption: React.FC<RenderOptionProps> = React.memo(
  ({ availableFrom, label, projectsToCheck, workAvailability }) => (
    <Box className={style.renderTag}>
      <Typography className={inheritedStyle.textOption}>{label}</Typography>
      {workAvailability !== EmployeeWorkAvailability.WRONG_DATES ? (
        <Tooltip
          title={`Availability: ${workAvailability}. ${
            availableFrom !== ''
              ? `Employee will become fully available starting from: ${format(new Date(availableFrom), 'MM/dd/yyyy')}.`
              : `Employee is fully available.`
          }`}
        >
          <FiberManualRecord
            className={clsx({
              [style.free]: workAvailability === EmployeeWorkAvailability.FREE,
              [style.busy]: workAvailability === EmployeeWorkAvailability.BUSY,
              [style.unavailable]: workAvailability === EmployeeWorkAvailability.UNAVAILABLE,
            })}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={`Error detected, verify both the employee and project dates for the following project(s): ${projectsToCheck.join(
            ', ',
          )}.`}
        >
          <span className={style.error} />
        </Tooltip>
      )}
    </Box>
  ),
);
