import React, { useEffect } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';

import styles from 'components/RoleChangeMenu/RoleChangeMenu.module.scss';
import { getProjects } from 'store/projectDashboard/thunk';
import { selectProjectFilters } from 'store/projectFilters/selectors';
import { selectRolesData } from 'store/roles/selectors';
import { getAllRoles } from 'store/roles/thunk';
import { changeUserRoles } from 'store/session/thunks';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { getRoleChangeRequest, roleChangeOptions } from 'utils/roleChangeOptions';

interface RoleChangeMenuProps {
  roleChangeAnchor: null | HTMLElement;
  onClearAllMenus: () => void;
}

const RoleChangeMenu: React.FC<RoleChangeMenuProps> = ({ roleChangeAnchor, onClearAllMenus }) => {
  const dispatch = useAppDispatch();
  const rolesLookup = useAppSelector(selectRolesData);

  const projectFilters = useAppSelector(selectProjectFilters);

  useEffect(() => dispatch(getAllRoles()), [dispatch]);

  const handleChangeRole = (reqRole: string) => async () => {
    onClearAllMenus();
    await dispatch(changeUserRoles(getRoleChangeRequest(reqRole, rolesLookup)));
    dispatch(getProjects(projectFilters, {}));
  };

  return (
    <Menu
      anchorEl={roleChangeAnchor}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      className={styles.myTeamMenu}
      classes={{ paper: clsx(styles.paper, styles.marginSession) }}
      getContentAnchorEl={null}
      id='changeRole'
      open={Boolean(roleChangeAnchor)}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      onClose={onClearAllMenus}
    >
      {roleChangeOptions.map((el) => (
        <MenuItem className={styles.sessionMenu} key={el}>
          <Button className={styles.changeRole} fullWidth onClick={handleChangeRole(el)}>
            {el}
          </Button>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default RoleChangeMenu;
