import { createSlice } from '@reduxjs/toolkit';

import { Position } from 'types/Project/Position';
import { RequestStatus } from 'types/RequestStatus';

import reducers from './reducers';

export interface PositionState {
  position: Position[];
  positionRequestStatus: RequestStatus;
}

const initialState: PositionState = {
  position: [],
  positionRequestStatus: 'loading',
};

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers,
});

export const { setPositionData, setPositionRequestStatus } = positionSlice.actions;

export default positionSlice.reducer;
