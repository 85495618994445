import React from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';
import { Cancel, Search } from '@material-ui/icons';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import styles from 'components/KSearchBar/KSearchBar.module.scss';

interface KSearchBarProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
  onClickButton: (value: string) => void;
}

export const KSearchBar: React.FC<KSearchBarProps> = ({ placeholder, value, onChange, onClear, onClickButton }) => {
  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(value);
  };

  const handleClear = (): void => {
    onChange('');
    onClear();
  };

  return (
    <div className={styles.searchBar}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search classes={{ root: clsx(styles.icon, styles.searchIcon) }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end' onClick={handleClear}>
              {!isEmpty(value) && <Cancel classes={{ root: clsx(styles.icon, styles.clearIcon) }} />}
            </InputAdornment>
          ),
        }}
        className={styles.barInput}
        id='k-search-bar'
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
      />
      {onClickButton && (
        <Button className={styles.button} onClick={() => onClickButton(value)}>
          Search
        </Button>
      )}
    </div>
  );
};
