import React, { useContext, useMemo, useState } from 'react';
import { AnchorContext } from 'context/AnchorProvider';
import { noop } from 'lodash';

import EmptyProjects from 'assets/images/EmptyProjects.svg';
import { KTableRow } from 'components/KTable/KTable';
import { CheckboxMenu, createCheckboxMenuValues } from 'components/Menus/CheckboxMenu/CheckboxMenu';
import { DefaultSortMenu } from 'components/Menus/DefaultSortMenu/DefaultSortMenu';
import { EditRowMenu } from 'components/Menus/EditRowMenu/EditRowMenu';
import { SortByNameMenu } from 'components/Menus/SortByNameMenu/SortByNameMenu';
import { PIFTable } from 'components/PIFTable/PIFTable';
import { Rol } from 'constants/Credentials/Rol';
import EmptyState from 'modules/Project/components/EmptyState/EmptyState';
import { selectBusinessUnitsByRoleOptions } from 'store/businessUnit/selector';
import { saveWhereQueryState, setCheckedProjectStatusValues, setCheckedUnits } from 'store/projectFilters';
import {
  selectCheckedProjectStatus,
  selectedCheckedBusinessUnits,
  selectProjectWhereQueries,
} from 'store/projectFilters/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import { Permissions } from 'types/Permissions';
import { ProjectStatus } from 'types/Project';
import { Project } from 'types/Project/Project';
import { IndexSignature } from 'types/utils';
import { exists } from 'utils/generators/generators';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';
import { AbstractHeader, AbstractTableProps, asHeader, HeaderMenuCallback, rowsOf } from 'utils/tables/tableUtils';

import { ProjectRow } from './ProjecRow/ProjectRow';

const checkPermissions = (roles: Rol[], status: ProjectStatus): Permissions => {
  const canEdit =
    hasRole(roles, Rol.PROJECT_INITIATOR) &&
    (status === ProjectStatus.DRAFT || status === ProjectStatus.PENDING || status === ProjectStatus.REJECTED);
  const canDelete =
    hasRole(roles, Rol.PROJECT_INITIATOR) && (status === ProjectStatus.DRAFT || status === ProjectStatus.PENDING);

  const canView =
    hasRole(roles, Rol.PROJECT_INITIATOR) && (status === ProjectStatus.APPRAISING || status === ProjectStatus.APPROVED);
  return {
    canDelete,
    canEdit,
    canView,
  };
};

const getProjectStatusOptions = (roles: Rol[]) => {
  const projectStatusOptions = Object.values(ProjectStatus).map((status: string) => ({ value: status, label: status }));
  if (hasRole(roles, Rol.CO_CREATOR)) {
    return projectStatusOptions.filter(({ label }) => label !== ProjectStatus.DRAFT);
  }
  if (hasRole(roles, Rol.APPRAISER)) {
    return projectStatusOptions.filter(({ label }) => label !== ProjectStatus.DRAFT && label !== ProjectStatus.PENDING);
  }
  return projectStatusOptions;
};

const extractCheckedValues = (items: IndexSignature<boolean>): string[] =>
  Object.entries(items).reduce<string[]>((acc, [value, active]) => {
    if (active) {
      return [...acc, value];
    }
    return acc;
  }, []);

enum MenuAnchors {
  DEFAULT = 'default',
  BY_NAME = 'byName',
  PROJECT_STATUS = 'projectStatus',
  BUSINESS_UNIT = 'businessUnit',
  EDIT_ROW = 'editRow',
}

export const ProjectsTable: React.FC<AbstractTableProps<Project>> = ({
  data: projects,
  currentPage,
  itemsPerPage,
  onColumnSort = noop,
  onDelete = noop,
  onEdit = noop,
  onItemsPerPageChange = noop,
  onPaginationChange = noop,
  onView = noop,
  totalItems,
}) => {
  const dispatch = useAppDispatch();
  const whereQueries = useAppSelector(selectProjectWhereQueries);
  const checkedUnits = useAppSelector(selectedCheckedBusinessUnits);
  const checkedStatus = useAppSelector(selectCheckedProjectStatus);
  const businessUnitByRoleOptions = useAppSelector(selectBusinessUnitsByRoleOptions);
  const roles = useAppSelector(selectSessionRoles);
  const { anchorStatus, showMenu, closeMenu } = useContext(AnchorContext);
  const [projectId, updateProjectId] = useState<string>();
  const [columnName, updateColumnName] = useState<string | string[]>();
  const [currentProjectPermissions, setCurrentProjectPermissions] = useState<Permissions>({
    canDelete: false,
    canEdit: false,
    canView: true,
  });

  const createMenuDispatch =
    (anchorName: string): HeaderMenuCallback =>
    (anchor, selectedColumnName) => {
      showMenu(anchorName, anchor);
      updateColumnName(selectedColumnName);
    };

  const projectTableAbstractHeaders: AbstractHeader[] = [
    {
      label: 'Project Name',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.BY_NAME]),
        onClick: createMenuDispatch(MenuAnchors.BY_NAME),
        columnFieldName: 'name',
      },
      style: { width: '276px' },
    },
    {
      label: 'Status',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.PROJECT_STATUS]),
        onClick: createMenuDispatch(MenuAnchors.PROJECT_STATUS),
        columnFieldName: 'creationStep',
      },
      style: { width: '188px' },
    },
    {
      label: 'Start Date',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'startDate',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'startDate',
      },
      style: { width: '188px' },
    },
    {
      label: 'End Date',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.DEFAULT]) && columnName === 'endDate',
        onClick: createMenuDispatch(MenuAnchors.DEFAULT),
        columnFieldName: 'endDate',
      },
      style: { width: '188px' },
    },
    {
      label: 'Business Unit',
      sortConfig: !hasRole(roles, Rol.CO_CREATOR_SALESFORCE)
        ? {
            isExpanded: exists(anchorStatus[MenuAnchors.BUSINESS_UNIT]),
            onClick: createMenuDispatch(MenuAnchors.BUSINESS_UNIT),
            columnFieldName: 'businessunitId',
          }
        : undefined,
      style: { width: '228px' },
    },
    {
      label: 'More',
      style: { width: '84px' },
    },
  ];

  const handleClickMoreMenu = (event: React.MouseEvent, projectId: string, status: ProjectStatus): void => {
    updateProjectId(projectId);
    setCurrentProjectPermissions(checkPermissions(roles, status));
    showMenu(MenuAnchors.EDIT_ROW, event.currentTarget);
  };

  const asProjectRow = (project: Project): KTableRow => ({
    sectionRows: [
      <ProjectRow
        colSpan={projectTableAbstractHeaders.length}
        key={project.id}
        kind={itemsPerPage === 10 ? 'plump' : 'slim'}
        project={project}
        onClickMenu={handleClickMoreMenu}
      />,
    ],
    rowId: project.id.toString(),
  });

  const handleCloseEditMenu = () => {
    closeMenu(MenuAnchors.EDIT_ROW);
  };

  const handleCheckboxChange = (clickedColumn: string | string[] | undefined, parameters: string[]): void => {
    if (clickedColumn && typeof clickedColumn === 'string') {
      const nextQueries = { ...whereQueries, [clickedColumn]: parameters };
      onPaginationChange(1);
      dispatch(saveWhereQueryState(nextQueries));
    }
  };

  const dispatchWithProjectId = (fn: (id: string | undefined) => void): void => {
    fn(projectId);
  };

  const memoizedBusinessUnitOptions = useMemo(() => businessUnitByRoleOptions, [businessUnitByRoleOptions]);

  return (
    <React.Fragment>
      <PIFTable
        EmptyStateComponent={
          <EmptyState
            description="Hmm, we couldn't find the selected option"
            image={EmptyProjects}
            message='Try selecting another one'
          />
        }
        currentPage={currentPage}
        headers={projectTableAbstractHeaders.map(asHeader)}
        itemsPerPage={itemsPerPage}
        rows={rowsOf(projects, asProjectRow)}
        totalItems={totalItems}
        typeShowed='project'
        onChangeItemsPerPage={onItemsPerPageChange}
        onChangePage={onPaginationChange}
      />
      <SortByNameMenu
        anchorEl={anchorStatus[MenuAnchors.BY_NAME]}
        id='projectsSortNameMenu'
        onClose={() => closeMenu(MenuAnchors.BY_NAME)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
      <CheckboxMenu
        anchorEl={anchorStatus[MenuAnchors.PROJECT_STATUS]}
        id='projectsStatusMenu'
        options={getProjectStatusOptions(roles)}
        values={checkedStatus || createCheckboxMenuValues(getProjectStatusOptions(roles), true)}
        onCheckedValuesChange={(checked) => {
          handleCheckboxChange(columnName, extractCheckedValues(checked));
          dispatch(setCheckedProjectStatusValues(checked));
        }}
        onClose={() => closeMenu(MenuAnchors.PROJECT_STATUS)}
      />
      <DefaultSortMenu
        anchorEl={anchorStatus[MenuAnchors.DEFAULT]}
        id='projectsDefaultSortMenu'
        onClose={() => closeMenu(MenuAnchors.DEFAULT)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
      <CheckboxMenu
        anchorEl={anchorStatus[MenuAnchors.BUSINESS_UNIT]}
        id='projectsBusinessUnitMenu'
        options={memoizedBusinessUnitOptions}
        values={checkedUnits || createCheckboxMenuValues(businessUnitByRoleOptions, true)}
        onCheckedValuesChange={(checked) => {
          handleCheckboxChange(columnName, extractCheckedValues(checked));
          dispatch(setCheckedUnits(checked));
        }}
        onClose={() => closeMenu(MenuAnchors.BUSINESS_UNIT)}
      />
      <EditRowMenu
        anchorEl={anchorStatus[MenuAnchors.EDIT_ROW]}
        id='projectsEditRowMenu'
        permissions={currentProjectPermissions}
        onClose={handleCloseEditMenu}
        onDelete={() => dispatchWithProjectId(onDelete)}
        onEdit={() => dispatchWithProjectId(onEdit)}
        onView={() => dispatchWithProjectId(onView)}
      />
    </React.Fragment>
  );
};
