import { onlyShowFile, PIFProject, ProjectTeamMember } from 'types/PIFProject';
import { ProjectType } from 'types/Project';
import { NewProjectModel } from 'types/Project/NewProjectModel';
import { Project, ProjectDetails } from 'types/Project/Project';
import { ProjectModel } from 'types/Project/ProjectModel';
import { TeamPosition } from 'types/Project/TeamPosition';
import { IndexSignature } from 'types/utils';
import { enFormatDate } from 'utils/formatters/date';

export const mapProjectModelToProject = (
  projects: ProjectModel[],
  rejectionNotes?: IndexSignature<string>,
): Project[] =>
  projects.map(({ id, name, businessunitId, contractTypeId, creationStep, startDate, endDate }) => ({
    id: id.toString(),
    projectName: name,
    businessUnitId: businessunitId,
    contractTypeId,
    startDate: enFormatDate(startDate),
    endDate: enFormatDate(endDate),
    creationStep,
    rejectionNotes: rejectionNotes && rejectionNotes[id],
  }));

export const mapPIFProjectToNewProjectModel = (PIFProject: PIFProject): NewProjectModel => {
  const {
    budgetForecasting,
    businessUnit,
    contractType,
    comments,
    currencyId,
    customerId,
    endDate,
    projectName,
    projectType,
    startDate,
    approverId,
    totalForecastedHours,
  } = PIFProject;
  const NewProject: NewProjectModel = {
    budget: budgetForecasting ? +budgetForecasting : 0,
    extraInformation: comments,
    currencyId: +currencyId,
    customerId: customerId ? +customerId : 0,
    endDate: endDate || '',
    name: projectName || '',
    startDate: startDate || '',
    projectType: ProjectType[projectType || 0] as unknown as ProjectType,
    totalForecastedHours: totalForecastedHours ? +totalForecastedHours : 0,
  };
  if (approverId) {
    NewProject.approverId = approverId;
  }
  if (businessUnit) {
    NewProject.businessunitId = businessUnit;
  }
  if (contractType) {
    NewProject.contractTypeId = contractType;
  }
  return NewProject;
};

export const mapProjectModelToProjectDetails = (Project: ProjectModel): ProjectDetails => {
  const {
    budget,
    businessunit,
    businessunitId,
    contractType,
    contractTypeId,
    cost,
    currency,
    currencyId,
    customer,
    customerId,
    endDate,
    extraInformation,
    id,
    name,
    startDate,
    creationStep,
    approver,
    approverId,
    totalForecastedHours,
    projectType,
    projectAttachments,
  } = Project;
  const projectTypeId = Number(ProjectType[projectType as unknown as ProjectType]);
  return {
    budgetForecasting: budget,
    businessUnitId: businessunitId,
    businessUnitName: businessunit?.name || '',
    contractTypeId,
    contractTypeName: contractType?.name || '',
    comments: extraInformation,
    cost: cost ?? 0,
    currencyId,
    currencySymbol: currency?.currencySymbol || '',
    customerId,
    customerName: customer?.name || '',
    endDate,
    id,
    projectName: name,
    projectTypeId,
    projectTypeName: projectType,
    projectAttachments,
    startDate,
    creationStep,
    approverId,
    approverName: approver ? `${approver.firstName} ${approver.lastName}` : '',
    totalForecastedHours,
  };
};

export const mapProjectAndPositionModelToPIF = (
  ProjectDetails: ProjectDetails,
  TeamPositions: TeamPosition[],
): PIFProject => {
  const {
    budgetForecasting,
    businessUnitId,
    comments,
    contractTypeId,
    currencyId,
    customerId,
    endDate,
    projectName,
    projectTypeId,
    projectAttachments,
    startDate,
    approverId,
    totalForecastedHours,
  } = ProjectDetails;
  const projectTeam = TeamPositions.map(
    ({
      id,
      billRate,
      currencyId,
      employeeId,
      positionId,
      endDate,
      startDate,
      skills,
      technologies,
      weeklyForecastedHours,
    }): ProjectTeamMember => ({
      id,
      billRate,
      currencyId,
      employeeId,
      jobPosition: positionId,
      endDate,
      startDate,
      skills: skills?.map(({ id }) => id),
      technologies: technologies?.map(({ id }) => id),
      weeklyForecastedHours,
    }),
  );
  return {
    budgetForecasting,
    businessUnit: businessUnitId,
    comments: comments || '',
    contractType: contractTypeId,
    currencyId,
    customerId,
    endDate,
    projectName,
    projectType: projectTypeId,
    startDate,
    approverId: approverId || null,
    totalForecastedHours: totalForecastedHours || null,
    projectTeam,
    projectAttachments: projectAttachments?.length
      ? (projectAttachments.map((attachment) => ({
          attachmentId: attachment.id,
          name: attachment.fileName,
        })) as onlyShowFile[])
      : ([] as onlyShowFile[]),
  };
};
