import React from 'react';
import { Container } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import styles from './SearchSkeleton.module.scss';

export const SearchSkeleton: React.FC = () => (
  <Container className={styles.searchSkeleton}>
    <Skeleton height='48px' variant='rect' width='564px' />
  </Container>
);
