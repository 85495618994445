import { PayloadAction } from '@reduxjs/toolkit';

import { Credentials } from 'types/Credentials/Credentials';
import { User } from 'types/User';

import { SessionState, SessionStatus } from '.';

const logOut = (state: SessionState): void => {
  state.isAuthenticated = false;
  state.credentials = undefined;
  state.status = SessionStatus.logOut;
};

const confirmSession = (state: SessionState): void => {
  state.isSessionConfirmed = true;
};

const updateCurrentUser = (state: SessionState, action: PayloadAction<User>): void => {
  state.currentUser = action.payload;
  state.isAuthenticated = true;
};

const updateIsAuthenticated = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.isAuthenticated = action.payload;
};

const updateSessionStatus = (state: SessionState, action: PayloadAction<SessionStatus>): void => {
  state.status = action.payload;
};

const setCredentials = (state: SessionState, action: PayloadAction<Credentials>): void => {
  state.credentials = action.payload;
};

const setHasError = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.hasError = action.payload;
};

const setErrorMessage = (state: SessionState, action: PayloadAction<string>): void => {
  state.errorMessage = action.payload;
};

const setIsForgotPasswordSuccessful = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.isForgotPasswordSuccessful = action.payload;
};

const setIsLoading = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.isLoading = action.payload;
};

const setIsRegisterSuccessful = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.isRegisterSuccessful = action.payload;
};

const setIsRefreshing = (state: SessionState, action: PayloadAction<boolean>): void => {
  state.isRefreshing = action.payload;
};

const reducers = {
  logOut,
  confirmSession,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
  setCredentials,
  setHasError,
  setErrorMessage,
  setIsForgotPasswordSuccessful,
  setIsLoading,
  setIsRegisterSuccessful,
  setIsRefreshing,
};

export default reducers;
