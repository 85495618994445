import { Currency } from 'types/Currency';
import { CurrencyModel } from 'types/models/CurrencyModel';

export const mapCurrencyModelToCurrency = (CurrencyModel: CurrencyModel[]): Currency[] =>
  CurrencyModel.reduce((acc, { currencyCode, currencySymbol, id, name }) => {
    if (id) {
      acc.push({
        currency: name,
        currencyCode,
        currencySymbol,
        id,
      });
    }
    return acc;
  }, [] as Currency[]);
