import { createSlice } from '@reduxjs/toolkit';

import reducers from 'store/notifications/reducers';
import { NotificationEmployee } from 'types/models/NotificationModel';
import { RequestNotificationDropdown } from 'types/Notification';
import { RequestStatus } from 'types/RequestStatus';

export interface NotificationsState {
  dropdownRequestStatus: RequestStatus;
  isPageLimit: boolean;
  offsetIncrement: number;
  recordRequestStatus: RequestStatus;
  requestNotifications: NotificationEmployee[];
  requestNotificationsDropdown: RequestNotificationDropdown[];
  requestNotificationsRecord: NotificationEmployee[];
  statusNotificationsDropdown: NotificationEmployee[];
  statusNotificationsRecord: NotificationEmployee[];
  updateStatus: RequestStatus;
}

const initialState: NotificationsState = {
  dropdownRequestStatus: 'idle',
  isPageLimit: false,
  offsetIncrement: 0,
  recordRequestStatus: 'idle',
  requestNotifications: [],
  requestNotificationsDropdown: [],
  requestNotificationsRecord: [],
  statusNotificationsDropdown: [],
  statusNotificationsRecord: [],
  updateStatus: 'idle',
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers,
});

export const {
  setDropdownNotificationRequestStatus,
  setIsPageLimit,
  setNotificationsUpdateStatus,
  setOffsetIncrement,
  setRecordNotificationRequestStatus,
  setRequestNotifications,
  setRequestNotificationsDropdown,
  setRequestNotificationsRecord,
  setStatusNotificationsDropdown,
  setStatusNotificationsRecord,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
