import { ProjectAppraisalModel } from 'types/Project/ProjectAppraisal/ProjectAppraisalModel';

import { ProjectAppraisalStatus } from '../types/Project';

import { BaseService } from './baseService';

class ProjectAppraisalService extends BaseService<ProjectAppraisalModel> {
  controllerName = 'projectappraisal';

  getByProjectId = async (id: number, status: ProjectAppraisalStatus): Promise<ProjectAppraisalModel> => {
    const {
      data: [uniqueAppraisal],
    } = await this.getAll({
      where: [
        {
          status,
        },
        {
          projectId: id,
        },
      ],
      order: [['createdAt', 'DESC']],
      limit: 1,
    });

    return uniqueAppraisal;
  };
}

export const projectAppraisalService = new ProjectAppraisalService();
