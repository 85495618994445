import { Employee } from 'types/Employee';
import { EmployeeModel } from 'types/models/EmployeeModel';
import { enFormatDate } from 'utils/formatters/date';

// TODO: add imageurl
export const mapEmployeeModelToEmployee = ({
  firstName,
  file,
  hiringDate,
  id,
  isDepartmentManager,
  jobPosition,
  lastName,
  managerId,
  personalEmail,
  phone,
}: EmployeeModel): Employee => {
  const date = new Date(hiringDate);
  return {
    firstName,
    hiringDate: enFormatDate(date),
    id,
    imageUrl: file?.url || '',
    isDepartmentManager,
    lastName,
    managerId,
    personalEmail,
    phone: phone || '',
    position: jobPosition,
  };
};

export const mapEmployeesModelToEmployees = (employees: EmployeeModel[]): Employee[] =>
  employees.map(
    ({ firstName, hiringDate, id, isDepartmentManager, managerId, jobPosition, lastName, personalEmail, phone }) => {
      const date = new Date(hiringDate);
      return {
        firstName,
        hiringDate: enFormatDate(date),
        id,
        imageUrl: '',
        isDepartmentManager,
        lastName,
        personalEmail,
        phone: phone || '',
        position: jobPosition,
        managerId,
      };
    },
  );
