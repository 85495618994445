import React, { FC, Fragment, useEffect, useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';

import NoOptionsButton from 'modules/Project/components/NoOptionsButton/NoOptionsButton';
import { Option } from 'types/Form/FormCommons';

import styles from './ControlledAutoComplete.module.scss';

interface AutoCompleteProps {
  defaultValues: Option<number | null>[];
  label: string;
  name: string;
  noOptionsText?: string;
  options: Option<number | null>[];
  onCreateItem?: (type: string, value: string) => void;
  onSetFieldValue: (field: string, value: (number | null)[]) => void;
}

export const ControlledAutoComplete: FC<AutoCompleteProps> = ({
  label,
  name,
  noOptionsText,
  options,
  defaultValues,
  onCreateItem,
  onSetFieldValue,
}: AutoCompleteProps) => {
  const [shrink, setShrik] = useState(false);
  const [values, setValues] = useState<Option<number | null>[]>([]);
  const [textfieldValue, setTextfieldValue] = useState<string>('');
  const [fixedPopover, setFixedPopover] = useState<boolean>(false);

  const handleGetOptionLabel = (option: Option<number | null>) => option.label;

  const handleGetOptionSelected = (option: Option<number | null>, value: Option<number | null>) =>
    option.value === value.value;

  const handleNotRenderTags = () => null;

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: Option<number | null>[]) => {
    updateValues(newValue);
  };

  const handleFocus = () => {
    setShrik(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) setShrik(false);
  };

  const handleDelete = (id: number | null) => () => {
    updateValues(values.filter((v) => v.value !== id));
  };

  const updateValues = (updatedValue: Option<number | null>[]) => {
    setValues(updatedValue);
    onSetFieldValue(
      name,
      updatedValue.map(({ value }) => value),
    );
  };

  const handleAddNew = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onCreateItem) {
      onCreateItem('technology', textfieldValue);
    }
  };

  const handleTexfieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextfieldValue(e.target.value);
  };

  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <Fragment>
      <Autocomplete
        classes={{
          popupIndicator: styles.icon,
          noOptions: styles.noOption,
        }}
        debug={fixedPopover}
        getOptionLabel={handleGetOptionLabel}
        getOptionSelected={handleGetOptionSelected}
        id='controllable-demo'
        multiple
        noOptionsText={
          noOptionsText || <NoOptionsButton label={label} setter={setFixedPopover} onClickHandler={handleAddNew} />
        }
        options={options}
        popupIcon={<KeyboardArrowDown />}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink,
              classes: {
                root: shrink ? styles.offset : styles.offsetMargin,
              },
            }}
            InputProps={{
              classes: {
                root: styles.input,
              },
              ...params.InputProps,
            }}
            className={styles.containedAutocomplete}
            label={label}
            value={textfieldValue}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleTexfieldChange}
            onFocus={handleFocus}
          />
        )}
        renderTags={handleNotRenderTags}
        value={values}
        onChange={handleChange}
      />
      <Fragment>
        {values.map((v) => (
          <Chip
            className={styles.chip}
            deleteIcon={<CloseIcon />}
            key={v.value}
            label={v.label}
            onDelete={handleDelete(v.value)}
          />
        ))}
      </Fragment>
    </Fragment>
  );
};
