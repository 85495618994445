import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { TextFieldTypes } from 'constants/textFieldTypes';
import { KTextField } from 'modules/Project/components/KTextField/KTextField';
import { JobPositionSchemaFactory } from 'modules/Project/components/NewJobPositionForm/JobPositionSchemaFactory';
import styles from 'modules/Project/components/NewJobPositionForm/NewJobPositionForm.module.scss';
import { selectPositionData } from 'store/position/selectors';
import { createPosition } from 'store/position/thunk';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';

interface NewJobPositionFormProps {
  name: string;
  onCancel: () => void;
}

const initialState = {
  acronym: '',
  name: '',
};

const NewJobPositionForm = ({ name, onCancel }: NewJobPositionFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const jobPositionsData = useAppSelector(selectPositionData);

  const formik = useFormik({
    initialValues: { ...initialState, name },
    validationSchema: JobPositionSchemaFactory(jobPositionsData),
    onSubmit: (values) => {
      dispatch(
        createPosition(values, {
          onSuccess: () => {
            enqueueSnackbar(`${values.name} has been successfully added !`, SnackbarVariant.SUCCESS);
            onCancel();
          },
          onError: () => enqueueSnackbar('An error occurred while adding a new  position', SnackbarVariant.ERROR),
        }),
      );
    },
    validateOnChange: true,
  });

  const { dirty, isValid, handleSubmit, submitForm } = formik;

  return (
    <div className={styles.modalContainer}>
      <Box className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <Grid className={styles.formTitle} item xs={12}>
            <Typography className={styles.title} variant='h1'>
              Add Job Position
            </Typography>
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField {...formik} label='Name' name='name' type={TextFieldTypes.TEXT} />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField {...formik} label='Acronym' name='acronym' type={TextFieldTypes.TEXT} />
          </Grid>
          <Box display='flex' justifyContent='flex-end' mt='auto'>
            <Button className={clsx(styles.button, styles.buttonCancel)} variant='outlined' onClick={onCancel}>
              Cancel
            </Button>
            <Button className={styles.button} disabled={!(isValid && dirty)} variant='contained' onClick={submitForm}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default NewJobPositionForm;
