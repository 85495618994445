import React, { CSSProperties, ReactElement } from 'react';

import styles from './Filler.module.scss';

interface FillerProps {
  style?: CSSProperties;
  variant?: 'horizontal' | 'vertical';
}

export const Filler: React.FC<FillerProps> = ({ variant = 'horizontal', style }): ReactElement =>
  variant === 'horizontal' ? (
    <div className={styles.horizontalFiller} style={style} />
  ) : (
    <div className={styles.verticalFiller} style={style} />
  );
