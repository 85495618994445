import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import reducers from 'store/projects/reducers';
import { EmployeeAvailabilitySelectOption } from 'types/Project/Project';
import { RequestStatus as RequestStatusTypes } from 'types/RequestStatus';

export interface ProjectState {
  employeeAvailabilityRequestStatus: RequestStatusTypes;
  employeeAvailabilitySelectOptions: EmployeeAvailabilitySelectOption[];
}

const initialState: ProjectState = {
  employeeAvailabilityRequestStatus: RequestStatus.IDLE,
  employeeAvailabilitySelectOptions: [],
};

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers,
});

export const { setEmployeeAvailabilityRequestStatus, setEmployeeAvailabilitySelectOptions } = projectSlice.actions;

export default projectSlice.reducer;
