import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { ProjectsState } from 'store/projectInitiationForm/index';
import { ProjectDetails } from 'types/Project/Project';

const setProjectCreationStatus = (state: ProjectsState, action: PayloadAction<RequestStatus>): void => {
  state.pifCreationStatus = action.payload;
};

const setProjectId = (state: ProjectsState, action: PayloadAction<number | null>): void => {
  state.projectId = action.payload;
};

const setProjectUpdateStatus = (state: ProjectsState, action: PayloadAction<RequestStatus>): void => {
  state.pifUpdateStatus = action.payload;
};

const setProjectDetailsData = (state: ProjectsState, action: PayloadAction<ProjectDetails>): void => {
  state.projectDetailsData = action.payload;
};

const setProjectDetailsStatus = (state: ProjectsState, action: PayloadAction<RequestStatus>): void => {
  state.projectDetailsStatus = action.payload;
};

const setProjectSubmitReviewStatus = (state: ProjectsState, action: PayloadAction<RequestStatus>): void => {
  state.projectSubmitReviewStatus = action.payload;
};

const reducers = {
  setProjectCreationStatus,
  setProjectId,
  setProjectUpdateStatus,
  setProjectDetailsData,
  setProjectDetailsStatus,
  setProjectSubmitReviewStatus,
};

export default reducers;
