/* eslint-disable no-console */
import React, { FC, Fragment, useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import isAfter from 'date-fns/isAfter';
import { FormikProps } from 'formik';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { FormSectionTitle } from 'components/FormSectionTitle/FormSectionTitle';
import { Rol } from 'constants/Credentials/Rol';
import { RequestStatus } from 'constants/requestStatus';
import { TextFieldTypes } from 'constants/textFieldTypes';
import ChipsRow from 'modules/Project/components/ChipsRow/ChipsRow';
import { KCalendarField } from 'modules/Project/components/KCalendarField/KCalendarField';
import { KMoneyField } from 'modules/Project/components/KMoneyField/KMoneyField';
import { KTextField } from 'modules/Project/components/KTextField/KTextField';
import styles from 'modules/Project/components/NewProjectForm/ProjectDetailsForm/ProjectDetailsForm.module.scss';
import { ProjectDetailsSkeleton } from 'modules/Project/components/PIFSkeleton/ProjectDetailsSkeleton/ProjectDetailsSkeleton';
import { SelectAutoComplete } from 'modules/Project/components/SelectAutocomplete/SelectAutoComplete';
import { selectBusinessUnitOptions, selectBusinessUnitStatus } from 'store/businessUnit/selector';
import { selectContractTypeOptions, selectContractTypeStatus } from 'store/contractType/selector';
import { selectCurrencyData, selectCurrencyRequestStatus } from 'store/currency/selectors';
import { selectCustomerData, selectCustomerRequestStatus } from 'store/customer/selectors';
import { selectEmployees, selectEmployeesStatus } from 'store/employee/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import { Option } from 'types/Form/FormCommons';
import { PIFProject } from 'types/PIFProject';
import { ProjectType } from 'types/Project';
import { titleCase, upperCase } from 'utils/formatters/text';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';

import UploadFile from '../../UploadFile/UploadFile';

interface ProjectDetailsFormProps {
  formik: FormikProps<PIFProject>;
  fromModal?: boolean;
  shouldConfirmReload?: boolean;
}

const PROJECT_TYPE = Object.entries(ProjectType)
  .filter(([key]) => !Number.isNaN(+key))
  .map(([key, label]) => ({
    label: label === 'itk' ? upperCase(label.toString()) : titleCase(label.toString()),
    value: +key,
  }));

export const ProjectDetailsForm: FC<ProjectDetailsFormProps> = ({
  formik,
  shouldConfirmReload,
  fromModal,
}: ProjectDetailsFormProps) => {
  const DEFAULT_CONFIRMATION_MESSAGE = 'By doing this, the changes made will not be saved.';
  const MAX_COMMENT_LENGTH = 1000;
  const currencyData = useAppSelector(selectCurrencyData);
  const currencyRequestStatus = useAppSelector(selectCurrencyRequestStatus);
  const businessUnitData = useAppSelector(selectBusinessUnitOptions);
  const businessUnitStatus = useAppSelector(selectBusinessUnitStatus);
  const contractTypeData = useAppSelector(selectContractTypeOptions);
  const contractTypeStatus = useAppSelector(selectContractTypeStatus);
  const customerData = useAppSelector(selectCustomerData);
  const customerStatus = useAppSelector(selectCustomerRequestStatus);
  const employeesData = useAppSelector(selectEmployees);
  const employeesStatus = useAppSelector(selectEmployeesStatus);
  const roles = useAppSelector(selectSessionRoles);
  const { values, resetForm, setFieldTouched, setFieldValue } = formik;
  const { comments, endDate, startDate } = values;

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
    return DEFAULT_CONFIRMATION_MESSAGE;
  };

  const handleFieldChange = (value: File[] | FileList) => {
    setFieldValue('projectAttachments', value);
  };

  const employeeMemoizedTags = useMemo(
    () =>
      employeesData.reduce<Option<number>[]>((acc, { firstName, lastName, id }) => {
        if (id) {
          acc.push({ label: `${firstName} ${lastName}`, value: id });
        }
        return acc;
      }, []),
    [employeesData],
  );

  useEffect(() => {
    if (shouldConfirmReload) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldConfirmReload]);

  useEffect(
    () => () => {
      if (fromModal) {
        resetForm();
      }
    },
    [fromModal, resetForm],
  );

  useEffect(() => {
    if (startDate && endDate && isAfter(Date.parse(startDate), Date.parse(endDate))) {
      setFieldTouched('endDate', true);
    }
  }, [endDate, startDate, setFieldTouched]);

  const getDisabledBusinessUnitOptions = (businessUnits: Option<number>[]) => {
    const values = businessUnits.reduce<number[]>((acum, businessUnit) => {
      if (businessUnit.label === 'Salesforce') {
        return [...acum, businessUnit.value];
      }
      return acum;
    }, []);

    return values;
  };

  const renderProjectDetailsForm = () => {
    if (
      currencyRequestStatus === RequestStatus.FAILED ||
      businessUnitStatus === RequestStatus.FAILED ||
      contractTypeStatus === RequestStatus.FAILED ||
      customerStatus === RequestStatus.FAILED ||
      employeesStatus === RequestStatus.FAILED
    ) {
      return (
        <ErrorMessage
          subtitle="We're having trouble loading the info from the server. Check your connection and try again."
          title='Something went wrong'
        />
      );
    }
    if (
      currencyRequestStatus === RequestStatus.SUCCESS &&
      businessUnitStatus === RequestStatus.SUCCESS &&
      contractTypeStatus === RequestStatus.SUCCESS &&
      customerStatus === RequestStatus.SUCCESS &&
      employeesStatus === RequestStatus.SUCCESS
    ) {
      return (
        <Fragment>
          <Grid className={styles.formTitle} item xs={12}>
            <FormSectionTitle text='General Information' />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <SelectAutoComplete
              {...formik}
              disabled={hasRole(roles, Rol.CO_CREATOR)}
              label='Customer Name (Person or Company)'
              name='customerId'
              noOptionsText='No Customer Found'
              tags={customerData.map(({ label, id }) => ({ label, value: id }))}
            />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField {...formik} label='Project Name' name='projectName' type={TextFieldTypes.TEXT} />
          </Grid>
          <Box margin={fromModal ? '-2px 0 4px 0' : '4px 0'} width='100%'>
            <ChipsRow {...formik} name='projectType' options={PROJECT_TYPE} title='Project Type' />
          </Box>
          <Box height='fit-content' margin={fromModal ? '4px 0 10px 0' : '4px 0'} width='100%'>
            <ChipsRow
              {...formik}
              disabled={hasRole(roles, Rol.CO_CREATOR_SALESFORCE)}
              disabledChips={
                hasRole(roles, Rol.CO_CREATOR_ENGINEERING)
                  ? getDisabledBusinessUnitOptions(businessUnitData)
                  : undefined
              }
              name='businessUnit'
              options={businessUnitData}
              title='Business Unit'
            />
          </Box>
          <Box height='fit-content' margin={fromModal ? '4px 0 10px 0' : '4px 0'} width='100%'>
            <ChipsRow {...formik} name='contractType' options={contractTypeData} title='Contract Type' />
          </Box>
          <Grid className={styles.formTitle} item xs={12}>
            <FormSectionTitle text='Specs' />
          </Grid>
          <Grid className={styles.formField} item md xs={12}>
            <KCalendarField {...formik} label='Start Date' name='startDate' />
          </Grid>
          <div className={styles.formFieldCalendarSpacing} />
          <Grid className={styles.formField} item md xs={12}>
            <KCalendarField {...formik} label='End Date' minValue={startDate} name='endDate' />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KMoneyField
              {...formik}
              currencyName='currencyId'
              currencyOptions={currencyData}
              label='Invoice'
              name='budgetForecasting'
            />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField
              {...formik}
              label='Project Total Forecasted Hours'
              name='totalForecastedHours'
              type={TextFieldTypes.INTEGER}
            />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <UploadFile uploadFile={values.projectAttachments as File[]} onChange={handleFieldChange} />
          </Grid>
          <Grid className={styles.formTitle} item xs={12}>
            <FormSectionTitle optional text='Additional Details' />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <SelectAutoComplete
              {...formik}
              label='Timesheet Approver'
              name='approverId'
              noOptionsText='No Timesheet Approver Found'
              tags={employeeMemoizedTags}
            />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField
              {...formik}
              label='Comments'
              maxLength={MAX_COMMENT_LENGTH}
              multiline
              name='comments'
              rows={7}
              type={TextFieldTypes.TEXT}
            />
            <Grid className={styles.commentsCount} container item justifyContent='flex-end' xs={12}>
              <span>
                {comments.length}/{MAX_COMMENT_LENGTH}
              </span>
            </Grid>
          </Grid>
        </Fragment>
      );
    }
    return <ProjectDetailsSkeleton />;
  };

  return (
    <Fragment>
      <Grid
        classes={{ root: fromModal ? styles.projectDetailsModalContainer : styles.projectDetailsContainer }}
        container
      >
        {renderProjectDetailsForm()}
      </Grid>
      <Prompt message={DEFAULT_CONFIRMATION_MESSAGE} when={!!shouldConfirmReload} />
    </Fragment>
  );
};
