import { RequestStatus } from 'constants/requestStatus';
import { roleService } from 'services/roleService';
import { setRolesData, setRolesRequestStatus } from 'store/roles';
import { AppThunk } from 'store/store';
import { fieldSorter } from 'utils/fieldSorter';
import { mapRoleModeltoRoles } from 'utils/parsers/roles';

export const getAllRoles = (): AppThunk => async (dispatch) => {
  dispatch(setRolesRequestStatus(RequestStatus.LOADING));
  try {
    const { data } = await roleService.getAll();
    const sortedRoles = fieldSorter(data, 'name');
    const roles = mapRoleModeltoRoles(sortedRoles);

    dispatch(setRolesData(roles));
    dispatch(setRolesRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setRolesRequestStatus(RequestStatus.FAILED));
  }
};
