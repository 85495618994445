import { RequestStatus } from 'constants/requestStatus';
import { contractTypeService } from 'services/contractTypeService';
import { AppThunk } from 'store/store';
import { mapContractTypeToFilterOptions } from 'utils/parsers/contractType';

import { setContractTypeOptions, setContractTypeStatus } from './index';

export const getContractTypes = (): AppThunk => async (dispatch) => {
  dispatch(setContractTypeStatus(RequestStatus.LOADING));
  try {
    const { data } = await contractTypeService.getAll();
    dispatch(setContractTypeOptions(mapContractTypeToFilterOptions(data)));
    dispatch(setContractTypeStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setContractTypeStatus(RequestStatus.FAILED));
  }
};
