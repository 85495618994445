import React, { FC, Fragment, useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import clsx from 'clsx';

import { getIcon } from 'assets/Icons';

import styles from './AppraiseProjectForm.module.scss';

const ThumbDown = getIcon({ name: 'ThumbUp', variant: 'default' });
const ThumbUp = getIcon({ name: 'ThumbDown', variant: 'default' });
const ThumbDownActive = getIcon({ name: 'ThumbUp', variant: 'white' });
const ThumbUpActive = getIcon({ name: 'ThumbDown', variant: 'white' });

interface AppraiseProjectFormProps {
  customApproveError?: string;
  description?: string;
  feedBackHelperText?: string;
  hasApproveError?: boolean;
  onValueChange: (feedback: string, value: boolean) => void;
  overwriteHoursRender?: () => React.ReactElement;
}

export const AppraiseProjectForm: FC<AppraiseProjectFormProps> = ({
  customApproveError,
  description,
  feedBackHelperText,
  hasApproveError,
  onValueChange,
  overwriteHoursRender,
}: AppraiseProjectFormProps) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [currentValue, setCurrentValue] = useState<boolean | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(event.target.value, !!currentValue);
  };
  const handleClick = (_e: React.MouseEvent, value: boolean) => {
    setOpenFeedback(!value);
    setCurrentValue(value);
    onValueChange('', value);
  };
  return (
    <Grid className={styles.formContainer} container>
      {overwriteHoursRender && <Fragment>{overwriteHoursRender()}</Fragment>}
      <Grid item xs={12}>
        {description}
      </Grid>
      {hasApproveError && (
        <Grid className={styles.approveError} item xs={12}>
          {customApproveError}
        </Grid>
      )}
      <Grid className={styles.iconButtonContainer} container item justifyContent='center' xs={1}>
        <IconButton
          aria-label='No'
          className={clsx(styles.button, { [styles.buttonSelected]: currentValue !== null && !currentValue })}
          color='inherit'
          onClick={(e) => handleClick(e, false)}
        >
          {currentValue !== null && !currentValue ? (
            <ThumbUpActive className={styles.icon} />
          ) : (
            <ThumbUp className={styles.icon} />
          )}
        </IconButton>
        <span className={styles.label}>No</span>
      </Grid>
      <Grid className={styles.iconButtonContainer} container item justifyContent='center' xs={1}>
        <IconButton
          aria-label='Yes'
          className={clsx(styles.button, { [styles.buttonSelected]: currentValue !== null && currentValue })}
          color='inherit'
          onClick={(e) => handleClick(e, true)}
        >
          {currentValue !== null && currentValue ? (
            <ThumbDownActive className={styles.icon} />
          ) : (
            <ThumbDown className={styles.icon} />
          )}
        </IconButton>
        <span className={styles.label}>Yes</span>
      </Grid>

      {openFeedback && (
        <Grid className={styles.inputContainer} item xs={12}>
          <TextField
            FormHelperTextProps={{ className: styles.helperText }}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              className: styles.multilineInput,
            }}
            fullWidth
            helperText={feedBackHelperText ?? ''}
            label='Feedback'
            multiline
            placeholder='Share your thoughts'
            rows={5}
            variant='outlined'
            onChange={handleChange}
          />
        </Grid>
      )}
    </Grid>
  );
};
