import React, { FC } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';

import { IndexSignature } from 'types/utils';

export type TooltipKind = 's' | 'm' | 'lg' | 'xl';

interface MyTooltipProps {
  children: React.ReactElement;
  kind: TooltipKind;
  title: React.ReactElement | string;
}
const TOOLTIP_WIDTH: IndexSignature<string> = {
  s: '128px',
  m: '216px',
  lg: '304px',
  xl: '392px',
};
const useStyles = (kind: TooltipKind) =>
  makeStyles(() => ({
    tooltip: {
      minWidth: TOOLTIP_WIDTH[kind],
      maxWidth: TOOLTIP_WIDTH[kind],
      maxHeight: '80px',
      textAlign: 'center',
    },
  }))();

export const PIFTooltip: FC<MyTooltipProps> = ({ title, kind, children }: MyTooltipProps) => {
  const { tooltip } = useStyles(kind);
  return (
    <Tooltip classes={{ tooltip }} placement='top-start' title={title || ''}>
      {children}
    </Tooltip>
  );
};
