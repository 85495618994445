import React, { ReactElement } from 'react';
import { TableRow } from '@material-ui/core';

import { ExpandableCommonProps } from 'components/Expandable/types';

interface ExpandableRowProps {
  expandedRowContent: ReactElement;
  isExpandable: boolean;
}

export const ExpandableRow: React.FC<ExpandableRowProps & ExpandableCommonProps> = ({
  isExpandable,
  isExpanded,
  children,
  expandedRowContent,
}) =>
  isExpandable ? (
    <React.Fragment>
      <TableRow>{children}</TableRow>
      {isExpanded && <TableRow>{expandedRowContent}</TableRow>}
    </React.Fragment>
  ) : (
    <TableRow>{children}</TableRow>
  );
