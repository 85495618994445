import React, { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { Box, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { PDFDocumentProxy } from 'pdfjs-dist/types/display/api';

import { AttachmentTypes } from 'constants/attachmentTypes';
import { DATE_FORMAT } from 'constants/dateFormat';
import { FileAttached } from 'types/PreviewAttachment';
import { useRefDimensions } from 'utils/hooks/useRefDimensions';

import styles from './PreviewAttachments.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PreviewAttachmentsProps {
  attachments: FileAttached[];
  onClose: () => void;
}

const getAttachmentBodyByIndex = (attachments: FileAttached[], numberOfAttach: number): FileAttached =>
  attachments[numberOfAttach - 1];

const PreviewAttachments: React.FC<PreviewAttachmentsProps> = ({ attachments, onClose }: PreviewAttachmentsProps) => {
  const [numberOfDocument, setNumberOfDocument] = useState(1);
  const [isError, setIsError] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const numberOfDocuments = attachments.length;
  const divRef = useRef<HTMLDivElement>(null);
  const { width } = useRefDimensions(divRef);
  const pageIndexes = Array.from({ length: numberOfPages }, (_, index) => index + 1);
  const { createdAt, fileName, path, type } = getAttachmentBodyByIndex(attachments, numberOfDocument);
  const parsedCreatedAt = format(new Date(createdAt), DATE_FORMAT);

  const handleIncrementPage = () => {
    setNumberOfDocument(numberOfDocument + 1);
    setIsError(false);
  };

  const handleDecrementPage = () => {
    setNumberOfDocument(numberOfDocument - 1);
    setIsError(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => setNumberOfPages(numPages);
  const onDocumentLoadError = () => setIsError(true);

  const showDocument = (showError: boolean) => {
    if (showError) {
      return (
        <div className={styles.emptyState}>
          <Typography className={styles.failedStateText} component='p' variant='h1'>
            Unable to visualize the file!
          </Typography>
          <Typography className={styles.failedStateTextSub} component='p'>
            Something went wrong, please try again.
          </Typography>
        </div>
      );
    }

    return type === AttachmentTypes.PDF ? (
      <div className={styles.documentContainer} ref={divRef}>
        <Document file={path} onLoadError={onDocumentLoadError} onLoadSuccess={onDocumentLoadSuccess}>
          {pageIndexes.map((pageNumber) => (
            <div key={`page_${pageNumber}`}>
              <Page pageNumber={pageNumber} width={width} />
              <Typography className={styles.pagination} component='p'>{`${pageNumber}/${numberOfPages}`}</Typography>
            </div>
          ))}
        </Document>
      </div>
    ) : (
      <div className={styles.documentContainer} ref={divRef}>
        <img alt='document attached' className={styles.imagesContainer} src={path} onError={onDocumentLoadError} />
      </div>
    );
  };

  return (
    <div className={styles.previewDocumentContainer}>
      <IconButton aria-label='close' className={styles.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {numberOfDocument !== 1 && (
        <IconButton className={styles.iconButtonLeft} onClick={handleDecrementPage}>
          <ArrowBackIosIcon />
        </IconButton>
      )}
      {numberOfDocument !== numberOfDocuments && (
        <IconButton className={styles.iconButtonRight} onClick={handleIncrementPage}>
          <ArrowForwardIosIcon />
        </IconButton>
      )}
      <Box alignItems='center' display='flex' flexDirection='column' justifyContent='flex-start'>
        <Box
          alignItems='center'
          className={styles.informationContainer}
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          width='100%'
        >
          <Box className={styles.dataDocument} display='flex' flexDirection='column'>
            <Typography className={styles.filename} component='h2' variant='h1'>
              {fileName}
            </Typography>
            <Typography className={styles.fileDate} component='p'>
              {`Uploaded: ${parsedCreatedAt}`}
            </Typography>
          </Box>
          <div className={styles.documentsPagination}>
            <Typography
              className={styles.pagination}
              component='p'
            >{`${numberOfDocument}/${numberOfDocuments}`}</Typography>
          </div>
        </Box>
        {showDocument(isError)}
      </Box>
    </div>
  );
};

export default PreviewAttachments;
