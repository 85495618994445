import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import { Filler } from 'components/Filler/Filler';
import styles from 'modules/Project/components/AddNewHeader/AddNewHeader.module.scss';

interface AddNewHeaderProps {
  displayAction?: boolean;
  subtitle: string;
  title: string;
  onClick: () => void;
}

export const AddNewHeader: React.FC<AddNewHeaderProps> = ({ displayAction = true, subtitle, title, onClick }) => (
  <div className={styles.addNewHeader}>
    <div className={clsx(styles.addNewHeaderLabel, !displayAction && styles.centeredTitle)}>{title}</div>
    {displayAction && (
      <React.Fragment>
        <Filler />
        <div className={styles.addNewHeader__createButtonContainer}>
          <Button variant='contained' onClick={onClick}>
            {subtitle}
          </Button>
        </div>
      </React.Fragment>
    )}
  </div>
);
