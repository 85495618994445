import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { projectService } from 'services/projectService';
import { setEmployeeAvailabilityRequestStatus, setEmployeeAvailabilitySelectOptions } from 'store/projects/index';
import { AppThunk } from 'store/store';
import { EmployeeAvailabilitySelectOption } from 'types/Project/Project';

/**
 * Gets all Employees using a Project ID to check each employee availability
 * based on the Project information such as the dates.
 *
 * @param projectId Project to check Employees availability.
 * @param employeeName Optional Employee name to search for in the list.
 * @param limit Limit the result quantity.
 */
export const getEmployeeAvailabilityByProjectId =
  (projectId: string | number, employeeName: string, limit: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setEmployeeAvailabilityRequestStatus(RequestStatus.LOADING));

      const serviceOptions: ServiceOptions = {
        order: [['firstName', 'ASC']],
        where: {
          employeeName,
        },
        limit,
      };

      const { data } = await projectService.getEmployeesProjectAvailabilityByProjectId(projectId, serviceOptions);

      const employeeAvailabilitySelectOptions: EmployeeAvailabilitySelectOption[] = data.map(
        ({ availableFrom, employeeId, fullName, projectsToCheck, workAvailability }) => ({
          value: String(employeeId),
          label: fullName,
          availableFrom,
          projectsToCheck,
          workAvailability,
        }),
      );

      dispatch(setEmployeeAvailabilitySelectOptions(employeeAvailabilitySelectOptions));
      dispatch(setEmployeeAvailabilityRequestStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setEmployeeAvailabilityRequestStatus(RequestStatus.FAILED));
    }
  };
