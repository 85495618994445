import { RequestStatus } from 'constants/requestStatus';
import { skillService } from 'services/skillService';
import { setSkillsData, setSkillsRequestStatus } from 'store/skills';
import { AppThunk } from 'store/store';
import { fieldSorter } from 'utils/fieldSorter';
import { mapSkillModelToSkills } from 'utils/parsers/skills';

export const getAllSkills = (): AppThunk => async (dispatch) => {
  dispatch(setSkillsRequestStatus(RequestStatus.LOADING));
  try {
    const { data } = await skillService.getAll();
    const sortedSkills = fieldSorter(data, 'name');
    const skills = mapSkillModelToSkills(sortedSkills);

    dispatch(setSkillsData(skills));
    dispatch(setSkillsRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setSkillsRequestStatus(RequestStatus.FAILED));
  }
};
