import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';

import { getIcon } from 'assets/Icons';
import { KMenuProps } from 'components/Menus/types';
import { commonTableMenuProps, paperStylesOf } from 'components/Menus/utils';
import { Order } from 'types/Filter/Filter';

const SortAZIcon = getIcon({ name: 'SortAZ', variant: 'default' });
const SortZAIcon = getIcon({ name: 'SortZA', variant: 'default' });

interface ProjectNameSortMenuProps {
  onSelected: (order: Order) => void;
}

export const SortByNameMenu: React.FC<ProjectNameSortMenuProps & KMenuProps> = ({
  id,
  anchorEl,
  onClose,
  onSelected,
}) => (
  <Menu
    anchorEl={anchorEl}
    classes={{ paper: paperStylesOf(anchorEl?.clientWidth) }}
    id={id}
    open={anchorEl !== undefined}
    onClose={onClose}
    {...commonTableMenuProps}
  >
    <MenuItem
      onClick={() => {
        onSelected('ASC');
        onClose();
      }}
    >
      <SortAZIcon />
      <span>Sort A to Z</span>
    </MenuItem>
    <MenuItem
      onClick={() => {
        onSelected('DESC');
        onClose();
      }}
    >
      <SortZAIcon />
      <span>Sort Z to A</span>
    </MenuItem>
  </Menu>
);
