import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Option } from 'types/Form/FormCommons';

import { BusinessUnitState } from './index';

const setBusinessUnitOptions = (state: BusinessUnitState, { payload }: PayloadAction<Option<number>[]>): void => {
  state.businessUnitOptions = payload;
};

const setBusinessUnitsByRoleOptions = (
  state: BusinessUnitState,
  { payload }: PayloadAction<Option<number>[]>,
): void => {
  state.businessUnitByRoleOptions = payload;
};

const setBusinessUnitStatus = (state: BusinessUnitState, { payload }: PayloadAction<RequestStatus>): void => {
  state.businessUnitStatus = payload;
};

const reducers = {
  setBusinessUnitOptions,
  setBusinessUnitStatus,
  setBusinessUnitsByRoleOptions,
};

export default reducers;
