import { noop } from 'lodash';

import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { teamPositionService } from 'services/teamPosition';
import { AppThunk } from 'store/store';
import {
  setAllTeamPositions,
  setProjectSubmitStatus,
  setTeamPositionCreationStatus,
  setTeamPositionDeleteStatus,
  setTeamPositionsData,
  setTeamPositionsHaveEmployee,
  setTeamPositionsStatus,
  setTeamPositionUpdateStatus,
  setTotalMembers,
} from 'store/teamPosition';
import { DispatchStatusCallbacks } from 'types/Dispatch/Dispatch';
import { ProjectTeamMember, ProjectTeamMemberWithId } from 'types/PIFProject';
import {
  mapProjectTeamMemberToNewTeamPositionModel,
  mapTeamPositionModeltoTeamPosition,
} from 'utils/parsers/teamPosition';

export const getAllTeamPositionsByPIF =
  (projectId: number): AppThunk =>
  async (dispatch) => {
    try {
      const ops: ServiceOptions = {
        where: {
          projectId,
        },
      };
      const { data } = await teamPositionService.getAll(ops);
      const teamPositions = mapTeamPositionModeltoTeamPosition(data);
      dispatch(setTeamPositionsHaveEmployee(teamPositions.every(({ employeeId }) => employeeId)));
      dispatch(setAllTeamPositions(teamPositions));
      dispatch(setTeamPositionsStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setTeamPositionsStatus(RequestStatus.FAILED));
    }
  };

export const getTeamPositionsByPIF =
  (projectId: number, options?: ServiceOptions): AppThunk =>
  async (dispatch) => {
    dispatch(setTeamPositionsStatus(RequestStatus.LOADING));
    try {
      const ops: ServiceOptions = {
        where: {
          projectId,
        },
        include: ['position'],
        ...options,
      };
      const { data, count } = await teamPositionService.getAll(ops);
      const teamPositions = mapTeamPositionModeltoTeamPosition(data);
      dispatch(setTeamPositionsData(teamPositions));
      dispatch(setTotalMembers(count));
      dispatch(setTeamPositionsStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setTeamPositionsStatus(RequestStatus.FAILED));
    }
  };

export const createTeamPosition =
  (
    teamPosition: ProjectTeamMember,
    projectId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setTeamPositionCreationStatus(RequestStatus.LOADING));
    try {
      const teamPositionModel = mapProjectTeamMemberToNewTeamPositionModel(teamPosition, projectId);
      const {
        data: { id },
      } = await teamPositionService.create(teamPositionModel);

      dispatch(setTeamPositionCreationStatus(RequestStatus.SUCCESS));
      onSuccess(id);
    } catch (error) {
      dispatch(setTeamPositionCreationStatus(RequestStatus.FAILED));
      onError(error);
    }
  };

export const updateTeamPosition =
  (
    teamPosition: ProjectTeamMember,
    teamPositionId: number,
    projectId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setTeamPositionUpdateStatus(RequestStatus.LOADING));
    try {
      const teamPositionModel = mapProjectTeamMemberToNewTeamPositionModel(teamPosition, projectId);
      await teamPositionService.update(teamPositionId, teamPositionModel);
      dispatch(setTeamPositionUpdateStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setTeamPositionUpdateStatus(RequestStatus.FAILED));
      onError();
    }
  };

export const deleteTeamPosition =
  (teamPositionId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setTeamPositionDeleteStatus(RequestStatus.LOADING));
    try {
      await teamPositionService.delete(teamPositionId);

      dispatch(setTeamPositionDeleteStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setTeamPositionDeleteStatus(RequestStatus.FAILED));
    }
  };

export const createMultipleTeamPosition =
  (teamPositions: ProjectTeamMember[], projectId: number, { onSuccess = noop, onError = noop }): AppThunk =>
  async (dispatch) => {
    const createTeamPositionsRequests = teamPositions.map((teamPosition) => {
      const teamPositionModel = mapProjectTeamMemberToNewTeamPositionModel(teamPosition, projectId);
      return teamPositionService.create(teamPositionModel);
    });

    dispatch(setTeamPositionCreationStatus(RequestStatus.LOADING));

    await Promise.allSettled(createTeamPositionsRequests).then((results) => {
      const defaultValue: boolean[] = [];
      // If any of the promises fails, show error message
      const failedPromises = results.reduce((curr, res) => {
        if (res.status === 'rejected') {
          return [...curr, true];
        }
        return curr;
      }, defaultValue);

      const newTeamPositionIds = results.reduce<number[]>((curr, res) => {
        if (res.status === 'fulfilled' && res.value.data.id) {
          return [...curr, res.value.data.id];
        }
        return curr;
      }, []);

      if (failedPromises.length !== createTeamPositionsRequests.length) {
        dispatch(setTeamPositionCreationStatus(RequestStatus.SUCCESS));
        onSuccess(newTeamPositionIds);
      }

      if (failedPromises.length) {
        dispatch(setTeamPositionCreationStatus(RequestStatus.FAILED));
        onError();
      }
    });
  };

export const updateMultipleTeamPosition =
  (
    teamPositions: ProjectTeamMemberWithId[],
    projectId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
  ): AppThunk =>
  async (dispatch) => {
    const updateTeamPositionsRequests = teamPositions.map((teamPosition) => {
      const teamPositionModel = mapProjectTeamMemberToNewTeamPositionModel(teamPosition, projectId);
      return teamPositionService.update(teamPosition.id, teamPositionModel);
    });

    dispatch(setTeamPositionUpdateStatus(RequestStatus.LOADING));

    await Promise.allSettled(updateTeamPositionsRequests).then((results) => {
      const defaultValue: boolean[] = [];
      // If any of the promises fails, show error message
      const failedPromises = results.reduce((curr, res) => {
        if (res.status === 'rejected') {
          return [...curr, true];
        }
        return curr;
      }, defaultValue);

      if (failedPromises.length !== updateTeamPositionsRequests.length) {
        dispatch(setTeamPositionUpdateStatus(RequestStatus.SUCCESS));
        onSuccess();
      }

      if (failedPromises.length) {
        dispatch(setTeamPositionUpdateStatus(RequestStatus.FAILED));
        onError();
      }
    });
  };

export const deleteMultipleTeamPosition =
  (
    teamPositions: ProjectTeamMemberWithId[],
    projectId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
  ): AppThunk =>
  async (dispatch) => {
    const deleteTeamPositionsRequests = teamPositions.map((teamPosition) =>
      teamPositionService.delete(teamPosition.id),
    );

    dispatch(setTeamPositionDeleteStatus(RequestStatus.LOADING));

    await Promise.allSettled(deleteTeamPositionsRequests).then((results) => {
      const defaultValue: boolean[] = [];
      // If any of the promises fails, show error message
      const failedPromises = results.reduce((curr, res) => {
        if (res.status === 'rejected') {
          return [...curr, true];
        }
        return curr;
      }, defaultValue);

      if (failedPromises.length !== deleteTeamPositionsRequests.length) {
        dispatch(setTeamPositionDeleteStatus(RequestStatus.SUCCESS));
        onSuccess();
      }

      if (failedPromises.length) {
        dispatch(setTeamPositionDeleteStatus(RequestStatus.FAILED));
        onError();
      }
    });
  };

export const submitProject =
  (projectId: number, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectSubmitStatus(RequestStatus.LOADING));
    try {
      await teamPositionService.submit(projectId);
      dispatch(setProjectSubmitStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectSubmitStatus(RequestStatus.FAILED));
      onError(error);
    }
  };

export const updateJobPosition =
  (teamPositionId: number, employeeId: number, startDate?: any, endDate?: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setTeamPositionUpdateStatus(RequestStatus.LOADING));
      await teamPositionService.assignEmployee(teamPositionId, employeeId, startDate, endDate);
      dispatch(setTeamPositionUpdateStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setTeamPositionUpdateStatus(RequestStatus.FAILED));
    }
  };

export const updateTeamPositionBillRate =
  (
    teamPositionBillRate: number | null,
    teamPositionId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setTeamPositionUpdateStatus(RequestStatus.LOADING));
    try {
      await teamPositionService.update(teamPositionId, { billRate: teamPositionBillRate ? +teamPositionBillRate : 0 });
      dispatch(setTeamPositionUpdateStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setTeamPositionUpdateStatus(RequestStatus.FAILED));
      onError();
    }
  };
