import React, { useEffect, useState } from 'react';
import { Checkbox, Menu, MenuItem } from '@material-ui/core';

import { KMenuProps } from 'components/Menus/types';
import { commonTableMenuProps, paperStylesOf } from 'components/Menus/utils';
import { Option } from 'types/Form/FormCommons';
import { IndexSignature } from 'types/utils';

import styles from './CheckboxMenu.module.scss';

interface CheckboxMenuProps {
  closeAfterClick?: boolean;
  options: Option<string | number>[];
  values: IndexSignature<boolean>;
  onCheckedValuesChange: (checked: IndexSignature<boolean>) => void;
}

export const createCheckboxMenuValues = (
  options: Option<string | number>[],
  initialState: boolean,
): IndexSignature<boolean> =>
  options.reduce<IndexSignature<boolean>>(
    (acc, { value }) => ({
      ...acc,
      [typeof value === 'number' ? value.toString() : value]: initialState,
    }),
    {},
  );

export const CheckboxMenu: React.FC<CheckboxMenuProps & KMenuProps> = ({
  id,
  anchorEl,
  onClose,
  options,
  onCheckedValuesChange,
  values,
  closeAfterClick,
}) => {
  const [checkedValues, updateCheckedValues] = useState<IndexSignature<boolean>>(values);

  const onToggleValue = (label: string | number): void => {
    updateCheckedValues((prevState) => {
      const nextState = { ...prevState, [label]: !prevState[label] };
      onCheckedValuesChange(nextState);
      return nextState;
    });
    if (closeAfterClick) {
      onClose();
    }
  };

  useEffect(() => {
    updateCheckedValues(values);
  }, [options, values]);

  return (
    <Menu
      anchorEl={anchorEl}
      classes={{ paper: paperStylesOf(anchorEl?.clientWidth) }}
      id={id}
      open={anchorEl !== undefined}
      onClose={onClose}
      {...commonTableMenuProps}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={`${id}-${label}-Item`} onClick={() => onToggleValue(value)}>
          <Checkbox
            checked={checkedValues[value]}
            className={styles.checkboxMenuItem}
            inputProps={{ 'aria-label': label }}
          />
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};
