import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Option } from 'types/Form/FormCommons';

import { ContractTypeState } from './index';

const setContractTypeOptions = (state: ContractTypeState, { payload }: PayloadAction<Option<number>[]>): void => {
  state.contractTypeOptions = payload;
};

const setContractTypeStatus = (state: ContractTypeState, { payload }: PayloadAction<RequestStatus>): void => {
  state.contractTypeStatus = payload;
};

const reducers = {
  setContractTypeOptions,
  setContractTypeStatus,
};

export default reducers;
