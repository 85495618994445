import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';

import { store } from 'store/store';

import * as serviceWorker from './serviceWorker';

import './index.css';
import './styles/customFonts.css';
import './styles/global.bundle.scss';

// Strict mode throws a warning with material that will be solved in material v5
// https://github.com/mui-org/material-ui/blob/next/CHANGELOG.md#material-uilabs500-alpha42
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
