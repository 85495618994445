import { BaseService, ResponseData } from 'services/baseService';
import { File } from 'types/File';
import { FileModel } from 'types/models/FileModel';

class FileService extends BaseService<FileModel> {
  controllerName = 'file';

  async getFileById(id: number): Promise<ResponseData<File>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<File>>(`/${this.controllerName}/${id}`);
    return data;
  }
}

export const fileService = new FileService();
