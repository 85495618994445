import React, { Fragment, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link, useRouteMatch } from 'react-router-dom';
import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { FormSectionTitle } from 'components/FormSectionTitle/FormSectionTitle';
import { RequestStatus } from 'constants/requestStatus';
import { ProjectDetails } from 'types/Project/Project';
import { enFormatDate } from 'utils/formatters/date';
import { titleCase, upperCase } from 'utils/formatters/text';

import { ProjectDetailsSkeleton } from '../PIFSkeleton/ProjectDetailsSkeleton/ProjectDetailsSkeleton';
import PreviewAttachments from '../PreviewAttachments/PreviewAttachments';

import styles from './ProjectDetailsRender.module.scss';

interface ProjectDetailsRenderProps {
  canEdit: boolean;
  className: string;
  projectDetailsData: ProjectDetails;
  projectDetailsStatus: RequestStatus;
}

export const ProjectDetailsRender = ({
  canEdit,
  className,
  projectDetailsData,
  projectDetailsStatus,
}: ProjectDetailsRenderProps) => {
  const { url } = useRouteMatch();
  const {
    budgetForecasting,
    businessUnitName,
    contractTypeName,
    comments,
    currencySymbol,
    customerName,
    endDate,
    projectName,
    projectTypeName,
    projectAttachments,
    startDate,
    approverName,
    totalForecastedHours,
  } = projectDetailsData;

  const [isDialogAttachmentsOpen, setIsDialogAttachmentsOpen] = useState(false);
  const handleOpenDialogAttachments = () => setIsDialogAttachmentsOpen(true);
  const handleCloseAttachmentsDialog = () => setIsDialogAttachmentsOpen(false);

  const renderProjectGeneralInformation = () => {
    const projectGeneralInformation = [
      { subtitle: 'Customer Name (Person or Company)', value: customerName },
      { subtitle: 'Project Name', value: projectName },
      {
        subtitle: 'Project Type',
        value: projectTypeName === 'itk' ? upperCase(projectTypeName) : titleCase(projectTypeName),
      },
      { subtitle: 'Business Unit', value: businessUnitName },
      { subtitle: 'Contract Type', value: !contractTypeName ? 'Missing' : contractTypeName },
    ];

    return projectGeneralInformation.map(({ subtitle, value }, idx) => (
      <Grid className={styles.formField} item key={`general-information-${idx.toString()}`} xs={12}>
        <Typography className={styles.subtitle}>{subtitle}</Typography>
        <Typography className={styles.currentValue}>{value}</Typography>
      </Grid>
    ));
  };

  const renderProjectSpecs = () => {
    const projectSpecsDates = [
      { subtitle: 'Start Date', value: enFormatDate(startDate) },
      { subtitle: 'End Date', value: enFormatDate(endDate) },
    ];

    const projectSpecs = [
      {
        subtitle: 'Project Total Forecasted Hours',
        value: totalForecastedHours || 'No Project Total Forecasted Hours',
      },
    ];

    return (
      <Fragment>
        <Grid container justifyContent='flex-start'>
          {projectSpecsDates.map(({ subtitle, value }, idx) => (
            <Grid className={styles.formField} item key={`specs-date-${idx.toString()}`} xs={3}>
              <Typography className={styles.subtitle}>{subtitle}</Typography>
              <Typography className={styles.currentValue}>{value}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid className={styles.formField} item xs={12}>
          <Typography className={styles.subtitle}>Budget Forecasting</Typography>
          <NumberFormat
            className={styles.currentValue}
            decimalScale={2}
            displayType='text'
            fixedDecimalScale
            prefix={currencySymbol}
            thousandSeparator
            value={budgetForecasting}
          />
        </Grid>
        {projectSpecs.map(({ subtitle, value }, idx) => (
          <Grid className={styles.formField} item key={`specs-${idx.toString()}`} xs={12}>
            <Typography className={styles.subtitle}>{subtitle}</Typography>
            <Typography className={styles.currentValue}>{value}</Typography>
          </Grid>
        ))}
        {!!projectAttachments?.length && (
          <Grid className={styles.formField} item xs={12}>
            <Typography className={styles.subtitle}>Files Attached</Typography>
            <IconButton className={styles.attachmentsIcon} color='primary' onClick={handleOpenDialogAttachments}>
              <AttachFile />
            </IconButton>
            <Dialog
              aria-labelledby='responsive-dialog-title'
              maxWidth={false}
              open={isDialogAttachmentsOpen}
              scroll='body'
              onClose={handleCloseAttachmentsDialog}
            >
              <PreviewAttachments attachments={projectAttachments} onClose={handleCloseAttachmentsDialog} />
            </Dialog>
          </Grid>
        )}
      </Fragment>
    );
  };

  const renderProjectExtraInformation = () => {
    const projectExtraInformation = [
      { subtitle: 'Timesheet Approver', value: approverName || 'No timesheet approver' },
      { subtitle: 'Comments', value: comments || 'No comments' },
    ];

    return projectExtraInformation.map(({ subtitle, value }, idx) => (
      <Grid className={styles.formField} item key={`general-information-${idx.toString()}`} xs={12}>
        <Typography className={styles.subtitle}>{subtitle}</Typography>
        <Typography className={styles.currentValue}>{value}</Typography>
      </Grid>
    ));
  };

  if (projectDetailsStatus === RequestStatus.FAILED) {
    return (
      <ErrorMessage
        subtitle="We're having trouble loading the info from the server. Check your connection and try again."
        title='Something went wrong'
      />
    );
  }

  if (projectDetailsStatus === RequestStatus.SUCCESS) {
    return (
      <Grid className={clsx(styles.projectDetailsContainer, className)} container justifyContent='center'>
        <Grid
          className={clsx({ [styles.mainFormTitle]: !canEdit })}
          container
          item
          justifyContent='space-between'
          xs={12}
        >
          <FormSectionTitle text='General Information' />
          {canEdit && (
            <IconButton
              aria-label='edit'
              component={Link}
              to={{ pathname: `${url}/edit`, state: { fromViewer: true } }}
            >
              <CreateIcon />
            </IconButton>
          )}
        </Grid>
        {renderProjectGeneralInformation()}
        <Grid className={styles.formTitle} item xs={12}>
          <FormSectionTitle text='Specs' />
        </Grid>
        {renderProjectSpecs()}
        <Grid className={styles.formTitle} item xs={12}>
          <FormSectionTitle optional text='Additional Details' />
        </Grid>
        {renderProjectExtraInformation()}
      </Grid>
    );
  }

  return (
    <Grid className={styles.projectDetailsContainer} container justifyContent='center'>
      <Grid className={styles.skeletonContainer} item>
        <ProjectDetailsSkeleton />
      </Grid>
    </Grid>
  );
};
