import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty, noop } from 'lodash';
import { useSnackbar } from 'notistack';

import EmptyProjects from 'assets/images/EmptyProjects.svg';
import { KDialogSmall } from 'components/Dialogs/KDialogSmall/KDialogSmall';
import { KDialogActionKind } from 'components/Dialogs/types';
import { KSearchBar } from 'components/KSearchBar/KSearchBar';
import { Rol } from 'constants/Credentials/Rol';
import { DEBOUNCE_TIMEOUT } from 'constants/ProjectDashboard/dashboardConstants';
import { RequestStatus } from 'constants/requestStatus';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { AddNewHeader } from 'modules/Project/components/AddNewHeader/AddNewHeader';
import { DashboardHelperText } from 'modules/Project/components/DashboardHelperText/DashboardHelperText';
import { DashboardHelpPopover } from 'modules/Project/components/DashboardHelpPopover/DashboardHelpPopover';
import { ProjectDashboardSkeleton } from 'modules/Project/components/DashboardSkeleton/ProjectDashboardSkeleton';
import { TableSkeleton } from 'modules/Project/components/DashboardSkeleton/TableSkeleton/TableSkeleton';
import EmptyState from 'modules/Project/components/EmptyState/EmptyState';
import { ProjectsTable } from 'modules/Project/components/ProjectsTable/ProjectsTable';
import { createNewProjectRoute, createProjectDetailsRouter } from 'modules/Project/ProjectRouter';
import { ServiceOptions } from 'services/baseService';
import { setBusinessUnitsByRoleOptions } from 'store/businessUnit';
import { getBusinessUnits } from 'store/businessUnit/thunk';
import { setProjectsData } from 'store/projectDashboard';
import {
  selectProjectDeleteStatus,
  selectProjects,
  selectProjectStatus,
  selectTotalGlobalProjects,
  selectTotalGlobalProjectsStatus,
  selectTotalProjects,
} from 'store/projectDashboard/selectors';
import { deleteProject, getProjects, getTotalGlobalProjects } from 'store/projectDashboard/thunk';
import { setLimit, setOffset, setOrder, setProjectsCurrentPage, setSearchValue, setWhere } from 'store/projectFilters';
import {
  selectProjectFilters,
  selectProjectsCurrentPage,
  selectProjectsLimit,
  selectProjectWhereQueries,
  selectSearchValue,
} from 'store/projectFilters/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import { Order, WhereOperator } from 'types/Filter/Filter';
import { ProjectModel } from 'types/Project/ProjectModel';
import {
  combineWhere,
  createWhereOperator,
  offsetOf,
  orderTuplesOf,
  sanitizeWhereQueries,
  searchQueryOf,
} from 'utils/filter/filterUtils';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useFirstRender } from 'utils/hooks/useFirstRender';
import { hasRole } from 'utils/roles';

import styles from './ProjectDashboard.module.scss';

interface ConfirmDialogData {
  id: string | undefined;
  isOpen: boolean;
}
const shouldDisplayHelpText = (roles: Rol[]): boolean => {
  const rolesToDisplay = [Rol.CO_CREATOR, Rol.APPRAISER];
  return rolesToDisplay.some((rol) => hasRole(roles, rol));
};

export const ProjectDashboard: React.FC = () => {
  const COCREATOR_HELPER_TEXT =
    'Review the Pending Projects and add the Ksquarians to the Job Position and then Submit.';
  const APPRAISER_HELPER_TEXT = 'Appraise the project(s) for Approval.';
  const { push } = useHistory();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const projectFilters = useAppSelector(selectProjectFilters);
  const whereQueries = useAppSelector(selectProjectWhereQueries);
  const projectRequestStatus = useAppSelector(selectProjectStatus);
  const projectDeleteStatus = useAppSelector(selectProjectDeleteStatus);
  const searchValue = useAppSelector(selectSearchValue);
  const totalProjects = useAppSelector(selectTotalProjects);
  const totalGlobalProjects = useAppSelector(selectTotalGlobalProjects);
  const totalGlobalProjectsStatus = useAppSelector(selectTotalGlobalProjectsStatus);
  const currentPage = useAppSelector(selectProjectsCurrentPage);
  const itemsPerPage = useAppSelector(selectProjectsLimit) || 10;
  const roles = useAppSelector(selectSessionRoles);
  const [hasFilters, updateHasFilters] = useState<boolean>(false);
  const [showHelperText, setShowHelperText] = useState<boolean>(shouldDisplayHelpText(roles));
  const [showSearch, updateShowSearch] = useState<boolean>(false);
  const [isSearching, updateIsSearching] = useState<boolean>(false);
  const [isEmptyQuery, setIsEmptyQuery] = useState<boolean>(false);
  const [confirmDeleteDialog, updateConfirmDeleteDialog] = useState<ConfirmDialogData>({
    id: undefined,
    isOpen: false,
  });
  const isFirstRender = useFirstRender();
  const { enqueueSnackbar } = useSnackbar();
  const debouncedQueries = useDebounce(whereQueries, DEBOUNCE_TIMEOUT);

  useEffect(() => {
    const serviceOptions: ServiceOptions = {};
    if (roles.includes(Rol.CO_CREATOR)) {
      serviceOptions.params = { assigned: true };
    }
    dispatch(
      getBusinessUnits({
        serviceOptions,
        dataReducer: setBusinessUnitsByRoleOptions,
      }),
    );
    dispatch(getTotalGlobalProjects());
  }, [dispatch, enqueueSnackbar, roles]);

  useEffect(() => {
    if (isFirstRender) return;
    setShowHelperText(shouldDisplayHelpText(roles));
  }, [roles, isFirstRender]);

  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    if (!isEmptyQuery) {
      dispatch(
        getProjects(projectFilters, {
          onError: () => enqueueSnackbar('Error trying to load the projects table', SnackbarVariant.ERROR),
          onSuccess: noop,
        }),
      );
    } else {
      dispatch(setProjectsData([]));
    }
    updateHasFilters(Boolean(projectFilters.order || projectFilters.where));
  }, [dispatch, enqueueSnackbar, isEmptyQuery, isFirstRender, projectFilters, whereQueries]);

  useEffect(() => {
    if (projectDeleteStatus === RequestStatus.SUCCESS) {
      dispatch(
        getProjects(projectFilters, {
          onError: () => enqueueSnackbar('Error trying to load the projects table', SnackbarVariant.ERROR),
          onSuccess: noop,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, enqueueSnackbar, projectDeleteStatus]);

  useEffect(() => {
    updateShowSearch(
      projectRequestStatus !== RequestStatus.IDLE && (projects.length > 0 || (projects.length === 0 && hasFilters)),
    );
  }, [hasFilters, projectRequestStatus, projects.length]);

  const squashWhereQueries = useCallback(
    (searchValue: string): void => {
      const queries = Object.values(debouncedQueries);
      const isEmptyQuery = queries.some((query) => query.length === 0);
      const next = {
        where: combineWhere([
          createWhereOperator<ProjectModel>([['name', WhereOperator.I_LIKE, searchQueryOf(searchValue)]]),
          sanitizeWhereQueries(debouncedQueries),
        ]),
      };
      updateIsSearching(!isEmpty(searchValue));
      dispatch(setWhere(next));
      setIsEmptyQuery(isEmptyQuery);
    },
    [dispatch, debouncedQueries],
  );

  useEffect(() => {
    squashWhereQueries(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squashWhereQueries, whereQueries]);

  const handleButtonSearch = (): void => {
    dispatch(setOffset(0));
    dispatch(setProjectsCurrentPage(1));
    squashWhereQueries(searchValue);
  };

  const handleButtonClear = (): void => {
    squashWhereQueries('');
  };

  const handleSearchInputChange = (value: string): void => {
    dispatch(setSearchValue(value));
  };

  const handleDeleteProject = useCallback(
    (projectId: string): void => {
      dispatch(
        deleteProject(projectId, {
          onSuccess: () => enqueueSnackbar('Project successfully deleted', SnackbarVariant.SUCCESS),
          onError: () => enqueueSnackbar('Error trying deleting the project', SnackbarVariant.ERROR),
        }),
      );
    },
    [dispatch, enqueueSnackbar],
  );

  const handleItemsPerPageChange = (itemsPerPage: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, 1)));
    dispatch(setLimit(itemsPerPage));
    dispatch(setProjectsCurrentPage(1));
  };

  const handlePaginationChange = (page: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, page)));
    dispatch(setProjectsCurrentPage(page));
  };

  const handleClickRowView = (projectId: string): void => {
    if (projectId) {
      push(createProjectDetailsRouter(projectId));
    }
  };

  const handleClickRowEdit = (projectId: string): void => {
    if (projectId) {
      push(createProjectDetailsRouter(projectId));
    }
  };

  const handleClickRowDelete = (projectId: string | undefined): void => {
    if (projectId) {
      updateConfirmDeleteDialog({ id: projectId, isOpen: true });
    }
  };

  const handleCloseDeleteDialog = (): void => {
    updateConfirmDeleteDialog({ id: undefined, isOpen: false });
  };

  const confirmDeleteProject = (): void => {
    if (confirmDeleteDialog.id) {
      handleDeleteProject(confirmDeleteDialog.id);
    }
    handleCloseDeleteDialog();
  };

  const handleColumnSort = (clickedColumn: string | string[] | undefined, order: Order): void => {
    if (clickedColumn && typeof clickedColumn === 'string') {
      const nextOrders = { [clickedColumn]: order };
      dispatch(setOrder(orderTuplesOf(nextOrders)));
    }
  };

  const createEmptyStateInformation = (): [string, string] => {
    if (totalGlobalProjects === 0) {
      if (hasRole(roles, Rol.PROJECT_INITIATOR)) {
        return ["There're no projects to see yet!", 'Start creating a new project to get your work on'];
      }
      if (hasRole(roles, Rol.CO_CREATOR)) {
        return ["There're no projects to see yet!", 'Wait for a project created by PMO or Salesforce to review it.'];
      }
      if (hasRole(roles, Rol.APPRAISER)) {
        return [
          "There're no projects to see yet!",
          'Wait for the project review by Head of Engineering or Director of Salesforce Mexico for your appraise',
        ];
      }
    }
    return ["Hmm, we didn't find what you were looking for", 'Try searching with another project name'];
  };

  const [description, message] = createEmptyStateInformation();

  const renderDashboardContent = (): ReactElement => {
    if (projects.length > 0 || !isSearching) {
      return (
        <ProjectsTable
          currentPage={currentPage}
          data={projects}
          itemsPerPage={itemsPerPage}
          totalItems={!isEmptyQuery ? totalProjects : 0}
          type={Rol.PROJECT_INITIATOR}
          onColumnSort={handleColumnSort}
          onDelete={handleClickRowDelete}
          onEdit={handleClickRowEdit}
          onItemsPerPageChange={handleItemsPerPageChange}
          onPaginationChange={handlePaginationChange}
          onView={handleClickRowView}
        />
      );
    }

    if (projectRequestStatus !== RequestStatus.LOADING || isSearching) {
      return <EmptyState description={description} image={EmptyProjects} message={message} />;
    }
    return <TableSkeleton />;
  };

  return (isFirstRender &&
    (projectRequestStatus === RequestStatus.LOADING || projectRequestStatus === RequestStatus.IDLE)) ||
    totalGlobalProjectsStatus === RequestStatus.LOADING ? (
    <ProjectDashboardSkeleton />
  ) : (
    <div className={styles.projectDashboard}>
      {totalGlobalProjects !== 0 && showHelperText && (
        <DashboardHelperText
          description={hasRole(roles, Rol.CO_CREATOR) ? COCREATOR_HELPER_TEXT : APPRAISER_HELPER_TEXT}
          onClick={() => setShowHelperText(false)}
        />
      )}

      <Container className={styles.projectDashboardHeading} maxWidth='sm'>
        <Grid className={styles.projectDashboardHeadingLayout} container>
          <Grid
            className={clsx(
              totalGlobalProjects === 0
                ? styles.projectDashboardHeadingLayout__addNewContainerEmpty
                : styles.projectDashboardHeadingLayout__addNewContainer,
            )}
            item
            md={12}
            xs={12}
          >
            <AddNewHeader
              displayAction={hasRole(roles, Rol.PROJECT_INITIATOR)}
              subtitle='New Project'
              title='Projects'
              onClick={() => push(createNewProjectRoute())}
            />
          </Grid>
          {totalGlobalProjects !== 0 && showSearch && (
            <KSearchBar
              placeholder='Try "Project Name"'
              value={searchValue}
              onChange={handleSearchInputChange}
              onClear={handleButtonClear}
              onClickButton={handleButtonSearch}
            />
          )}
        </Grid>
      </Container>
      <Container
        className={clsx(styles.projectDashboardTable, { [styles.projectDashboardNoPagination]: totalProjects < 11 })}
        maxWidth={false}
      >
        {totalGlobalProjects > 0 ? (
          renderDashboardContent()
        ) : (
          <EmptyState description={description} image={EmptyProjects} message={message} />
        )}
        <DashboardHelpPopover />
      </Container>
      <KDialogSmall
        actions={[
          {
            label: 'Cancel',
            kind: KDialogActionKind.CANCEL,
            onClick: () => handleCloseDeleteDialog(),
          },
          {
            label: 'Delete',
            kind: KDialogActionKind.CAUTION,
            onClick: () => confirmDeleteProject(),
          },
        ]}
        isOpen={confirmDeleteDialog.isOpen}
        message='By doing this, the project will be permanently removed.'
        title='Delete Project?'
        onClose={() => handleCloseDeleteDialog()}
      />
    </div>
  );
};
