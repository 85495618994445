import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Role } from 'types/Project/Role';

import reducers from './reducers';

export interface RolesState {
  roles: Role[];
  rolesRequestStatus: RequestStatus;
}

const initialState: RolesState = {
  roles: [],
  rolesRequestStatus: RequestStatus.LOADING,
};

export const rolesSlice = createSlice({
  name: 'role',
  initialState,
  reducers,
});

export const { setRolesData, setRolesRequestStatus } = rolesSlice.actions;

export default rolesSlice.reducer;
