import { PayloadAction } from '@reduxjs/toolkit';

import { ProjectState } from 'store/projects/index';
import { EmployeeAvailabilitySelectOption } from 'types/Project/Project';
import { RequestStatus } from 'types/RequestStatus';

const setEmployeeAvailabilityRequestStatus = (state: ProjectState, action: PayloadAction<RequestStatus>): void => {
  state.employeeAvailabilityRequestStatus = action.payload;
};

const setEmployeeAvailabilitySelectOptions = (
  state: ProjectState,
  action: PayloadAction<EmployeeAvailabilitySelectOption[]>,
): void => {
  state.employeeAvailabilitySelectOptions = action.payload;
};

const reducers = {
  setEmployeeAvailabilityRequestStatus,
  setEmployeeAvailabilitySelectOptions,
};

export default reducers;
