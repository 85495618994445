import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Project } from 'types/Project/Project';

import reducers from './reducers';

export interface ProjectsState {
  projectDeleteStatus: RequestStatus;
  projectRequestStatus: RequestStatus;
  projects: Project[];
  totalGlobalProjects: number;
  totalGlobalProjectsStatus: RequestStatus;
  totalProjects: number;
}

const initialState: ProjectsState = {
  projects: [],
  projectRequestStatus: RequestStatus.IDLE,
  projectDeleteStatus: RequestStatus.IDLE,
  totalGlobalProjects: 0,
  totalGlobalProjectsStatus: RequestStatus.LOADING,
  totalProjects: 0,
};

export const projectsSlice = createSlice({
  name: 'projectDashboard',
  initialState,
  reducers,
});

export const {
  setProjectsData,
  setProjectRequestStatus,
  setProjectDeleteStatus,
  setTotalGlobalProjects,
  setTotalGlobalProjectsStatus,
  setTotalProjects,
} = projectsSlice.actions;

export default projectsSlice.reducer;
