import { PayloadAction } from '@reduxjs/toolkit';

import { SortingOrderArray } from 'types/Filter/Filter';

import { TeamRosterFilterState } from './index';

const setOrder = (state: TeamRosterFilterState, { payload }: PayloadAction<SortingOrderArray[] | undefined>): void => {
  state.options.order = payload;
};

const setOffset = (state: TeamRosterFilterState, { payload }: PayloadAction<number | undefined>): void => {
  state.options.offset = payload;
};

const setLimit = (state: TeamRosterFilterState, { payload }: PayloadAction<number>): void => {
  state.options.limit = payload;
};

const setCurrentPage = (state: TeamRosterFilterState, { payload }: PayloadAction<number>): void => {
  state.currentPage = payload;
};

export default {
  setOrder,
  setLimit,
  setOffset,
  setCurrentPage,
};
