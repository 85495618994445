import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Technology } from 'types/Project/Technology';

import reducers from './reducers';

export interface TechnologiesState {
  technologies: Technology[];
  technologiesRequestStatus: RequestStatus;
}

const initialState: TechnologiesState = {
  technologies: [],
  technologiesRequestStatus: RequestStatus.LOADING,
};

export const technologiesSlice = createSlice({
  name: 'technology',
  initialState,
  reducers,
});

export const { setTechnologiesData, setTechnologiesRequestStatus } = technologiesSlice.actions;

export default technologiesSlice.reducer;
