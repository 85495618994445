import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Header } from 'components/Header';
import NotificationLayout from 'modules/NotificationCenter/view/NotificationLayout';

export const NotificationCenterRouter = (): ReactElement => {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={path}>
          <Header />
          <Route component={NotificationLayout} exact path={path} />
        </Route>
      </Switch>
    </div>
  );
};
export const NotificationCenterCreateRoute = () => `/notificationCenter`;
