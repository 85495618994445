import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Grid, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { getIcon, KIcons } from 'assets/Icons';
import styles from 'components/NotificationRow/NotificationRow.module.scss';
import getPassedTimeText from 'utils/formatters/daysPassed';

interface NotificationRowProps {
  creationDate: string;
  description: string;
  icon: KIcons;
  isDropdown: boolean;
  isRead?: boolean;
  requiresAction: boolean;
  onClickButton: () => void;
  onClickIcon?: () => void;
}

const NotificationRow: FC<NotificationRowProps> = ({
  creationDate,
  description,
  icon,
  isDropdown,
  isRead,
  requiresAction,
  onClickButton,
  onClickIcon,
}) => {
  const NotificationIcon = getIcon({ name: icon, variant: 'default' });

  const [isHover, setIsHover] = useState(false);
  const [hasTooltip, setHasTooltip] = useState(false);
  const [element, setElement] = useState<HTMLElement | null>(null);

  const getWidth = (node: HTMLSpanElement | null) => {
    if (node !== null) {
      setElement((_) => node);
    }
  };

  const setTooltip = () => {
    setHasTooltip((_) => Number(element?.scrollWidth) > Math.floor(Number(element?.clientWidth)));
  };

  const handleMouseOver = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const handleOnClickButton = () => onClickButton();

  const handleOnClickIcon = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClickIcon) onClickIcon();
  };

  useEffect(setTooltip, [element?.clientWidth, element?.scrollWidth]);

  return (
    <Button
      className={isRead || isDropdown ? styles.button : styles.buttonGrey}
      fullWidth
      id='notificationItem'
      onClick={handleOnClickButton}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      <Box px='18px' py='10px' width='100%'>
        <Grid alignItems='center' className={styles.container} container direction='row' justifyContent='space-between'>
          <Grid item>
            <SvgIcon
              className={isDropdown ? styles.iconDropdown : clsx(styles.icon, isRead && styles.iconOpacity)}
              color='primary'
              component={NotificationIcon}
            />
          </Grid>
          <Grid className={styles.notificationInfo} item>
            <Box display='inline-flex' flexWrap='wrap' width={isDropdown ? '338px' : '100%'}>
              <Tooltip placement='top-start' title={hasTooltip ? description : ''}>
                <Typography
                  className={clsx(styles.title, isRead && styles.opacity80)}
                  color='textPrimary'
                  ref={getWidth}
                  variant='body2'
                >
                  {description}
                </Typography>
              </Tooltip>
              {!requiresAction && isHover && !isDropdown && !isRead ? (
                <Tooltip placement='top-end' title='Mark as read'>
                  <SvgIcon
                    className={styles.iconRead}
                    color='primary'
                    component={getIcon({ name: 'NotificationMarkRead', variant: 'default' })}
                    id='ReadIcon'
                    onClick={(event: React.MouseEvent) => handleOnClickIcon(event)}
                  />
                </Tooltip>
              ) : (
                <Typography className={styles.notificationDate}>{getPassedTimeText(creationDate)}</Typography>
              )}
            </Box>
          </Grid>
          {!requiresAction && isHover && isDropdown && (
            <Grid item>
              <Tooltip placement='top-end' title='Mark as read'>
                <SvgIcon
                  className={styles.iconReadDropdown}
                  color='primary'
                  component={getIcon({ name: 'NotificationMarkRead', variant: 'default' })}
                  id='ReadIcon'
                  onClick={(event: React.MouseEvent) => handleOnClickIcon(event)}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Box>
    </Button>
  );
};

export default NotificationRow;
