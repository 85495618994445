import { isEmpty } from 'lodash';

import {
  Order,
  SortingOrderArray,
  WhereEnum,
  WhereMatchTuple,
  WhereNestedObject,
  WhereObject,
} from 'types/Filter/Filter';
import { IndexSignature } from 'types/utils';

/**
 * Converts an object with order values to order and order tuple.
 * Returns undefined when all the tuples doesn't have value.
 * @param orders - Order objects to convert.
 */
export const orderTuplesOf = (orders: IndexSignature<Order>): SortingOrderArray[] | undefined => {
  const tuples = Object.entries(orders).reduce<SortingOrderArray[]>((acc, next) => {
    if (next.length > 0) {
      const [field, order] = next;
      if (field.split('.').length > 1) {
        const [...rest] = field.split('.');
        const nested: SortingOrderArray = [...rest, order];
        return [...acc, nested];
      }
      return [...acc, next];
    }
    return acc;
  }, []);

  return tuples.length > 0 ? tuples : undefined;
};

/**
 * Deletes empty values from a 'Where' object.
 * Returns undefined when all the tuples doesn't have value.
 * @param whereQueries
 */
export const sanitizeWhereQueries = (whereQueries: IndexSignature<WhereEnum>): WhereObject<WhereEnum> | undefined => {
  const values = Object.entries(whereQueries).reduce<WhereObject<WhereEnum>>((acc, [field, value]) => {
    if (value.length > 0) {
      return { ...acc, [field]: value };
    }
    return acc;
  }, {});

  return values && Object.keys(values).length > 0 ? values : undefined;
};

/**
 * Converts a operator match case tuple to a valid query search object.
 * Returns undefined when all the tuples doesn't have value.
 * @param tuples - Tuples to convert into a 'Where' query object.
 */
export const createWhereOperator = <T>(tuples: WhereMatchTuple<T>[]): WhereObject<WhereNestedObject> | undefined => {
  const values = tuples.reduce<WhereObject<WhereNestedObject>>((acc, [field, operator, value]) => {
    if (!isEmpty(value)) {
      return { ...acc, [field]: { [operator]: value } };
    }
    return acc;
  }, {});

  return values && Object.keys(values).length > 0 ? values : undefined;
};

export const combineWhere = (objects: WhereObject[]): WhereObject =>
  objects.reduce((acc, next) => ({ ...acc, ...next }), {});

/**
 * Calculates the offset for pagination.
 * @param itemsPerPage - Current rows per page.
 * @param currentPage - Current page.
 */
export const offsetOf = (itemsPerPage: number, currentPage: number): number =>
  itemsPerPage * currentPage - itemsPerPage;

/**
 * Create a match string for where operator value.
 * @param value
 */
export const searchQueryOf = (value: string): string => (value.length > 0 ? `%${value}%` : '');
