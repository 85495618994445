import { createSlice } from '@reduxjs/toolkit';

import { ServiceOptions } from 'services/baseService';

import reducers from './redurcers';

export interface TeamRosterFilterState {
  currentPage: number;
  options: ServiceOptions;
}

export const initialState: TeamRosterFilterState = {
  options: {
    limit: 10,
  },
  currentPage: 1,
};

export const teamRosterFilterOptions = createSlice({
  name: 'teamRosterFilters',
  initialState,
  reducers,
});

export const { setOrder, setLimit, setOffset, setCurrentPage } = teamRosterFilterOptions.actions;

export default teamRosterFilterOptions.reducer;
