import { NotificationCategory } from 'constants/notificationCategory';
import { employeeService } from 'services/employeeService';
import { notificationService } from 'services/notificationService';
import {
  setDropdownNotificationRequestStatus,
  setIsPageLimit,
  setNotificationsUpdateStatus,
  setOffsetIncrement,
  setRecordNotificationRequestStatus,
  setRequestNotifications,
  setRequestNotificationsDropdown,
  setRequestNotificationsRecord,
  setStatusNotificationsDropdown,
  setStatusNotificationsRecord,
} from 'store/notifications';
import { AppThunk } from 'store/store';
import { NotificationEmployee } from 'types/models/NotificationModel';
import { SocketResponseNotification } from 'types/SocketResponse';

const NOTIFICATIONS_LIMIT = 20;

export const getRequestNotificationsDropdown = (): AppThunk => async (dispatch) => {
  dispatch(setDropdownNotificationRequestStatus('loading'));
  try {
    const options = {
      where: {
        isRead: false,
        requiresAction: true,
      },
      order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
    };

    const { data } = await employeeService.getSelfNotifications(options);
    dispatch(setRequestNotifications(data.notifications));
    const latestTimeOff = data.notifications.find(
      (notification: NotificationEmployee) => notification.notification.subtype === 'timeoff',
    );
    const latestTimesheet = data.notifications.find(
      (notification: NotificationEmployee) => notification.notification.subtype === 'timesheet',
    );

    const requestNotifications = [];

    if (latestTimeOff)
      requestNotifications.push({
        count: data.timeOffRequestsCount,
        createdAt: latestTimeOff ? latestTimeOff.createdAt : '',
        description: `You have ${data.timeOffRequestsCount} Time Off Requests to see`,
        iconName: 'Time',
        subtype: 'timeoff',
      });

    if (latestTimesheet)
      requestNotifications.push({
        count: data.timeSheetsRequestsCount,
        createdAt: latestTimesheet ? latestTimesheet.createdAt : '',
        description: `You have ${data.timeSheetsRequestsCount} Timesheets to see`,
        iconName: 'Timesheet',
        subtype: 'timesheet',
      });

    dispatch(setRequestNotificationsDropdown(requestNotifications));
    dispatch(setDropdownNotificationRequestStatus('success'));
  } catch (error) {
    dispatch(setDropdownNotificationRequestStatus('failed'));
  }
};

export const getStatusNotificationsDropdown = (): AppThunk => async (dispatch) => {
  dispatch(setDropdownNotificationRequestStatus('loading'));
  try {
    const options = {
      where: {
        isRead: false,
        requiresAction: false,
      },
      order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
    };

    const { data } = await employeeService.getSelfNotifications(options);
    dispatch(setStatusNotificationsDropdown(data.notifications));
    dispatch(setDropdownNotificationRequestStatus('success'));
  } catch (error) {
    dispatch(setDropdownNotificationRequestStatus('failed'));
  }
};

export const getStatusNotificationRecord =
  (page: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRecordNotificationRequestStatus('loading'));
    try {
      const options = {
        where: {
          requiresAction: false,
        },
        limit: NOTIFICATIONS_LIMIT,
        offset: page * NOTIFICATIONS_LIMIT + getState().notifications.offsetIncrement,
        order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
      };

      const { data } = await employeeService.getSelfNotifications(options);
      dispatch(setIsPageLimit(data.notifications.length === 0));
      dispatch(
        setStatusNotificationsRecord([...getState().notifications.statusNotificationsRecord, ...data.notifications]),
      );
      dispatch(setRecordNotificationRequestStatus('success'));
    } catch (error) {
      dispatch(setRecordNotificationRequestStatus('failed'));
    }
  };

export const getRequestNotificationsRecord =
  (page: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRecordNotificationRequestStatus('loading'));
    try {
      const options = {
        where: {
          requiresAction: true,
        },
        limit: NOTIFICATIONS_LIMIT,
        offset: page * NOTIFICATIONS_LIMIT + getState().notifications.offsetIncrement,
        order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
      };

      const { data } = await employeeService.getSelfNotifications(options);
      dispatch(setIsPageLimit(data.notifications.length === 0));
      dispatch(
        setRequestNotificationsRecord([...getState().notifications.requestNotificationsRecord, ...data.notifications]),
      );
      dispatch(setRecordNotificationRequestStatus('success'));
    } catch (error) {
      dispatch(setRecordNotificationRequestStatus('failed'));
    }
  };

export const getAllNotificationsRecord =
  (index: number, type: string): AppThunk =>
  async (dispatch) => {
    dispatch(setRecordNotificationRequestStatus('loading'));
    try {
      const options = {
        where: {
          type,
        },
        limit: index,
        order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
      };

      const { data } = await employeeService.getSelfNotifications(options);
      if (type === 'status') dispatch(setStatusNotificationsRecord(data.notifications));
      dispatch(setRecordNotificationRequestStatus('success'));
    } catch (error) {
      dispatch(setRecordNotificationRequestStatus('failed'));
    }
  };

export const getLatestNotificationRecord =
  (type: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setRecordNotificationRequestStatus('loading'));
    try {
      const options = {
        where: {
          type,
        },
        limit: 1,
        order: [['createdAt', 'DESC'] as [string, 'ASC' | 'DESC']],
      };

      const { data } = await employeeService.getSelfNotifications(options);
      if (type === 'status' && getState().notifications.statusNotificationsRecord.length) {
        dispatch(
          setStatusNotificationsRecord([...data.notifications, ...getState().notifications.statusNotificationsRecord]),
        );
        dispatch(setOffsetIncrement(getState().notifications.offsetIncrement + 1));
      } else if (type === 'request' && getState().notifications.requestNotificationsRecord.length) {
        dispatch(
          setRequestNotificationsRecord([
            ...data.notifications,
            ...getState().notifications.requestNotificationsRecord,
          ]),
        );
        dispatch(setOffsetIncrement(getState().notifications.offsetIncrement + 1));
      }
      dispatch(setRecordNotificationRequestStatus('success'));
    } catch (error) {
      dispatch(setRecordNotificationRequestStatus('failed'));
    }
  };

export const updateNotificationAsRead =
  (notificationId: number): AppThunk =>
  async (dispatch) => {
    dispatch(setNotificationsUpdateStatus('loading'));
    try {
      await notificationService.update(notificationId, {
        isRead: true,
      });

      dispatch(setNotificationsUpdateStatus('success'));
    } catch (error) {
      dispatch(setNotificationsUpdateStatus('failed'));
    } finally {
      setNotificationsUpdateStatus('idle');
    }
  };

export const updateAllNotificationAsRead = (): AppThunk => async (dispatch) => {
  dispatch(setNotificationsUpdateStatus('loading'));
  try {
    await employeeService.updateAllNotificationAsRead();
    dispatch(setNotificationsUpdateStatus('success'));
  } catch (error) {
    dispatch(setNotificationsUpdateStatus('failed'));
  } finally {
    setNotificationsUpdateStatus('idle');
  }
};

export const resolveSocketEvent =
  (event: SocketResponseNotification): AppThunk =>
  (dispatch) => {
    switch (event.notificationType) {
      case 'bell':
        if (event.notificationCategory === NotificationCategory.Request) {
          dispatch(getRequestNotificationsDropdown());
        } else if (event.notificationCategory === NotificationCategory.Status) {
          dispatch(getStatusNotificationsDropdown());
        }
        dispatch(getLatestNotificationRecord(event.notificationCategory));
        break;
      default:
    }
  };

export const cleanRecords = (): AppThunk => (dispatch) => {
  dispatch(setStatusNotificationsRecord([]));
  dispatch(setRequestNotificationsRecord([]));
  dispatch(setOffsetIncrement(0));
  dispatch(setIsPageLimit(false));
};
