import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import reducers from 'store/teamPosition/reducers';
import { TeamPosition } from 'types/Project/TeamPosition';

export interface TeamPositionState {
  allTeamPositions: TeamPosition[];
  projectSubmitStatus: RequestStatus;
  teamPositionCreationStatus: RequestStatus;
  teamPositionDeleteStatus: RequestStatus;
  teamPositionUpdateStatus: RequestStatus;
  teamPositions: TeamPosition[];
  teamPositionsHaveEmployee: boolean;
  teamPositionsStatus: RequestStatus;
  totalMembers: number;
}

const initialState: TeamPositionState = {
  allTeamPositions: [],
  projectSubmitStatus: RequestStatus.IDLE,
  teamPositionCreationStatus: RequestStatus.LOADING,
  teamPositionDeleteStatus: RequestStatus.LOADING,
  teamPositions: [],
  teamPositionsHaveEmployee: false,
  teamPositionsStatus: RequestStatus.LOADING,
  teamPositionUpdateStatus: RequestStatus.LOADING,
  totalMembers: 0,
};

export const teamPositionSlice = createSlice({
  name: 'teamPosition',
  initialState,
  reducers,
});

export const {
  setAllTeamPositions,
  setProjectSubmitStatus,
  setTeamPositionCreationStatus,
  setTeamPositionDeleteStatus,
  setTeamPositionUpdateStatus,
  setTeamPositionsData,
  setTeamPositionsHaveEmployee,
  setTeamPositionsStatus,
  setTotalMembers,
} = teamPositionSlice.actions;

export default teamPositionSlice.reducer;
