import { noop } from 'lodash';

import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { fileService } from 'services/fileService';
import { projectAttachmentService } from 'services/projectAttachmentService';
import { projectInitiationFormService } from 'services/projectInitiationFormService';
import {
  setProjectCreationStatus,
  setProjectDetailsData,
  setProjectDetailsStatus,
  setProjectId,
  setProjectSubmitReviewStatus,
  setProjectUpdateStatus,
} from 'store/projectInitiationForm';
import { AppThunk } from 'store/store';
import { DispatchStatusCallbacks } from 'types/Dispatch/Dispatch';
import { onlyShowFile, PIFProject } from 'types/PIFProject';
import { NewProjectReview } from 'types/Project/NewProjectReview';
import { ProjectModel } from 'types/Project/ProjectModel';
import { mapPIFProjectToNewProjectModel, mapProjectModelToProjectDetails } from 'utils/parsers/project';

function isOnlyShowArray(attachment: File[] | onlyShowFile[]) {
  if (!attachment.length) return false;
  return 'attachmentId' in attachment[0];
}

export const createProject =
  (newProject: PIFProject, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectCreationStatus(RequestStatus.LOADING));
    try {
      const projectModel = mapPIFProjectToNewProjectModel(newProject);
      const { data } = await projectInitiationFormService.create(projectModel);
      onSuccess();
      const projectDetails = mapProjectModelToProjectDetails(data);
      dispatch(setProjectId(data.id));
      dispatch(setProjectDetailsData(projectDetails));

      const newFiles = newProject.projectAttachments;
      const isOnlyShowType = isOnlyShowArray(newFiles);
      if (!!newFiles?.length && !isOnlyShowType) {
        const documents = Array.from(newFiles as File[]);
        const filesResponse = await Promise.all(
          documents.map((document) =>
            fileService.create({
              type: document.type,
              fileName: document.name,
            }),
          ),
        ).catch(() => {
          dispatch(setProjectCreationStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });

        await Promise.all(
          filesResponse.map((file, i) =>
            fetch(file.data.uploadUrl as string, {
              method: 'PUT',
              body: documents[i],
            }),
          ),
        ).catch(() => {
          dispatch(setProjectCreationStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });

        await Promise.all(
          filesResponse.map((file) =>
            projectAttachmentService.create({
              type: file.data.type,
              fileName: file.data.fileName,
              path: file.data.path,
              projectId: data.id,
              attachmentId: file.data.id,
            }),
          ),
        ).catch(() => {
          dispatch(setProjectCreationStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });
      }

      dispatch(setProjectCreationStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setProjectCreationStatus(RequestStatus.FAILED));
      onError();
    }
  };

export const updateProject =
  (
    newProject: PIFProject,
    projectId: number,
    { onSuccess = noop, onError = noop }: DispatchStatusCallbacks,
    attachmentIdsToDelete: number[],
  ): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectUpdateStatus(RequestStatus.LOADING));
    try {
      if (attachmentIdsToDelete.length) {
        await Promise.all(
          attachmentIdsToDelete.filter((id) => id !== -1).map((id) => projectAttachmentService.delete(id)),
        );
      }
      let projectData: ProjectModel;

      const options: ServiceOptions = {
        include: ['projectAttachments'],
      };

      const newFiles = newProject.projectAttachments;
      const isOnlyShowType = isOnlyShowArray(newFiles);
      if (!!newFiles?.length && !isOnlyShowType) {
        const documents = Array.from(newFiles as File[]);
        const filesResponse = await Promise.all(
          documents.map((document) =>
            fileService.create({
              type: document.type,
              fileName: document.name,
            }),
          ),
        ).catch(() => {
          dispatch(setProjectUpdateStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });

        await Promise.all(
          filesResponse.map(({ data }, i) =>
            fetch(data.uploadUrl as string, {
              method: 'PUT',
              body: documents[i],
            }),
          ),
        ).catch(() => {
          dispatch(setProjectUpdateStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });

        const filesAttached = await Promise.all(
          filesResponse.map(({ data }) =>
            projectAttachmentService.create({
              type: data.type,
              fileName: data.fileName,
              path: data.path,
              projectId,
              attachmentId: data.id,
              createdAt: data.createdAt,
            }),
          ),
        ).catch(() => {
          dispatch(setProjectUpdateStatus(RequestStatus.UPLOAD_FAILED));
          throw Error('UploadError');
        });

        const projectModel = mapPIFProjectToNewProjectModel(newProject);

        const request = {
          ...projectModel,
          projectAttachments: filesAttached[0].data,
        };

        const { data } = await projectInitiationFormService.update(projectId, request, options);

        projectData = data;
      } else {
        const projectModel = mapPIFProjectToNewProjectModel(newProject);

        const { data } = await projectInitiationFormService.update(projectId, projectModel, options);
        projectData = data;
      }

      const { projectAttachments } = projectData;

      if (projectAttachments?.length) {
        const attachmentsWithS3Url = projectAttachments.map(async (attachment) => {
          const { attachmentId } = attachment;
          if (attachmentId) {
            const { data } = await fileService.getById(attachmentId);
            return { ...attachment, path: data.url, createdAt: data.createdAt as string };
          }
          return attachment;
        });

        const request: ProjectModel = {
          ...projectData,
          projectAttachments: await Promise.all(attachmentsWithS3Url),
        };

        projectData = request;
      }
      const projectDetails = mapProjectModelToProjectDetails(projectData);
      dispatch(setProjectDetailsData(projectDetails));
      dispatch(setProjectUpdateStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectUpdateStatus(RequestStatus.FAILED));
      onError();
    }
  };

export const getProjectById =
  (projectId: number | string): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectDetailsStatus(RequestStatus.LOADING));
    try {
      const opts: ServiceOptions = {
        include: ['customer', 'currency', 'businessunit', 'contractType', 'approver', 'ProjectAttachment'],
      };
      const { data } = await projectInitiationFormService.getById(projectId, opts);

      const {
        data: { projectCost },
      } = await projectInitiationFormService.getCostById(projectId);

      const dataWithCost: ProjectModel = { ...data, cost: projectCost ?? 0 };

      const { projectAttachments } = dataWithCost;
      if (projectAttachments?.length) {
        const attachmentsWithS3Url = projectAttachments.map(async (attachment) => {
          const { attachmentId } = attachment;
          if (attachmentId) {
            const { data } = await fileService.getById(attachmentId);
            return { ...attachment, path: data.url };
          }
          return attachment;
        });

        const request: ProjectModel = {
          ...dataWithCost,
          projectAttachments: await Promise.all(attachmentsWithS3Url),
        };

        const projectDetails = mapProjectModelToProjectDetails(request);
        dispatch(setProjectDetailsData(projectDetails));
      } else {
        const projectDetails = mapProjectModelToProjectDetails(dataWithCost);
        dispatch(setProjectDetailsData(projectDetails));
      }
      dispatch(setProjectDetailsStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setProjectDetailsStatus(RequestStatus.FAILED));
    }
  };

export const submitProjectReview =
  (projectReview: NewProjectReview, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectSubmitReviewStatus(RequestStatus.LOADING));
    try {
      await projectInitiationFormService.submitReview(projectReview);
      dispatch(setProjectSubmitReviewStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectSubmitReviewStatus(RequestStatus.FAILED));
      onError(error);
    }
  };

export const submitProjectAppraise =
  (projectAppraise: NewProjectReview, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectSubmitReviewStatus(RequestStatus.LOADING));
    try {
      await projectInitiationFormService.submitAppraise(projectAppraise);
      dispatch(setProjectSubmitReviewStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectSubmitReviewStatus(RequestStatus.FAILED));
      onError(error);
    }
  };
