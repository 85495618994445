import React from 'react';

import { ProjectStatus } from 'types/Project';
import { IndexSignature } from 'types/utils';

import { StatusProps } from '../types';

import styles from './StatusNumber.module.scss';

const STATUS_WATERFALL_STEPS: IndexSignature<number> = {
  [ProjectStatus.DRAFT]: 1,
  [ProjectStatus.PENDING]: 2,
  [ProjectStatus.APPRAISING]: 3,
  [ProjectStatus.APPROVED]: 4,
  [ProjectStatus.REJECTED]: 4,
};

export const StatusNumber: React.FC<StatusProps> = ({ status }) => (
  <div className={styles.draft}>{STATUS_WATERFALL_STEPS[status]}</div>
);
