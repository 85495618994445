import { PayloadAction } from '@reduxjs/toolkit';

import { EmployeeState } from 'store/employee';
import { Employee } from 'types/Employee';
import { RequestStatus } from 'types/RequestStatus';

const setEmployeeCount = (state: EmployeeState, action: PayloadAction<number>): void => {
  state.count = action.payload;
};

const setEmployeeData = (state: EmployeeState, action: PayloadAction<Employee>): void => {
  state.data = action.payload;
};

const setEmployeeRequestStatus = (state: EmployeeState, action: PayloadAction<RequestStatus>): void => {
  state.requestStatus = action.payload;
};

const setEmployees = (state: EmployeeState, action: PayloadAction<Employee[]>): void => {
  state.employees = action.payload;
};

const setEmployeesStatus = (state: EmployeeState, action: PayloadAction<RequestStatus>): void => {
  state.employeesRequestStatus = action.payload;
};

const reducers = {
  setEmployeeCount,
  setEmployeeData,
  setEmployeeRequestStatus,
  setEmployees,
  setEmployeesStatus,
};

export default reducers;
