import React, { Fragment, ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';

import ProjectDetails from 'assets/images/ProjectDetails.svg';
import ProjectTeamImage from 'assets/images/ProjectTeamImage.svg';
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs';
import { CircleIconButton } from 'components/CircleIconButton/CircleIconButton';
import { KDialogSmall } from 'components/Dialogs/KDialogSmall/KDialogSmall';
import { KDialogWithChildren } from 'components/Dialogs/KDialogWithChildren/KDialogWithChildren';
import { KDialogActionKind } from 'components/Dialogs/types';
import KSkeleton from 'components/KSkeleton/KSkeleton';
import { PIFTooltip } from 'components/Tooltips/PIFTooltip';
import { TopBar } from 'components/TopBar/TopBar';
import { Rol } from 'constants/Credentials/Rol';
import { DefaultPermissions } from 'constants/defaultPermissions';
import { ProjectStep } from 'constants/ProjectDashboard/pifConstants';
import { RequestStatus } from 'constants/requestStatus';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { AppraiseProjectForm } from 'modules/Project/components/AppraiseProjectForm/AppraiseProjectForm';
import { ProjectDetailsForm } from 'modules/Project/components/NewProjectForm/ProjectDetailsForm/ProjectDetailsForm';
import { ProjectDetailsSkeleton } from 'modules/Project/components/PIFSkeleton/ProjectDetailsSkeleton/ProjectDetailsSkeleton';
import { ProjectDetailsOverview } from 'modules/Project/components/ProjectDetailsOverview/ProjectDetailsOverview';
import { ProjectDetailsRender } from 'modules/Project/components/ProjectDetailsRender/ProjectDetailsRender';
import { ProjectTeam } from 'modules/Project/components/ProjectTeam/ProjectTeam';
import StepInformation from 'modules/Project/components/StepInformation/StepInformation';
import { TabPanel } from 'modules/Project/components/TabPanel/TabPanel';
import { TeamRosterAppraisal } from 'modules/Project/components/TeamRosterAppraisal/TeamRosterAppraisal';
import { PIFSchema } from 'modules/Project/views/ProjectBuilder/PIFSchema';
import styles from 'modules/Project/views/ProjectViewer/ProjectViewer.module.scss';
import { getBusinessUnits } from 'store/businessUnit/thunk';
import { getContractTypes } from 'store/contractType/thunk';
import { getAllCurrency } from 'store/currency/thunk';
import { getAllCustomers } from 'store/customer/thunk';
import { getAllEmployees } from 'store/employee/thunks';
import { setProjectId } from 'store/projectInitiationForm';
import {
  selectpifUpdateStatus,
  selectProjectDetailsData,
  selectProjectDetailsStatus,
} from 'store/projectInitiationForm/selectors';
import {
  getProjectById,
  submitProjectAppraise,
  submitProjectReview,
  updateProject,
} from 'store/projectInitiationForm/thunks';
import { selectSessionRoles } from 'store/session/selectors';
import { getAllSkills } from 'store/skills/thunk';
import {
  selectTeamPositions,
  selectTeamPositionsHaveEmployee,
  selectTeamPositionsStatus,
} from 'store/teamPosition/selectors';
import { getAllTeamPositionsByPIF, getTeamPositionsByPIF, submitProject } from 'store/teamPosition/thunks';
import { Permissions } from 'types/Permissions';
import { FileAttached } from 'types/PreviewAttachment';
import { ProjectStatus } from 'types/Project';
import { NewProjectReview } from 'types/Project/NewProjectReview';
import { IndexSignature } from 'types/utils';
import { calculateWeeklyForecastedHours } from 'utils/calculateForecastedHours';
import { checkTeamPermissions } from 'utils/checkTeamPermissions';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { mapProjectAndPositionModelToPIF } from 'utils/parsers/project';
import { hasRole } from 'utils/roles';

import { getIdsToDelete, shouldDeleteFiles } from '../view.utils';

export const ProjectViewer = (): ReactElement => {
  const dispatch = useAppDispatch();
  const projectDetailsData = useAppSelector(selectProjectDetailsData);
  const projectDetailsStatus = useAppSelector(selectProjectDetailsStatus);
  const projectTeamData = useAppSelector(selectTeamPositions);
  const projectTeamStatus = useAppSelector(selectTeamPositionsStatus);
  const projectUpdateStatus = useAppSelector(selectpifUpdateStatus);
  const projectTeamPositionsHaveEmployees = useAppSelector(selectTeamPositionsHaveEmployee);
  const roles = useAppSelector(selectSessionRoles);
  const history = useHistory();
  const { id } = useParams<IndexSignature<string>>();
  const { url } = useRouteMatch();
  const [activeStep, setActiveStep] = useState<ProjectStep>(ProjectStep.PROJECT_DETAILS);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [isEdition, setIsEdition] = useState(url.split('/').includes('edit'));
  const [isPositionDialogOpen, setIsPositionDialogOpen] = useState(false);
  const [isProjectReviewDialogOpen, setIsProjectReviewDialogOpen] = useState(false);
  const [isProjectAppraiseDialogOpen, setIsProjectAppraiseDialogOpen] = useState(false);
  const [projectReviewData, setProjectReviewData] = useState<{ feedback: string; value: boolean } | null>(null);
  const [projectAppraiseData, setProjectAppraiseData] = useState<{ feedback: string; value: boolean } | null>(null);
  const [overwriteForecastedHours, setOverwriteForecastedHours] = useState(false);
  const [showBrowserConfirmation, setShowBrowserConfirmation] = useState(false);
  const [enableProjectReviewSubmission, setEnableProjectReviewSubmission] = useState(false);
  const [enableProjectAppraiseSubmission, setEnableProjectAppraiseSubmission] = useState(false);
  const [projectReviewHasApproveError, setProjectReviewHasApproveError] = useState(false);
  const [currentPermissions, setCurrentPermissions] = useState<Permissions>(DefaultPermissions);
  const historyState = history.location.state as IndexSignature<boolean>;
  const isProjectInitiator = hasRole(roles, Rol.PROJECT_INITIATOR);
  const isAppraiser = hasRole(roles, Rol.APPRAISER);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<FileAttached[]>([]);

  const { budgetForecasting, creationStep, currencySymbol, projectAttachments, totalForecastedHours } =
    projectDetailsData;

  useEffect(() => {
    setFile(projectAttachments ?? []);
  }, [projectAttachments]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: mapProjectAndPositionModelToPIF(projectDetailsData, projectTeamData),
    validateOnChange: true,
    validationSchema: PIFSchema,
    onSubmit: () => undefined,
  });

  const { dirty, isValid, values, resetForm } = formik;

  const breadCrumbsOptions = [
    { id: 1, label: 'Project Initiation Form', path: '/' },
    { id: 2, label: values.projectName },
  ];

  const teamCost = useMemo(() => projectDetailsData.cost ?? 0, [projectDetailsData]);

  const teamMembersTotalHours = useMemo(() => {
    if (!projectTeamData) return -1;
    const summedHours = projectTeamData.reduce((acum, teamMember) => {
      const memberInfo = {
        startDate: new Date(teamMember.startDate),
        endDate: new Date(teamMember.endDate),
        weeklyForecastedHours: teamMember.weeklyForecastedHours,
      };

      return acum + calculateWeeklyForecastedHours(memberInfo);
    }, 0);
    return summedHours;
  }, [projectTeamData]);

  const mismatchedForecastedHours =
    creationStep === ProjectStatus.PENDING &&
    overwriteForecastedHours !== undefined &&
    teamMembersTotalHours > (totalForecastedHours ?? 0);

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllCustomers());
    dispatch(getAllSkills());
    dispatch(getBusinessUnits());
    dispatch(getContractTypes());
    dispatch(getAllEmployees());
    dispatch(getProjectById(id));
    dispatch(getTeamPositionsByPIF(+id));
    dispatch(setProjectId(+id));
    return () => {
      dispatch(setProjectId(null));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (projectUpdateStatus === RequestStatus.SUCCESS) {
      dispatch(getProjectById(id));
    }
  }, [dispatch, id, projectUpdateStatus]);

  useEffect(() => {
    setIsEdition(url.split('/').includes('edit'));
  }, [url]);

  useEffect(() => {
    setShowBrowserConfirmation(dirty);
  }, [dirty]);

  useEffect(() => {
    if (projectDetailsStatus === RequestStatus.SUCCESS && projectTeamStatus === RequestStatus.SUCCESS) {
      resetForm();
    }
  }, [projectDetailsStatus, projectTeamStatus, resetForm]);

  useEffect(() => {
    if (roles && projectDetailsData) {
      setCurrentPermissions(checkTeamPermissions(roles, creationStep));
    }
  }, [roles, projectDetailsData, creationStep]);

  const handleProjectSubmit = (): void => {
    dispatch(
      submitProject(Number(id), {
        onSuccess: () => {
          enqueueSnackbar(`${values.projectName} project has been submitted for Approval!`, SnackbarVariant.SUCCESS);
          history.push('/');
        },
        onError: () => enqueueSnackbar('Error trying to submit project', SnackbarVariant.ERROR),
      }),
    );
  };

  const handleSubmitProjectReview = (): void => {
    if (projectReviewData) {
      const { feedback, value } = projectReviewData;
      const newProjectReview: NewProjectReview = {
        approved: value,
        comment: feedback,
        projectId: +id,
        ...(overwriteForecastedHours && { replaceTotalForecastedHours: overwriteForecastedHours }),
      };
      dispatch(
        submitProjectReview(newProjectReview, {
          onSuccess: () => {
            enqueueSnackbar(
              value
                ? `You have Submitted the project for Finance Appraisal: ${values.projectName}!`
                : `You have Rejected the project: ${values.projectName}!`,
              SnackbarVariant.SUCCESS,
            );
            history.push('/');
          },
          onError: () => enqueueSnackbar('Error trying to submit project review', SnackbarVariant.ERROR),
        }),
      );
    }
  };

  const handleSubmitProjectAppraise = () => {
    if (projectAppraiseData) {
      const { feedback, value } = projectAppraiseData;
      const newProjectAppraise: NewProjectReview = {
        approved: value,
        comment: feedback,
        projectId: +id,
      };
      dispatch(
        submitProjectAppraise(newProjectAppraise, {
          onSuccess: () => {
            enqueueSnackbar(
              value
                ? `You have Approved the project: ${values.projectName}!`
                : `You have Rejected the project: ${values.projectName}!`,
              SnackbarVariant.SUCCESS,
            );
            history.push('/');
          },
          onError: () => enqueueSnackbar('Error trying to submit project appraise', SnackbarVariant.ERROR),
        }),
      );
    }
  };

  const handleProjectReview = (): void => {
    dispatch(getAllTeamPositionsByPIF(+id));
    setIsProjectReviewDialogOpen(true);
  };

  const handleCancelProjectAppraiseDialog = () => {
    setIsProjectAppraiseDialogOpen(false);
    setProjectAppraiseData(null);
    setEnableProjectAppraiseSubmission(false);
  };

  const handleValueChangeProjectAppraise = (feedback: string, value: boolean) => {
    setEnableProjectAppraiseSubmission(value ? true : feedback.length > 0);
    setProjectAppraiseData({
      value,
      feedback,
    });
  };

  const handleCancelProjectReviewDialog = (): void => {
    setIsProjectReviewDialogOpen(false);
    setProjectReviewHasApproveError(false);
    setProjectReviewData(null);
    setEnableProjectReviewSubmission(false);
  };

  const handleValueChangeProjectReview = (feedback: string, value: boolean): void => {
    setProjectReviewHasApproveError(value && (!projectTeamPositionsHaveEmployees || projectTeamData.length === 0));
    setEnableProjectReviewSubmission(value ? projectTeamPositionsHaveEmployees : feedback.length > 0);
    setProjectReviewData({
      value,
      feedback,
    });
  };

  const handleCloseEditionDialog = (): void => {
    setShowBrowserConfirmation(false);
    setTimeout(() => {
      if (historyState && historyState.fromViewer) history.goBack();
      history.replace(`/project/${id}`);
    }, 0);
  };

  const handleSaveEditionDialog = (): void => {
    const pifValues = values;

    const listOfIdsToDelete = getIdsToDelete(file);
    const isIdsToDelete = shouldDeleteFiles(
      values.projectAttachments instanceof FileList,
      !!projectAttachments?.length ?? false,
      !!values.projectAttachments.length,
    );

    dispatch(
      updateProject(
        pifValues,
        +id,
        {
          onSuccess: () => enqueueSnackbar(`${values.projectName} has been saved`, SnackbarVariant.SUCCESS),
          onError: () => enqueueSnackbar('Error trying to save project', SnackbarVariant.ERROR),
        },

        isIdsToDelete ? listOfIdsToDelete : [],
      ),
    );
    handleCloseEditionDialog();
  };

  const handleCancelEditionDialog = (): void => {
    if (dirty) {
      return setConfirmationDialog(true);
    }
    return handleCloseEditionDialog();
  };

  const handleAddPosition = (): void => {
    setIsPositionDialogOpen(true);
  };

  const handleProjectTeamDialogClose = (): void => {
    setIsPositionDialogOpen(false);
  };

  const handleCancelDiscardChanges = (): void => {
    setConfirmationDialog(false);
  };

  const handleDiscardEditionChanges = (): void => {
    setConfirmationDialog(false);
    handleCloseEditionDialog();
  };

  const handleOverwriteForecastedHours = (): void => {
    setOverwriteForecastedHours(!overwriteForecastedHours);
  };

  const handleClickNext = (): void => setActiveStep(ProjectStep.TEAM_ROASTER);

  const handleClickBack = (): void => setActiveStep(ProjectStep.PROJECT_DETAILS);

  const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
    setActiveStep(newValue);
  };

  const renderTopBar = () => {
    if (projectDetailsStatus === RequestStatus.SUCCESS) {
      if (isAppraiser && creationStep === ProjectStatus.APPRAISING) {
        return (
          <TopBar
            actionLabel='Appraise Project'
            staticTitle='Back'
            onActionClick={() => setIsProjectAppraiseDialogOpen(true)}
          />
        );
      }
      return <TopBar staticTitle='Back' />;
    }
    return <KSkeleton elementHeight='60px' />;
  };

  const renderUserAction = () =>
    isProjectInitiator ? (
      <Button className={styles.button} variant='contained' onClick={handleProjectSubmit}>
        Submit
      </Button>
    ) : (
      <Button className={styles.button} variant='contained' onClick={handleProjectReview}>
        Finish Review
      </Button>
    );

  const renderStepTwoActions = () => {
    if (currentPermissions.canEdit) {
      return (
        <Fragment>
          <Button className={clsx(styles.button)} variant='outlined' onClick={handleClickBack}>
            Back
          </Button>
          {projectTeamData.length === 0 ? (
            <Button className={styles.button} variant='contained' onClick={handleAddPosition}>
              Add New Position
            </Button>
          ) : (
            renderUserAction()
          )}
        </Fragment>
      );
    }
    return (
      <Button className={styles.button} variant='contained' onClick={handleClickBack}>
        Back
      </Button>
    );
  };

  const renderSteps = (): ReactElement => {
    if (isAppraiser) {
      if (projectDetailsStatus === RequestStatus.SUCCESS) {
        return (
          <StepInformation
            description={`${values.projectName} Appraisal`}
            image={ProjectDetails}
            message='Review all the information regarding the project, after that, give your appraise of the project.'
          />
        );
      }
      return <Skeleton height='122px' variant='rect' width='100%' />;
    }

    return activeStep === ProjectStep.PROJECT_DETAILS ? (
      <StepInformation
        description='Project Details'
        image={ProjectDetails}
        message='Fill in all your project details so the reviewer can have a clear view of it.'
      />
    ) : (
      <StepInformation
        description='Project Team'
        image={ProjectTeamImage}
        message='Add the job positions to help you succeed on your new project.'
      />
    );
  };

  const renderOverwriteForecastedHours = () => {
    if (!mismatchedForecastedHours) return <Fragment />;
    const projectSpecs = [
      {
        subtitle: 'Project Forecasted Hours',
        value: totalForecastedHours || 'N/A',
      },
      {
        subtitle: 'Members Forecasted Hours',
        value: teamMembersTotalHours || 'N/A',
      },
    ];
    const tooltipMessage = totalForecastedHours
      ? 'Overwrite current Project Total Forecasted Hours with Roster Total Forecasted Hours'
      : 'Project Total Forecasted Hours will be set to Roster Total Forecasted Hours';
    return (
      <Grid className={styles.forecastOverwrite} container>
        <Box className={styles.row}>Project and team members forecasted hours are mismatched.</Box>
        <Grid className={styles.forecastContainer} container>
          {projectSpecs.map(({ subtitle, value }, idx) => (
            <Grid item key={`project-specs-${idx.toString()}`} xs={4}>
              <Typography className={styles.value}>{subtitle}</Typography>
              <Typography>{value}</Typography>
            </Grid>
          ))}
          <Grid item xs={2}>
            <Typography className={styles.value}>Overwrite</Typography>
            <Switch
              checked={totalForecastedHours ? overwriteForecastedHours : true}
              disabled={!totalForecastedHours}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              name='replaceForecastedHours'
              onChange={handleOverwriteForecastedHours}
            />
          </Grid>
          <Box className={styles.tooltipContainer}>
            <PIFTooltip kind='lg' title={tooltipMessage}>
              <span>
                <CircleIconButton className={styles.tooltip} onClick={noop}>
                  <p style={{ fontSize: '1rem', transform: 'none' }}>i</p>
                </CircleIconButton>
              </span>
            </PIFTooltip>
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderContent = (): ReactElement => {
    if (isAppraiser) {
      if (projectDetailsStatus === RequestStatus.SUCCESS) {
        return (
          <Fragment>
            <Tabs value={activeStep} onChange={handleTabChange}>
              <Tab label='Project Details' value={ProjectStep.PROJECT_DETAILS} />
              <Tab label='Team Roster' value={ProjectStep.TEAM_ROASTER} />
            </Tabs>
            <TabPanel index={ProjectStep.PROJECT_DETAILS} value={activeStep}>
              <ProjectDetailsRender
                canEdit={currentPermissions.canEdit}
                className={styles.tabPanelChild}
                projectDetailsData={projectDetailsData}
                projectDetailsStatus={projectDetailsStatus}
              />
            </TabPanel>
            <TabPanel index={ProjectStep.TEAM_ROASTER} value={activeStep}>
              <TeamRosterAppraisal className={styles.tabPanelChild} teamData={projectTeamData} />
            </TabPanel>
          </Fragment>
        );
      }
      return <Skeleton height='918px' variant='rect' width='100%' />;
    }
    return (
      <form onSubmit={formik.handleSubmit}>
        {activeStep === ProjectStep.PROJECT_DETAILS ? (
          <Fragment>
            <CardContent className={styles.cardContent}>
              <ProjectDetailsRender
                canEdit={currentPermissions.canEdit}
                className={styles.stepperChild}
                projectDetailsData={projectDetailsData}
                projectDetailsStatus={projectDetailsStatus}
              />
            </CardContent>
            <CardActions className={styles.cardActions}>
              <Button className={styles.button} variant='contained' onClick={handleClickNext}>
                Next
              </Button>
            </CardActions>
          </Fragment>
        ) : (
          <Fragment>
            <CardContent className={styles.cardContent}>
              <ProjectTeam
                formik={formik}
                isOpen={isPositionDialogOpen}
                onClick={handleAddPosition}
                onClose={handleProjectTeamDialogClose}
              />
            </CardContent>
            <CardActions
              className={clsx(styles.cardActions, projectTeamData.length !== 0 && styles.cardActionsStepTwo)}
            >
              {renderStepTwoActions()}
            </CardActions>
          </Fragment>
        )}
      </form>
    );
  };

  return (
    <Fragment>
      <div className={styles.container}>
        {renderTopBar()}
        <Box ml='24px' mt='16px'>
          {projectDetailsStatus === RequestStatus.SUCCESS ? (
            <BreadCrumbs options={breadCrumbsOptions} separator='>' />
          ) : (
            <Skeleton height='19PX' width='223px' />
          )}
        </Box>
        <Container maxWidth='lg'>
          <Grid className={styles.generalContainer} container justifyContent='center'>
            <Grid className={styles.containerCard} item xs={12}>
              {renderSteps()}
            </Grid>
            {isAppraiser &&
              (projectDetailsStatus === RequestStatus.SUCCESS ? (
                <ProjectDetailsOverview
                  budget={budgetForecasting}
                  className={styles.containerCard}
                  cost={teamCost}
                  currencySymbol={currencySymbol}
                />
              ) : (
                <Container className={styles.containerCard}>
                  <Skeleton height='144px' variant='rect' width='100%' />
                </Container>
              ))}

            <Grid className={styles.stepContainer} item>
              <Card>{renderContent()}</Card>

              <KDialogWithChildren
                actions={[
                  {
                    label: 'Cancel',
                    kind: KDialogActionKind.CANCEL,
                    onClick: () => handleCancelEditionDialog(),
                  },
                  {
                    disabled: !(isValid && dirty),
                    label: 'Save',
                    kind: KDialogActionKind.PRIMARY,
                    onClick: () => handleSaveEditionDialog(),
                  },
                ]}
                isOpen={isEdition}
                title='Edit Project Details'
                onClose={() => null}
              >
                {projectDetailsStatus === RequestStatus.SUCCESS ? (
                  <ProjectDetailsForm formik={formik} fromModal shouldConfirmReload={showBrowserConfirmation} />
                ) : (
                  <Grid className={styles.skeletonContainer} container justifyContent='center'>
                    <ProjectDetailsSkeleton />
                  </Grid>
                )}
              </KDialogWithChildren>

              <KDialogWithChildren
                actions={[
                  {
                    label: 'Cancel',
                    kind: KDialogActionKind.CANCEL,
                    onClick: () => handleCancelProjectReviewDialog(),
                  },
                  {
                    disabled: !enableProjectReviewSubmission,
                    label: projectReviewData && !projectReviewData.value ? 'Submit Feedback' : 'Submit',
                    kind: KDialogActionKind.PRIMARY,
                    onClick: () => handleSubmitProjectReview(),
                  },
                ]}
                isOpen={isProjectReviewDialogOpen}
                title='Finish Review'
                onClose={() => null}
              >
                <AppraiseProjectForm
                  customApproveError='To submit you need to assign Ksquarians to Job Positions.'
                  description='Are you sure you want to send this project to Finance?'
                  feedBackHelperText='This feedback will be sent to PMO or Sales.'
                  hasApproveError={projectReviewHasApproveError}
                  overwriteHoursRender={renderOverwriteForecastedHours}
                  onValueChange={handleValueChangeProjectReview}
                />
              </KDialogWithChildren>

              <KDialogWithChildren
                actions={[
                  {
                    label: 'Cancel',
                    kind: KDialogActionKind.CANCEL,
                    onClick: () => handleCancelProjectAppraiseDialog(),
                  },
                  {
                    disabled: !enableProjectAppraiseSubmission,
                    label: 'Submit Appraise',
                    kind: KDialogActionKind.PRIMARY,
                    onClick: () => handleSubmitProjectAppraise(),
                  },
                ]}
                isOpen={isProjectAppraiseDialogOpen}
                title='Appraise Project'
                onClose={() => null}
              >
                <AppraiseProjectForm
                  description='Does the project has the requirements to become a new business opportunity for The Ksquare Group?'
                  onValueChange={handleValueChangeProjectAppraise}
                />
              </KDialogWithChildren>

              <KDialogSmall
                actions={[
                  {
                    label: 'Cancel',
                    kind: KDialogActionKind.CANCEL,
                    onClick: () => handleCancelDiscardChanges(),
                  },
                  {
                    label: 'Discard',
                    kind: KDialogActionKind.PRIMARY,
                    onClick: () => handleDiscardEditionChanges(),
                  },
                ]}
                isOpen={confirmationDialog}
                message='By doing this, the changes made will not be saved.'
                title='Discard Changes?'
                onClose={() => null}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};
