import React, { useRef } from 'react';
import { Grid, Tooltip } from '@material-ui/core';

import { hasTextOverflow } from 'utils/dom/domUtils';

import styles from './OverviewCard.module.scss';

interface OverviewCardProps {
  positionName: string;
  totalHours: number;
  totalMembers: number;
}

export const OverviewCard: React.FC<OverviewCardProps> = ({ positionName, totalMembers, totalHours }) => {
  const titleContainerRef = useRef<HTMLSpanElement>(null);
  const hoursContainer = useRef<HTMLSpanElement>(null);

  const titleLabel = `${totalMembers} ${positionName}`;
  const hoursLabel = `${totalHours} Total Weekly Hours`;

  const renderTitleTooltip = (): string =>
    titleContainerRef.current && hasTextOverflow(titleContainerRef.current) ? titleLabel : '';

  const renderHoursTooltip = (): string =>
    hoursContainer.current && hasTextOverflow(hoursContainer.current) ? hoursLabel : '';

  return (
    <Grid className={styles.overviewCard} item key={positionName} xs={3}>
      <span className={styles.overviewCard__title} ref={titleContainerRef}>
        <Tooltip placement='top-start' title={renderTitleTooltip()}>
          <span>{titleLabel}</span>
        </Tooltip>
      </span>
      <span className={styles.overviewCard__hours} ref={hoursContainer}>
        <Tooltip placement='top-start' title={renderHoursTooltip()}>
          <span>{hoursLabel}</span>
        </Tooltip>
      </span>
    </Grid>
  );
};
