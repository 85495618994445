import React, { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { TextFieldTypes } from 'constants/textFieldTypes';
import { KTextField } from 'modules/Project/components/KTextField/KTextField';
import styles from 'modules/Project/components/NewJobPositionForm/NewJobPositionForm.module.scss';
import { TechnologySchemaFactory } from 'modules/Project/components/NewTechnologyForm/TechnologySchemaFactory';
import { SelectAutoComplete } from 'modules/Project/components/SelectAutocomplete/SelectAutoComplete';
import { selectDepartmentsData } from 'store/departments/selectors';
import { getAllDepartments } from 'store/departments/thunk';
import { selectTechnologiesData } from 'store/technologies/selectors';
import { createTechnology } from 'store/technologies/thunk';
import { selectTechnologyTypesData } from 'store/technologyTypes/selectors';
import { getAllTechnologyTypes } from 'store/technologyTypes/thunk';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';

interface NewTechnologyFormProps {
  name: string;
  onCancel: () => void;
}

const initialState = {
  name: '',
  departmentId: '',
  description: '',
  technologyTypesId: '',
};

const NewTechnologyForm = ({ name, onCancel }: NewTechnologyFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const departmentsData = useAppSelector(selectDepartmentsData);
  const technologiesData = useAppSelector(selectTechnologiesData);
  const technologyTypesData = useAppSelector(selectTechnologyTypesData);

  const formik = useFormik({
    initialValues: { ...initialState, name },
    validationSchema: TechnologySchemaFactory(technologiesData),
    onSubmit: (values) => {
      dispatch(
        createTechnology(values, {
          onSuccess: () => {
            enqueueSnackbar(`${values.name} has been successfully added!`, SnackbarVariant.SUCCESS);
            onCancel();
          },
          onError: () => enqueueSnackbar('An error occurred while adding a new  position', SnackbarVariant.ERROR),
        }),
      );
    },
    validateOnChange: true,
  });

  const { dirty, isValid, submitForm, handleSubmit } = formik;

  useEffect(() => {
    dispatch(getAllDepartments());
    dispatch(getAllTechnologyTypes());
  }, [dispatch]);

  return (
    <div className={styles.modalContainer}>
      <Box className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <Grid className={styles.formTitle} item xs={12}>
            <Typography className={styles.title} variant='h1'>
              Add Technology
            </Typography>
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField {...formik} label='Name' name='name' type={TextFieldTypes.TEXT} />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <KTextField {...formik} label='Description' name='description' type={TextFieldTypes.TEXT} />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <SelectAutoComplete
              {...formik}
              label='Technology Type'
              name='technologyTypesId'
              noOptionsText='No Technology Types found'
              tags={technologyTypesData.map(({ name, id }) => ({ label: name, value: id }))}
            />
          </Grid>
          <Grid className={styles.formField} item xs={12}>
            <SelectAutoComplete
              {...formik}
              label='Department'
              name='departmentId'
              noOptionsText='No Departments found'
              tags={departmentsData.map(({ name, id }) => ({ label: name, value: id }))}
            />
          </Grid>
          <Box display='flex' justifyContent='flex-end' mt='auto'>
            <Button className={clsx(styles.button, styles.buttonCancel)} variant='outlined' onClick={onCancel}>
              Cancel
            </Button>
            <Button className={styles.button} disabled={!(isValid && dirty)} variant='contained' onClick={submitForm}>
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default NewTechnologyForm;
