import React from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import clsx from 'clsx';

import styles from 'components/Dialogs/KDialogWithChildren/KDialogWithChildren.module.scss';
import { KDialogActionKind, KDialogCommonProps } from 'components/Dialogs/types';

const createActionKey = (kind: KDialogActionKind, id: number): string => `KDialogWithChildren-Action-${kind}-${id}`;

interface KDialogWithChildrenProps extends Omit<KDialogCommonProps, 'message'> {
  children: React.ReactNode;
}

export const KDialogWithChildren: React.FC<KDialogWithChildrenProps> = ({
  actions,
  children,
  isOpen,
  title,
  isLarge,
  onClose,
}: KDialogWithChildrenProps) => (
  <Dialog
    className={styles.dialog}
    classes={{ paper: isLarge ? styles.paperLarge : styles.paper }}
    open={isOpen}
    onClose={onClose}
  >
    <Typography className={styles.dialogTitle} variant='h1'>
      {title}
    </Typography>
    <DialogContent classes={{ root: styles.dialogContent }}>{children}</DialogContent>
    <div className={styles.dialogActions}>
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.CANCEL)
        .map(({ disabled, label, onClick }, index) => (
          <Button
            className={styles.dialogButton}
            disabled={disabled}
            key={createActionKey(KDialogActionKind.CANCEL, index)}
            variant='outlined'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.PRIMARY)
        .map(({ disabled, label, onClick }, index) => (
          <Button
            className={styles.dialogButton}
            disabled={disabled}
            key={createActionKey(KDialogActionKind.PRIMARY, index)}
            variant='contained'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.CAUTION)
        .map(({ disabled, label, onClick }, index) => (
          <Button
            className={clsx(styles.dialogButton, styles.cautionButton)}
            disabled={disabled}
            key={createActionKey(KDialogActionKind.CAUTION, index)}
            variant='contained'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
    </div>
  </Dialog>
);
