import { CSSProperties } from 'react';
import { camelCase } from 'lodash';

import { KTableHeader, KTableRow } from 'components/KTable/KTable';
import { Order } from 'types/Filter/Filter';

export type HeaderMenuCallback = (anchor: Element, columnKey: string | string[]) => void;

export interface HeaderSortConfiguration {
  columnFieldName: string | string[];
  isExpanded: boolean;
  onClick: HeaderMenuCallback;
}

export interface AbstractHeader {
  id?: string;
  label: string;
  sortConfig?: HeaderSortConfiguration;
  style?: CSSProperties;
}

export interface AbstractTableProps<T> extends AbstractPaginationProps, AbstractFilterProps, AbstractMenuRowProps {
  data: T[];
  type: string;
}

interface AbstractPaginationProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  onPaginationChange: (page: number) => void;
}

interface AbstractFilterProps {
  onColumnSort?: (columnName: string | string[] | undefined, order: Order) => void;
}

interface AbstractMenuRowProps {
  onDelete?: (itemId: string) => void;
  onEdit?: (itemId: string) => void;
  onView?: (itemId: string) => void;
}

export const asHeader = ({ id, label, style, sortConfig }: AbstractHeader): KTableHeader => ({
  id: id ?? camelCase(label),
  label,
  align: 'left',
  sortConfig,
  style,
});

export const rowsOf = <T>(list: T[], mapper: (item: T) => KTableRow): KTableRow[] => list.map(mapper);
