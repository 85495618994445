import { PayloadAction } from '@reduxjs/toolkit';

import { IndexSignature } from 'types/utils';

import { ProjectAppraisalState } from './index';

const setProjectAppraisals = (
  state: ProjectAppraisalState,
  { payload }: PayloadAction<IndexSignature<string>>,
): void => {
  state.projectAppraisals = {
    ...state.projectAppraisals,
    ...payload,
  };
};

export default {
  setProjectAppraisals,
};
