import { BaseService, ResponseData } from 'services/baseService';
import { User } from 'types/User';

class UserService extends BaseService<User> {
  controllerName = 'user';

  async getSelfUser(): Promise<ResponseData<User>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<User>>(`/${this.controllerName}/self`);
    return data;
  }
}

export const userService = new UserService();
