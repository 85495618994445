import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { departmentService } from 'services/departmentService';
import { setDepartmentsData, setDepartmentsRequestStatus } from 'store/departments';
import { AppThunk } from 'store/store';
import { fieldSorter } from 'utils/fieldSorter';
import { mapDepartmentModelToDepartments } from 'utils/parsers/departments';

export const getAllDepartments = (): AppThunk => async (dispatch) => {
  dispatch(setDepartmentsRequestStatus(RequestStatus.LOADING));
  try {
    const { count } = await departmentService.getAll();
    const opts: ServiceOptions = {
      limit: count,
    };
    const { data } = await departmentService.getAll(opts);
    const sortedDepartments = fieldSorter(data, 'name');
    const departments = mapDepartmentModelToDepartments(sortedDepartments);
    dispatch(setDepartmentsData(departments));
    dispatch(setDepartmentsRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setDepartmentsRequestStatus(RequestStatus.FAILED));
  }
};
