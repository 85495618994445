import React, { FC } from 'react';
import { Box, Grid, GridSize, GridSpacing } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

interface KSkeletonProps {
  amountOfElements?: number;
  columns?: number;
  elementHeight?: string;
  spacing?: number;
  type?: 'table' | 'card';
}

const KSkeleton: FC<KSkeletonProps> = ({
  amountOfElements,
  columns = 1,
  elementHeight,
  spacing,
  type,
}: KSkeletonProps) => {
  if (type) {
    const defaultAmountOfElements = type === 'card' ? 6 : 10;
    const defaultHeight = type === 'card' ? '300px' : '70px';
    const defaultSpacing = type === 'card' ? 3 : 1;
    return (
      <Grid
        container
        direction={type === 'card' ? 'row' : 'column'}
        spacing={(spacing || defaultSpacing) as GridSpacing}
      >
        {Array(amountOfElements || defaultAmountOfElements)
          .fill(null)
          .map((_, idx) => (
            <Grid item key={`Skeleton-${idx.toString()}`} xs={Math.floor(12 / columns) as GridSize}>
              <Box height={elementHeight || defaultHeight} width='100%'>
                <Skeleton animation='wave' height='100%' variant='rect' width='100%' />
              </Box>
            </Grid>
          ))}
      </Grid>
    );
  }
  const filterRowHeight = '50px';
  return <Skeleton animation='wave' height={elementHeight || filterRowHeight} variant='rect' width='100%' />;
};

export default KSkeleton;
