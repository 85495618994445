import React, { FC } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import clsx from 'clsx';

import styles from './FormSectionTitle.module.scss';

interface FormSectionTitleProps {
  bold?: boolean;
  optional?: boolean;
  text: string;
  tooltipText?: string;
}

export const FormSectionTitle: FC<FormSectionTitleProps> = React.memo(
  ({ bold, optional, text, tooltipText }: FormSectionTitleProps) => (
    <Box alignItems='center' display='inline-flex'>
      <Typography className={clsx(styles.title, bold && styles.bold)} component='h2'>
        {text} {optional && <span>(Optional)</span>}
      </Typography>
      {tooltipText && (
        <Tooltip
          classes={{ tooltip: styles.tooltip }}
          disableFocusListener
          disableTouchListener
          placement='top-start'
          title={tooltipText}
        >
          <Info className={styles.iconTooltip} color='secondary' />
        </Tooltip>
      )}
    </Box>
  ),
);
