import axios, { AxiosInstance } from 'axios';

import { RootState, store } from 'store/store';
import { Credentials } from 'types/Credentials/Credentials';
import { CredentialsModel } from 'types/Credentials/CredentialsModel';
import { IndexSignature } from 'types/utils';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_TIMEOUT: number = 600000 * 1.01;
export const BASE_HEADERS = {
  'Content-Type': 'application/json',
};

const selectSessionToken = (state: RootState) => state?.session?.credentials?.token;

export interface ResponseData<T> {
  data: T;
  message?: string;
}

class AuthService {
  controllerName = 'auth';

  baseUrl: string | undefined;

  apiTimeOut: number = API_TIMEOUT;

  apiInstance: AxiosInstance | undefined;

  get api() {
    if (this.apiInstance == null || this.baseUrl == null) {
      this.baseUrl = API_BASE_URL;
      if (this.baseUrl == null) {
        console.warn('Your REACT_APP_API_BASE_URL is not defined, make sure it is correctly set in .env');
      }
      this.apiInstance = axios.create({
        timeout: this.apiTimeOut,
        headers: BASE_HEADERS,
        baseURL: `${this.baseUrl}`,
      });
    }
    return this.apiInstance;
  }

  getDefaultHeaders(): IndexSignature<string> {
    const token =
      process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
        ? process.env.REACT_APP_DEVELOPMENT_TOKEN
        : selectSessionToken(store.getState());

    let defaultHeaders: IndexSignature<string> = {
      ...BASE_HEADERS,
    };
    if (token) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      };
    }
    return defaultHeaders;
  }

  async refresh(currentCredentials: Credentials): Promise<ResponseData<CredentialsModel>> {
    const { data } = await this.api.post<ResponseData<CredentialsModel>>(`/${this.controllerName}/refresh`, undefined, {
      headers: {
        ...BASE_HEADERS,
        Authorization: `Bearer ${currentCredentials.refreshToken.token}`,
      },
    });
    return data;
  }

  async login(email: string, password: string): Promise<ResponseData<CredentialsModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(
      `/emailauth/login`,
      JSON.stringify({
        email,
        password,
      }),
    );
    return data;
  }

  async logout(): Promise<ResponseData<CredentialsModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/${this.controllerName}/logout`);

    return data;
  }

  async forgot(email: string): Promise<ResponseData<string>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post(`/emailauth/reset`, { email });
    return data;
  }

  async reset(token: string, password: string): Promise<ResponseData<CredentialsModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post(`/emailauth/reset`, {
      token,
      password,
    });
    return data;
  }

  async exchange(token: string): Promise<ResponseData<CredentialsModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/${this.controllerName}/exchange`, undefined, {
      headers: {
        ...BASE_HEADERS,
        Authorization: `Bearer ${token}`,
      },
    });

    return data;
  }

  async resendConfirm(email: string): Promise<string> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/emailauth/resendconfirm`, { email });

    return data;
  }

  async registerEmailUser(
    name: string,
    email: string,
    password: string,
    locale?: string,
    timezone?: string,
  ): Promise<string> {
    this.api.defaults.headers = this.getDefaultHeaders();

    const { data } = await this.api.post(`/emailauth/register`, {
      name,
      email,
      password,
      locale,
      timezone,
    });

    return data;
  }

  async changeRoles(requestedRoles: number[]): Promise<ResponseData<CredentialsModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post(`/role/pif-change-roles`, { roleIds: requestedRoles });

    return data;
  }
}

export const authService = new AuthService();
