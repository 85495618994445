import React from 'react';
import { Box } from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({ value, index, children }) => (
  <div
    aria-labelledby={`scrollable-prevent-tab-${index}`}
    hidden={value !== index}
    id={`scrollable-prevent-tabpanel-${index}`}
    role='tabpanel'
  >
    {value === index && (
      <Box p={3} padding={0}>
        <span>{children}</span>
      </Box>
    )}
  </div>
);
