import React, { FC, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { AppBar, Button, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import clsx from 'clsx';

import styles from 'components/TopBar/TopBar.module.scss';
import { selectSessionRoles } from 'store/session/selectors';
import { getRoleLabel } from 'utils/getRoleLabel';
import { useAppSelector } from 'utils/hooks/storeHooks';

interface TopBarProps {
  actionLabel?: string;
  staticTitle?: string;
  onActionClick?: () => void;
  onClickBack?: () => void;
}

export const TopBar: FC<TopBarProps> = ({
  actionLabel = '',
  staticTitle = '',
  onActionClick,
  onClickBack,
}: TopBarProps) => {
  const history = useHistory();
  const roles = useAppSelector(selectSessionRoles);

  return (
    <AppBar elevation={0} position='sticky'>
      <Grid alignItems='center' className={styles.topBar} container direction='row' justifyContent='space-between'>
        <Grid className={styles.iconButtonContainer} item xs>
          <IconButton className={styles.iconButton} disableRipple onClick={onClickBack || history.goBack}>
            <ArrowBack className={styles.arrowBack} fontSize='medium' />
            <Typography className={clsx(styles.bold, styles.typographyInline)} component='h2'>
              {staticTitle}
            </Typography>
          </IconButton>
        </Grid>
        {onActionClick ? (
          <Fragment>
            <Grid className={styles.actionButtonContainer} item>
              <Button variant='contained' onClick={onActionClick}>
                {actionLabel}
              </Button>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <Grid className={styles.roleLabelContainer} item>
              <Typography variant='h3'>{getRoleLabel(roles)}</Typography>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </AppBar>
  );
};
