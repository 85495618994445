import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { TeamPosition } from 'types/Project/TeamPosition';

import { TeamPositionState } from './index';

const setAllTeamPositions = (state: TeamPositionState, action: PayloadAction<TeamPosition[]>): void => {
  state.allTeamPositions = action.payload;
};

const setProjectSubmitStatus = (state: TeamPositionState, action: PayloadAction<RequestStatus>): void => {
  state.projectSubmitStatus = action.payload;
};

const setTeamPositionCreationStatus = (state: TeamPositionState, action: PayloadAction<RequestStatus>): void => {
  state.teamPositionCreationStatus = action.payload;
};

const setTeamPositionDeleteStatus = (state: TeamPositionState, action: PayloadAction<RequestStatus>): void => {
  state.teamPositionDeleteStatus = action.payload;
};

const setTeamPositionsData = (state: TeamPositionState, action: PayloadAction<TeamPosition[]>): void => {
  state.teamPositions = action.payload;
};

const setTeamPositionsHaveEmployee = (state: TeamPositionState, action: PayloadAction<boolean>): void => {
  state.teamPositionsHaveEmployee = action.payload;
};

const setTeamPositionsStatus = (state: TeamPositionState, action: PayloadAction<RequestStatus>): void => {
  state.teamPositionsStatus = action.payload;
};

const setTeamPositionUpdateStatus = (state: TeamPositionState, action: PayloadAction<RequestStatus>): void => {
  state.teamPositionUpdateStatus = action.payload;
};

const setTotalMembers = (state: TeamPositionState, { payload }: PayloadAction<number>): void => {
  state.totalMembers = payload;
};

const reducers = {
  setAllTeamPositions,
  setProjectSubmitStatus,
  setTeamPositionCreationStatus,
  setTeamPositionDeleteStatus,
  setTeamPositionsData,
  setTeamPositionsHaveEmployee,
  setTeamPositionsStatus,
  setTeamPositionUpdateStatus,
  setTotalMembers,
};

export default reducers;
