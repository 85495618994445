import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  ButtonGroup,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { getIcon, KIcons } from 'assets/Icons';
import EmptyStateSection from 'components/EmptyStateSection/EmptyStateSection';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import KSkeleton from 'components/KSkeleton/KSkeleton';
import NotificationRow from 'components/NotificationRow/NotificationRow';
import { NotificationCategory } from 'constants/notificationCategory';
import { RequestStatus } from 'constants/requestStatus';
import { selectEmployeeData } from 'store/employee/selectors';
import { setStatusNotificationsRecord } from 'store/notifications';
import {
  selectIsPageLimit,
  selectRecordNotificationRequestStatus,
  selectRequestNotificationsRecord,
  selectStatusNotificationsDropdown,
  selectStatusNotificationsRecord,
} from 'store/notifications/selectors';
import {
  cleanRecords,
  getAllNotificationsRecord,
  getRequestNotificationsRecord,
  getStatusNotificationRecord,
  updateAllNotificationAsRead,
  updateNotificationAsRead,
} from 'store/notifications/thunks';
import { NotificationEmployee } from 'types/models/NotificationModel';
import { useAppSelector } from 'utils/hooks/storeHooks';

import styles from './Notification.module.scss';

enum RecordNotificationType {
  Mine = 'mine',
  MyTeam = 'myTeam',
}

const NotificationLayout: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentEmployee = useAppSelector(selectEmployeeData);
  const statusNotifications = useAppSelector(selectStatusNotificationsRecord);
  const requestNotifications = useAppSelector(selectRequestNotificationsRecord);
  const recordNotificationRequestStatus = useAppSelector(selectRecordNotificationRequestStatus);
  const isUnread = useAppSelector(selectStatusNotificationsDropdown).length;
  const isPageLimit = useAppSelector(selectIsPageLimit);

  const [notificationPage, setNotificationPage] = useState(0);
  const [notificationType, setNotificationType] = useState<RecordNotificationType>(RecordNotificationType.Mine);

  const handleClickRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationType(event.target.value as RecordNotificationType);
    setNotificationPage(0);
    dispatch(cleanRecords());
  };

  const handleClickOnNotificationRequest = async (isRead: boolean, subtype: string, timeOffRequestId: number) => {
    if (subtype === 'timeoff') {
      const url = isRead ? `/myteam/timeoffs/${timeOffRequestId}` : `/myteam/timeoffs`;
      history.push(url);
    } else if (subtype === 'timesheet') {
      history.push('/timesheets');
    }
  };

  const handleClickOnNotificationStatusButton = async (
    isRead: boolean,
    notificationId: number,
    subtype: string,
    timeOffRequestId: number,
  ) => {
    if (!isRead) dispatch(updateNotificationAsRead(notificationId));
    if (subtype === 'timeoff') {
      history.push(`/timeoff/${timeOffRequestId}`);
    } else if (subtype === 'timesheet') {
      history.push('/timesheets');
    }
  };

  const handleClickOnNotificationStatusIcon = (notificationId: number) => {
    dispatch(updateNotificationAsRead(notificationId));
    const statusNotifitionsUpdated = statusNotifications.map((notificationEmployee: NotificationEmployee) => {
      if (notificationEmployee.notificationId === notificationId) {
        return { ...notificationEmployee, notification: { ...notificationEmployee.notification, isRead: true } };
      }
      return notificationEmployee;
    });
    dispatch(setStatusNotificationsRecord(statusNotifitionsUpdated));
  };

  const handleClickOnMarkAllAsRead = () => {
    dispatch(updateAllNotificationAsRead());
    dispatch(getAllNotificationsRecord(statusNotifications.length, NotificationCategory.Status));
  };

  useEffect(
    () => () => {
      dispatch(cleanRecords());
    },
    [dispatch],
  );

  useEffect(() => {
    const onScroll = () => {
      const isPageBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 10;
      if (isPageBottom && !isPageLimit && recordNotificationRequestStatus === RequestStatus.SUCCESS) {
        setNotificationPage((prevNotificationPage) => prevNotificationPage + 1);
      }
    };
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isPageLimit, recordNotificationRequestStatus]);

  useEffect(() => {
    dispatch(
      notificationType === RecordNotificationType.Mine
        ? getStatusNotificationRecord(notificationPage)
        : getRequestNotificationsRecord(notificationPage),
    );
  }, [dispatch, notificationPage, notificationType]);

  const getNotificationRows = (category: RecordNotificationType) => {
    const notifications = category === RecordNotificationType.Mine ? statusNotifications : requestNotifications;
    return (
      <ButtonGroup className={styles.buttonGroup} fullWidth orientation='vertical' size='large'>
        {notifications.map(
          ({
            createdAt,
            notification: { description, isRead, subtype },
            notificationId,
            timeOffRequestId,
            timeOffRequest: {
              benefit: { iconName },
            },
          }) => {
            const defaultConfig = {
              creationDate: createdAt,
              description,
              icon: iconName as KIcons,
              isDropdown: false,
              isRead,
              key: `notificationRow-${notificationId}`.toString(),
              requiresAction: category === RecordNotificationType.MyTeam,
            };
            return category === RecordNotificationType.Mine ? (
              <NotificationRow
                {...defaultConfig}
                onClickButton={() =>
                  handleClickOnNotificationStatusButton(isRead, notificationId, subtype, timeOffRequestId)
                }
                onClickIcon={() => handleClickOnNotificationStatusIcon(notificationId)}
              />
            ) : (
              <NotificationRow
                {...defaultConfig}
                onClickButton={() => handleClickOnNotificationRequest(isRead, subtype, timeOffRequestId)}
              />
            );
          },
        )}
      </ButtonGroup>
    );
  };

  const getNotifications = (notificationRequestStatus: string) => {
    if (notificationRequestStatus === RequestStatus.FAILED) {
      return (
        <ErrorMessage
          subtitle="We're having trouble loading the info from the server. Check your connection and try again."
          title='Something went wrong'
        />
      );
    }
    if (
      notificationRequestStatus === RequestStatus.SUCCESS &&
      statusNotifications.length === 0 &&
      requestNotifications.length === 0
    ) {
      return <EmptyStateSection description='No Notifications to see yet' />;
    }
    return (
      <Box mt={3} px={25}>
        {getNotificationRows(notificationType)}
        {notificationRequestStatus === RequestStatus.LOADING && <KSkeleton amountOfElements={20} type='table' />}
      </Box>
    );
  };

  return (
    <Container className={styles.container} maxWidth='lg'>
      <Grid className={styles.titleContainer} container justifyContent='space-between'>
        <Grid item>
          <Box mt={6} my={3} width='100%'>
            <Typography className={styles.primaryTitle} color='textPrimary' variant='h5'>
              Notifications
            </Typography>
            {isUnread > 0 && notificationType === RecordNotificationType.Mine && (
              <Tooltip placement='top-end' title='Mark all as read'>
                <SvgIcon
                  className={styles.iconRead}
                  color='primary'
                  component={getIcon({ name: 'NotificationMarkRead', variant: 'default' })}
                  id='ReadIcon'
                  onClick={handleClickOnMarkAllAsRead}
                />
              </Tooltip>
            )}
          </Box>
        </Grid>
        {currentEmployee.isDepartmentManager && (
          <Grid item>
            <Box mt='45px' my={3} width='100%'>
              <RadioGroup
                aria-label='notification-options'
                defaultValue={RecordNotificationType.Mine}
                name='notification-radio-buttons'
                row
                value={notificationType}
                onChange={handleClickRadioButton}
              >
                <FormControlLabel
                  className={styles.radio}
                  control={<Radio />}
                  label='Mine'
                  value={RecordNotificationType.Mine}
                />
                <FormControlLabel
                  className={styles.radio}
                  control={<Radio />}
                  label={`My Team's`}
                  value={RecordNotificationType.MyTeam}
                />
              </RadioGroup>
            </Box>
          </Grid>
        )}
      </Grid>
      {getNotifications(recordNotificationRequestStatus)}
    </Container>
  );
};

export default NotificationLayout;
