import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import styles from 'modules/Project/components/EmptyState/EmptyState.module.scss';

interface EmptyStateProps {
  description?: string;
  image: string;
  message?: string;
}

const EmptyState: FC<EmptyStateProps> = ({ description, image, message }: EmptyStateProps) => (
  <Box alignItems='center' className={styles.emptyStateSection} display='flex' flexDirection='column' mb='56px'>
    <img alt='Empty State' src={image} />
    <Typography className={styles.description} variant='h1'>
      {description}
    </Typography>
    {message && <Typography className={styles.message}>{message}</Typography>}
  </Box>
);

export default EmptyState;
