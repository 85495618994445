export enum Rol {
  PROJECT_INITIATOR = 'Project Initiator',
  CO_CREATOR = 'Co-creator',
  CO_CREATOR_ENGINEERING = 'Co-creator Engineering',
  CO_CREATOR_SALESFORCE = 'Co-creator Salesforce',
  APPRAISER = 'Appraiser',
  ROLE_CHANGER = 'PIF Role Changer',
  // TODO: Change this role when product define he name
  CEO = 'CEO',
}
