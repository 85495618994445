import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { AccountCircle, KeyboardArrowDown } from '@material-ui/icons';
import clsx from 'clsx';

import styles from 'components/Header/Header.module.scss';
import RoleChangeMenu from 'components/RoleChangeMenu/RoleChangeMenu';
import { Rol } from 'constants/Credentials/Rol';
import { selectEmployeeData } from 'store/employee/selectors';
import { selectIsSessionLoading, selectSessionRoles } from 'store/session/selectors';
import { closeSession } from 'store/session/thunks';
import { getRoleLabel } from 'utils/getRoleLabel';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';

const Header: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const employee = useAppSelector(selectEmployeeData);
  const profileImage = employee.imageUrl;
  const roles = useAppSelector(selectSessionRoles);
  const isSessionLoading = useAppSelector(selectIsSessionLoading);

  const isRoleChanger = useMemo(() => hasRole(roles, Rol.ROLE_CHANGER), [roles]);

  const [sessionAnchor, setSessionAnchor] = useState<null | HTMLElement>(null);
  const [roleChangeAnchor, setRoleChangeAnchor] = useState<null | HTMLElement>(null);

  const handleOpenSessionMenu = (e: React.MouseEvent<HTMLButtonElement>) => setSessionAnchor(e.currentTarget);
  const handleCloseSessionMenu = () => {
    setSessionAnchor(null);
  };

  const handleSetRoleChangeMenu = (e: React.MouseEvent<HTMLButtonElement>) => setRoleChangeAnchor(e.currentTarget);

  const handleClearAllMenus = () => {
    setRoleChangeAnchor(null);
    setSessionAnchor(null);
  };

  const handleLogout = () => {
    dispatch(closeSession());
    history.push('/login');
  };

  return (
    <React.Fragment>
      <AppBar className={styles.appBar} elevation={0} position='sticky'>
        <Toolbar className={styles.toolbar} variant='dense'>
          <Grid className={styles.headerContainer} container>
            <Grid className={styles.headerText} item xs={3}>
              Project Initiation Form
            </Grid>
            <Grid alignItems='center' container item justifyContent='flex-end' md={3} spacing={1} wrap='nowrap' xs={4}>
              <Grid item>
                {isSessionLoading ? (
                  <CircularProgress className={styles.loader} color='inherit' size='2rem' />
                ) : (
                  <Typography variant='h3'>{getRoleLabel(roles)}</Typography>
                )}
              </Grid>
              <Grid item>
                <IconButton className={styles.iconButton} disableRipple id='Session' onClick={handleOpenSessionMenu}>
                  {profileImage === '' ? (
                    <AccountCircle className={clsx(styles.icon, styles.profilePic)} />
                  ) : (
                    <Avatar alt='avatar' className={styles.profilePic} src={profileImage} />
                  )}
                  <KeyboardArrowDown className={styles.iconDrop} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {isRoleChanger && <RoleChangeMenu roleChangeAnchor={roleChangeAnchor} onClearAllMenus={handleClearAllMenus} />}

      <Menu
        anchorEl={sessionAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={styles.myTeamMenu}
        classes={{ paper: clsx(styles.paper, styles.marginSession) }}
        getContentAnchorEl={null}
        id='Session'
        open={Boolean(sessionAnchor)}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCloseSessionMenu}
      >
        <MenuItem className={styles.sessionMenu}>
          {isRoleChanger && (
            <Button className={styles.logOut} onClick={handleSetRoleChangeMenu}>
              Change Role
            </Button>
          )}
        </MenuItem>
        <MenuItem className={styles.sessionMenu}>
          <Button className={styles.logOut} onClick={handleLogout}>
            Log Out
          </Button>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default Header;
