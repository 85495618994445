import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';

import styles from 'components/ErrorMessage/ErrorMessage.module.scss';

interface ErrorMessageProps {
  subtitle: string;
  title: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ title, subtitle }: ErrorMessageProps) => (
  <Box className={styles.emptyState} width='100%'>
    <Typography className={styles.failedStateText} component='p' variant='h1'>
      {title}
    </Typography>
    <Typography className={styles.failedStateTextSub} component='p' variant='body1'>
      {subtitle}
    </Typography>
  </Box>
);

export default ErrorMessage;
