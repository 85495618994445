import { createSlice } from '@reduxjs/toolkit';

import { ServiceOptions } from 'services/baseService';
import { WhereEnum } from 'types/Filter/Filter';
import { IndexSignature } from 'types/utils';

import reducers from './reducers';

export interface ProjectFilterState {
  checkedStatus: IndexSignature<boolean> | undefined;
  checkedUnits: IndexSignature<boolean> | undefined;
  currentPage: number;
  options: ServiceOptions;
  searchValue: string;
  whereValues: IndexSignature<WhereEnum>;
}

export const initialState: ProjectFilterState = {
  options: {
    limit: 10,
    order: [['createdAt', 'DESC']],
  },
  whereValues: {},
  checkedStatus: undefined,
  checkedUnits: undefined,
  searchValue: '',
  currentPage: 1,
};

export const filterProjectOptions = createSlice({
  name: 'projectsFilter',
  initialState,
  reducers,
});

export const {
  resetServiceOptions,
  setOrder,
  setOffset,
  setInclude,
  setLimit,
  setWhere,
  saveWhereQueryState,
  setCheckedProjectStatusValues,
  setCheckedUnits,
  setSearchValue,
  setProjectsCurrentPage,
} = filterProjectOptions.actions;

export default filterProjectOptions.reducer;
