import React from 'react';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

import styles from './CircleIconButton.module.scss';

interface AddButtonProps {
  className?: string;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => void;
}

export const CircleIconButton: React.FC<AddButtonProps> = ({ onClick, className, disabled, children }) => (
  <IconButton
    className={clsx(styles.circleIconButtonBase, styles.circleIconButtonSvg, className)}
    disabled={disabled ?? false}
    onClick={onClick}
  >
    {children}
  </IconButton>
);
