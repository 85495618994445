import { RootState } from 'store/store';

export const selectProjectFilters = (state: RootState) => state.projectFilters.options;

export const selectProjectsLimit = (state: RootState) => state.projectFilters.options.limit;

export const selectProjectWhereQueries = (state: RootState) => state.projectFilters.whereValues;

export const selectCheckedProjectStatus = (state: RootState) => state.projectFilters.checkedStatus;

export const selectedCheckedBusinessUnits = (state: RootState) => state.projectFilters.checkedUnits;

export const selectSearchValue = (state: RootState) => state.projectFilters.searchValue;

export const selectProjectsCurrentPage = (state: RootState) => state.projectFilters.currentPage;
