import { ServiceOptions } from 'services/baseService';
import { currencyService } from 'services/currency';
import { setCurrencyData, setCurrencyRequestStatus } from 'store/currency';
import { AppThunk } from 'store/store';
import { mapCurrencyModelToCurrency } from 'utils/parsers/currency';

export const getAllCurrency = (): AppThunk => async (dispatch) => {
  dispatch(setCurrencyRequestStatus('loading'));
  try {
    // TODO remove limit once backend removes duplicated data
    const opts: ServiceOptions = {
      limit: 4,
    };

    const { data } = await currencyService.getAll(opts);

    const currencies = mapCurrencyModelToCurrency(data);

    dispatch(setCurrencyData(currencies));
    dispatch(setCurrencyRequestStatus('success'));
  } catch (error) {
    dispatch(setCurrencyRequestStatus('failed'));
  }
};
