import React, { FC, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { PIFTooltip } from 'components/Tooltips/PIFTooltip';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { BillRateCell } from 'modules/Project/components/BillRateCell/BillRateCell';
import styles from 'modules/Project/components/TeamRosterTable/TeamRosterAppraiserRow/TeamRosterAppraiserRow.module.scss';
import { selectProjectDetailsData, selectprojectId } from 'store/projectInitiationForm/selectors';
import { getAllTeamPositionsByPIF, getTeamPositionsByPIF, updateTeamPositionBillRate } from 'store/teamPosition/thunks';
import { selectTeamRosterFilters } from 'store/teamRosterFilters/selectors';
import { TeamPosition } from 'types/Project/TeamPosition';
import { enFormatDate } from 'utils/formatters/date';
import { titleCase } from 'utils/formatters/text';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { useCurrency } from 'utils/hooks/useCurrency';
import { renderTooltip } from 'utils/tables/tooltips';

interface TeamRosterAppraiserRowProps {
  teamMember: TeamPosition;
}

export const TeamRosterAppraiserRow: FC<TeamRosterAppraiserRowProps> = ({
  teamMember,
}: TeamRosterAppraiserRowProps) => {
  const projectDetailsData = useAppSelector(selectProjectDetailsData);
  const filters = useAppSelector(selectTeamRosterFilters);
  const projectId = useAppSelector(selectprojectId) || NaN;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const currency = useCurrency(teamMember.currencyId);

  const ksquarianContainer = useRef<HTMLDivElement>(null);
  const positionContainer = useRef<HTMLDivElement>(null);
  const timeFrameContainer = useRef<HTMLDivElement>(null);

  const handleConfirm = (value: number) => {
    dispatch(
      updateTeamPositionBillRate(value, teamMember.id, {
        onSuccess: () => {
          enqueueSnackbar('Bill Rate has been edited!', SnackbarVariant.SUCCESS);
          dispatch(getTeamPositionsByPIF(projectId, filters));
          dispatch(getAllTeamPositionsByPIF(projectId));
        },
        onError: () =>
          enqueueSnackbar(
            'Unable to update Bill Rate due to internal server error. Try again, if the problem persists contact the IT team.',
            SnackbarVariant.ERROR,
          ),
      }),
    );
  };

  const ksquarian = teamMember.employee ? `${teamMember.employee.firstName} ${teamMember.employee.lastName}` : '';
  const timeFrame = `${enFormatDate(teamMember.startDate)} - ${enFormatDate(teamMember.endDate)}`;

  return (
    <TableRow>
      <TableCell className={styles.teamRosterRowCell} ref={ksquarianContainer}>
        <PIFTooltip kind='m' title={renderTooltip(ksquarianContainer, ksquarian)}>
          <span>{ksquarian}</span>
        </PIFTooltip>
      </TableCell>
      <TableCell className={styles.teamRosterRowCell} ref={positionContainer}>
        <PIFTooltip kind='m' title={renderTooltip(positionContainer, titleCase(teamMember.position?.name || ''))}>
          <span>{titleCase(teamMember.position?.name || '')}</span>
        </PIFTooltip>
      </TableCell>
      <TableCell className={clsx(styles.teamRosterRowCell)}>
        <span>
          <NumberFormat
            className={styles.currentValue}
            displayType='text'
            thousandSeparator
            value={teamMember.weeklyForecastedHours}
          />
        </span>
      </TableCell>
      <TableCell className={clsx(styles.teamRosterRowCell)}>
        <span>
          {currency?.currencySymbol ?? ''}
          <NumberFormat
            className={styles.currentValue}
            decimalScale={2}
            displayType='text'
            fixedDecimalScale
            thousandSeparator
            value={teamMember.costRate}
          />
        </span>
      </TableCell>
      <TableCell className={clsx(styles.teamRosterRowCell, styles.teamRosterRowCellBillRate)}>
        <BillRateCell
          billRateValue={teamMember.billRate}
          currencyId={teamMember.currencyId}
          projectStatus={projectDetailsData.creationStep}
          onConfirm={handleConfirm}
        />
      </TableCell>
      <TableCell className={styles.teamRosterRowCell} ref={timeFrameContainer}>
        <PIFTooltip kind='m' title={renderTooltip(timeFrameContainer, timeFrame)}>
          <span>{timeFrame}</span>
        </PIFTooltip>
      </TableCell>
      <TableCell className={clsx(styles.teamRosterRowCell)}>
        <span>
          <NumberFormat
            className={styles.currentValue}
            displayType='text'
            thousandSeparator
            value={teamMember.businessDaysBetweenDates}
          />
        </span>
      </TableCell>
    </TableRow>
  );
};
