import React, { ReactElement } from 'react';
import clsx from 'clsx';

import { KPaginationFooter } from 'components/KPaginationFooter/KPaginationFooter';
import { KPaginationHeader } from 'components/KPagintationHeader/KPaginationHeader';
import KTable, { KTableHeader, KTableRow } from 'components/KTable/KTable';

import styles from './PIFTable.module.scss';

interface PIFTableProps {
  EmptyStateComponent?: ReactElement;
  className?: string;
  currentPage: number;
  filterArray?: number[];
  headers: KTableHeader[];
  itemsPerPage: number;
  rows: KTableRow[];
  totalItems: number;
  typeShowed: string;
  onChangeItemsPerPage: (items: number) => void;
  onChangePage: (nextPage: number) => void;
}

export const PIFTable: React.FC<PIFTableProps> = ({
  EmptyStateComponent,
  className,
  currentPage,
  filterArray = [10, 20],
  headers,
  itemsPerPage,
  rows,
  totalItems,
  typeShowed,
  onChangeItemsPerPage,
  onChangePage,
}) => {
  const displayPaginationInfo = rows.length >= 11 || totalItems > itemsPerPage;
  return (
    <div className={clsx(styles.pifTable, className)}>
      <KPaginationHeader
        currentPage={currentPage}
        displayRowsPerPage={displayPaginationInfo}
        filterArray={filterArray}
        itemsPerPage={itemsPerPage}
        totalItems={totalItems}
        typePerPage='Rows'
        typeShowed={typeShowed}
        onChangeItemsPerPage={onChangeItemsPerPage}
      />
      <KTable EmptyStateComponent={EmptyStateComponent} headers={headers} rows={rows} />
      {displayPaginationInfo && (
        <KPaginationFooter
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          onChangePage={onChangePage}
        />
      )}
    </div>
  );
};
