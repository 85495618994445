import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import { FormikValues, getIn } from 'formik';
import noop from 'lodash';

import NoOptionsButton from 'modules/Project/components/NoOptionsButton/NoOptionsButton';
import styles from 'modules/Project/components/SelectAutocomplete/SelectAutoComplete.module.scss';
import { Option } from 'types/Form/FormCommons';
import { useRefDimensions } from 'utils/hooks/useRefDimensions';

interface SelectAutoCompleteProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  name: string;
  noOptionsText?: string;
  placeholder?: string;
  rowsFontClassName?: string;
  tags: Option<number | null>[];
  values: FormikValues;
  onChange?: (value: number) => void;
  onCreateItem?: (type: string, value: string) => void;
  onOpen?: (value: boolean) => void;
  setFieldValue: (field: string, value: number | null) => void;
}

export const SelectAutoComplete: FC<SelectAutoCompleteProps> = ({
  className,
  disabled,
  name,
  noOptionsText,
  label = '',
  placeholder,
  rowsFontClassName,
  tags,
  values,
  onCreateItem,
  onOpen,
  onChange,
  setFieldValue,
}: SelectAutoCompleteProps) => {
  const SELECT_LIMIT = 26;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);
  const [fixedPopover, setFixedPopover] = useState<boolean>(false);
  const [textfieldValue, setTextfieldValue] = useState<string>('');
  const selectContainer = useRef<HTMLDivElement>(null);
  const { width } = useRefDimensions(selectContainer);

  const isShrink = Boolean(anchorEl) || Boolean(!anchorEl && getIn(values, name));

  useEffect(() => {
    setIsAutoComplete(tags.length > SELECT_LIMIT);
  }, [tags]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!getIn(values, name) && onOpen) {
      onOpen(true);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_event: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEl) {
      anchorEl.focus();
    }
    if (!getIn(values, name) && onOpen) {
      onOpen(false);
    }

    setAnchorEl(null);
  };

  const getFieldText = () => {
    const inputText = tags.find((option) => option.value === getIn(values, name))?.label;
    return inputText !== undefined ? inputText : '';
  };

  const renderValue = () => () => {
    const inputText = tags.find((option) => option.value === getIn(values, name))?.label;
    if (inputText) {
      return <React.Fragment>{inputText}</React.Fragment>;
    }
    return <React.Fragment>{placeholder ?? ''}</React.Fragment>;
  };

  const handleAddNew = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
    if (onCreateItem) {
      onCreateItem('jobPosition', textfieldValue);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextfieldValue(e.target.value);
  };

  const renderAutocomplete = () => (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className={clsx(styles.borderShadow, styles.popper)}
      classes={{ paper: styles.paperContainer }}
      id={`menu-${label}`}
      open={Boolean(anchorEl)}
    >
      <Box className={className} width={width}>
        <Autocomplete
          classes={{
            listbox: clsx(
              styles.borderBottom,
              styles.listbox,
              styles.scrollbar,
              tags.length > 0 && styles.listboxExpanded,
            ),
            noOptions: styles.noOption,
            option: styles.option,
            paper: styles.paper,
            popperDisablePortal: styles.popperDisablePortal,
          }}
          debug={fixedPopover}
          disablePortal
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option, valueSelected) => option.value === valueSelected.value}
          noOptionsText={
            noOptionsText || <NoOptionsButton label={label} setter={setFixedPopover} onClickHandler={handleAddNew} />
          }
          open
          options={tags}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                endAdornment: null,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon className={styles.iconSearch} />
                  </InputAdornment>
                ),
                className: styles.input,
              }}
              autoFocus
              className={styles.inputBase}
              placeholder='Quick Find'
              ref={params.InputProps.ref}
              value={textfieldValue}
              variant='outlined'
              onChange={handleChange}
            />
          )}
          renderOption={(option) => <Typography className={styles.textOption}>{option.label}</Typography>}
          renderTags={() => null}
          onChange={(event, newValue) => {
            if (newValue) setFieldValue(name, newValue.value);
            if (onChange && newValue?.value) onChange(newValue.value);
          }}
          onClose={handleClose}
        />
      </Box>
    </Popover>
  );

  const renderSelect = () => (
    <Select
      IconComponent={KeyboardArrowDown}
      MenuProps={{
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        elevation: 0,
        getContentAnchorEl: null,
        MenuListProps: { disablePadding: true },
        PaperProps: { className: clsx(className, styles.borderShadow, styles.listbox, styles.scrollbar) },
      }}
      classes={{
        icon: styles.iconArrow,
        iconOpen: styles.iconArrowOpen,
        select: clsx(rowsFontClassName, styles.select),
      }}
      displayEmpty
      id={`menu-${label}`}
      input={
        label.length > 0 ? <OutlinedInput label={label} name={label} notched={!!getIn(values, name)} /> : undefined
      }
      labelId={`outlined-${label}`}
      renderValue={renderValue()}
      value={tags.map(({ value }) => value).includes(getIn(values, name)) ? getIn(values, name) : ''}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target;
        if (value) setFieldValue(name, value as number);
        if (onChange && value) onChange(value as number);
      }}
    >
      {tags.length > 0 ? (
        tags.map(({ label, value }) => (
          <MenuItem classes={{ root: styles.option, selected: styles.optionSelected }} key={value} value={value ?? 0}>
            <Typography className={styles.textOption}>{label}</Typography>
          </MenuItem>
        ))
      ) : (
        <MenuItem
          classes={{ root: styles.option, selected: styles.optionSelected }}
          disabled
          key={`empty-options-${name}`}
          value={0}
        >
          <Typography className={styles.textOption}>{noOptionsText}</Typography>
        </MenuItem>
      )}
    </Select>
  );

  return (
    <React.Fragment>
      <FormControl className={styles.selectInputContainer} innerRef={selectContainer} variant='outlined'>
        {label.length > 0 && (
          <InputLabel className={styles.label} htmlFor={`outlined-${label}`} id={`outlined-${label}`} shrink={isShrink}>
            {label}
          </InputLabel>
        )}
        {isAutoComplete ? (
          <OutlinedInput
            className={styles.selectInput}
            classes={{ notchedOutline: styles.outlinedBorder, root: styles.selectInputBaseRoot }}
            disabled={disabled}
            endAdornment={
              !disabled && (
                <InputAdornment className={styles.iconArrowContainer} position='end'>
                  <KeyboardArrowDown className={styles.iconArrow} />
                </InputAdornment>
              )
            }
            id={`outlined-${label}`}
            inputProps={{
              className: clsx(rowsFontClassName, disabled ? styles.textFieldDisabled : styles.textField),
              placeholder,
            }}
            label={label}
            name={name}
            notched={label.length > 0 && isShrink}
            readOnly
            value={getFieldText()}
            onClick={disabled ? noop : handleClick}
          />
        ) : (
          renderSelect()
        )}
      </FormControl>
      {isAutoComplete && renderAutocomplete()}
    </React.Fragment>
  );
};
