import { RootState } from 'store/store';

export const selectRequestNotifications = (state: RootState) => state.notifications.requestNotifications;

export const selectRequestNotificationsDropdown = (state: RootState) =>
  state.notifications.requestNotificationsDropdown;
export const selectStatusNotificationsDropdown = (state: RootState) => state.notifications.statusNotificationsDropdown;

export const selectRequestNotificationsRecord = (state: RootState) => state.notifications.requestNotificationsRecord;
export const selectStatusNotificationsRecord = (state: RootState) => state.notifications.statusNotificationsRecord;

export const selectDropdownNotificationRequestStatus = (state: RootState) => state.notifications.dropdownRequestStatus;
export const selectRecordNotificationRequestStatus = (state: RootState) => state.notifications.recordRequestStatus;
export const selectNotificationsUpdateStatus = (state: RootState) => state.notifications.updateStatus;

export const selectOffsetIncrement = (state: RootState) => state.notifications.offsetIncrement;
export const selectIsPageLimit = (state: RootState) => state.notifications.isPageLimit;
