export enum BusinessUnit {
  DIGITAL = 'Digital',
  ITK = 'ITK',
  KSQUARE_EXPRESS = 'Ksquare Express',
  SALESFORCE = 'Salesforce',
  UX = 'Ux',
}

export enum ProjectStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  APPRAISING = 'appraising',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum ProjectAppraisalStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum ProjectType {
  'billable project',
  'bench',
  'research and discovery',
  'pre sales',
  'management',
  'itk',
}
