import { Role } from 'types/Project/Role';

export const roleChangeOptions = ['Project Initiator', 'Co-creator', 'Appraiser'];

const formatRoleIdsArray = (roleNames: string[], rolesLookup: Role[]): number[] =>
  rolesLookup.reduce<number[]>((curr, el): number[] => {
    if (roleNames.includes(el.name)) {
      return [...curr, el.id];
    }
    return curr;
  }, []);

export const getRoleChangeRequest = (reqRole: string, rolesLookup: Role[]): number[] => {
  const baseRoles = [reqRole];

  return formatRoleIdsArray(baseRoles, rolesLookup);
};
