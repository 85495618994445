import React, { FC } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import styles from './DashboardHelperText.module.scss';

interface HelperTextProps {
  description: string;
  onClick: () => void;
}

export const DashboardHelperText: FC<HelperTextProps> = ({ description, onClick }: HelperTextProps) => (
  <div className={styles.helperContainer}>
    <span className={styles.helperContainer__description}>{description}</span>
    <IconButton aria-label='close-helper-text' className={styles.helperContainer__icon} onClick={onClick}>
      <CloseIcon />
    </IconButton>
  </div>
);
