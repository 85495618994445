import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import styles from './KPaginationFooter.module.scss';

interface KPaginationFooterProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
  onChangePage: (page: number) => void;
}

export const KPaginationFooter: React.FC<KPaginationFooterProps> = ({
  totalItems,
  currentPage,
  itemsPerPage,
  onChangePage,
}) => {
  const [totalPages, updateTotalPages] = useState<number>(0);

  useEffect(() => {
    updateTotalPages(Math.ceil(totalItems / itemsPerPage));
  }, [totalItems, itemsPerPage]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  return (
    <Box className={styles.paginationFooter}>
      <Box className={styles.containerDisplayedItems}>
        <Pagination
          classes={{ ul: styles.pagination }}
          color='primary'
          count={totalPages}
          page={currentPage}
          shape='rounded'
          showFirstButton
          showLastButton
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};
