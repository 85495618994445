import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { TechnologyType } from 'types/Project/TechnologyType';

import reducers from './reducers';

export interface TechnologyTypesState {
  technologyTypes: TechnologyType[];
  technologyTypesRequestStatus: RequestStatus;
}

const initialState: TechnologyTypesState = {
  technologyTypes: [],
  technologyTypesRequestStatus: RequestStatus.LOADING,
};

export const technologyTypesSlice = createSlice({
  name: 'technologyTypes',
  initialState,
  reducers,
});

export const { setTechnologyTypesData, setTechnologyTypesRequestStatus } = technologyTypesSlice.actions;

export default technologyTypesSlice.reducer;
