import React, { useContext, useEffect, useState } from 'react';
import { AnchorContext } from 'context/AnchorProvider';
import { FormikProps } from 'formik';
import { noop } from 'lodash';

import { KDialogSmall } from 'components/Dialogs/KDialogSmall/KDialogSmall';
import { KDialogWithChildren } from 'components/Dialogs/KDialogWithChildren/KDialogWithChildren';
import { KDialogActionKind } from 'components/Dialogs/types';
import { KTableRow } from 'components/KTable/KTable';
import { EditRowMenu } from 'components/Menus/EditRowMenu/EditRowMenu';
import { SortByNameMenu } from 'components/Menus/SortByNameMenu/SortByNameMenu';
import { PIFTable } from 'components/PIFTable/PIFTable';
import { Rol } from 'constants/Credentials/Rol';
import { DefaultPermissions } from 'constants/defaultPermissions';
import { TeamPositionDetails } from 'modules/Project/components/TeamPositionDetails/TeamPositionDetails';
import { TeamRosterRow } from 'modules/Project/components/TeamRosterTable/TeamRosterRow/TeamRosterRow';
import { selectProjectDetailsData } from 'store/projectInitiationForm/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import { Permissions } from 'types/Permissions';
import { PIFProject } from 'types/PIFProject';
import { TeamPosition } from 'types/Project/TeamPosition';
import { checkTeamPermissions } from 'utils/checkTeamPermissions';
import { exists } from 'utils/generators/generators';
import { useAppSelector } from 'utils/hooks/storeHooks';
import { hasRole } from 'utils/roles';
import { AbstractHeader, AbstractTableProps, asHeader, HeaderMenuCallback, rowsOf } from 'utils/tables/tableUtils';

interface TeamRosterTableProps extends AbstractTableProps<TeamPosition> {
  formik: FormikProps<PIFProject>;
}

enum MenuAnchors {
  JOB_POSITION = 'jobPosition',
  TECH_SET = 'techSet',
  EDIT_ROW = 'editRow',
}

export const TeamRosterTable: React.FC<TeamRosterTableProps> = ({
  formik,
  data: teamMembers,
  currentPage,
  itemsPerPage,
  totalItems,
  onDelete = noop,
  onEdit = noop,
  onPaginationChange,
  onColumnSort = noop,
  onItemsPerPageChange,
}) => {
  const roles = useAppSelector(selectSessionRoles);
  const { creationStep } = useAppSelector(selectProjectDetailsData);
  const [teamMemberId, updateTeamMemberId] = useState<undefined | string>(undefined);
  const [columnName, updateColumnName] = useState<string | string[] | undefined>(undefined);
  const [isDeleteDialogOpen, updateDeleteDialogOpen] = useState(false);
  const { anchorStatus, showMenu, closeMenu } = useContext(AnchorContext);
  const [isTeamPositionViewDialogOpen, setIsTeamPositionViewDialogOpen] = useState<boolean>(false);
  const [teamPositionView, setTeamPositionView] = useState({});
  const [currentPermissions, setCurrentPermissions] = useState<Permissions>(DefaultPermissions);

  useEffect(() => {
    if (roles && creationStep) {
      setCurrentPermissions(checkTeamPermissions(roles, creationStep));
    }
  }, [roles, creationStep]);

  const createMenuDispatch =
    (anchorName: string): HeaderMenuCallback =>
    (anchor, selectedColumnName) => {
      showMenu(anchorName, anchor);
      updateColumnName(selectedColumnName);
    };

  const projectTableAbstractHeaders: AbstractHeader[] = [
    {
      id: '1',
      label: '',
      style: { width: '72px' },
    },
    {
      id: '2',
      label: 'Position',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.JOB_POSITION]),
        onClick: createMenuDispatch(MenuAnchors.JOB_POSITION),
        columnFieldName: 'position.name',
      },
      style: { width: '192px' },
    },
    {
      id: '3',
      label: hasRole(roles, Rol.PROJECT_INITIATOR) || hasRole(roles, Rol.CO_CREATOR) ? 'Ksquarian' : '',
      style: { width: '220px' },
    },
    {
      id: '4',
      label: 'Tech Set',
      sortConfig: {
        isExpanded: exists(anchorStatus[MenuAnchors.TECH_SET]),
        onClick: createMenuDispatch(MenuAnchors.TECH_SET),
        columnFieldName: 'technologies.name',
      },
      style: { width: '192px' },
    },
    {
      id: '5',
      label: 'More',
      style: { width: '84px' },
    },
  ];

  const handleClickMoreMenu = (event: React.MouseEvent, teamMemberId: string): void => {
    updateTeamMemberId(teamMemberId);
    showMenu(MenuAnchors.EDIT_ROW, event.currentTarget);
  };

  const handleClickView = (teamPosition: TeamPosition): void => {
    setIsTeamPositionViewDialogOpen(true);
    setTeamPositionView(teamPosition);
  };

  const asTeamMemberRow = (teamMember: TeamPosition): KTableRow => ({
    sectionRows: [
      <TeamRosterRow
        formik={formik}
        key={teamMember.id}
        teamMember={teamMember}
        onClickMenu={handleClickMoreMenu}
        onClickView={handleClickView}
      />,
    ],
    rowId: teamMember.id.toString(),
  });

  const handleChangeItemsPerPage = (items: number): void => {
    onItemsPerPageChange(items);
  };

  const handleChangePage = (nextPage: number): void => {
    onPaginationChange(nextPage);
  };

  const handleCloseEditMenu = () => {
    closeMenu(MenuAnchors.EDIT_ROW);
  };

  const handleClickRowEdit = (): void => {
    if (teamMemberId) {
      onEdit(teamMemberId);
    }
  };

  const handleClickRowDelete = (): void => {
    if (teamMemberId) {
      updateDeleteDialogOpen(true);
    }
  };

  const handleCloseDeleteDialog = (): void => {
    updateDeleteDialogOpen(false);
    updateTeamMemberId(undefined);
  };

  const confirmDeleteTeamMember = (): void => {
    if (teamMemberId) {
      onDelete(teamMemberId);
    }
    handleCloseDeleteDialog();
  };

  const handleCloseTeamPositionViewDialog = (): void => {
    setIsTeamPositionViewDialogOpen(false);
  };

  return (
    <React.Fragment>
      <PIFTable
        currentPage={currentPage}
        headers={projectTableAbstractHeaders.map(asHeader)}
        itemsPerPage={itemsPerPage}
        rows={rowsOf(teamMembers, asTeamMemberRow)}
        totalItems={totalItems}
        typeShowed='job position'
        onChangeItemsPerPage={handleChangeItemsPerPage}
        onChangePage={handleChangePage}
      />
      <SortByNameMenu
        anchorEl={anchorStatus[MenuAnchors.JOB_POSITION]}
        id='projectsSortPositionMenu'
        onClose={() => closeMenu(MenuAnchors.JOB_POSITION)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
      <SortByNameMenu
        anchorEl={anchorStatus[MenuAnchors.TECH_SET]}
        id='projectsSortTechSetMenu'
        onClose={() => closeMenu(MenuAnchors.TECH_SET)}
        onSelected={(sortType) => {
          onColumnSort(columnName, sortType);
        }}
      />
      <EditRowMenu
        anchorEl={anchorStatus[MenuAnchors.EDIT_ROW]}
        id='projectsEditRowMenu'
        permissions={currentPermissions}
        onClose={() => handleCloseEditMenu()}
        onDelete={() => handleClickRowDelete()}
        onEdit={() => handleClickRowEdit()}
      />
      <KDialogSmall
        actions={[
          {
            label: 'Cancel',
            kind: KDialogActionKind.CANCEL,
            onClick: () => handleCloseDeleteDialog(),
          },
          {
            label: 'Delete',
            kind: KDialogActionKind.CAUTION,
            onClick: () => confirmDeleteTeamMember(),
          },
        ]}
        isOpen={isDeleteDialogOpen}
        message='By doing this, the job position will be permanently removed.'
        title='Delete Job Position?'
        onClose={() => handleCloseDeleteDialog()}
      />
      <KDialogWithChildren
        actions={[
          {
            label: 'Close',
            kind: KDialogActionKind.PRIMARY,
            onClick: () => handleCloseTeamPositionViewDialog(),
          },
        ]}
        isLarge
        isOpen={isTeamPositionViewDialogOpen}
        title='View'
        onClose={() => null}
      >
        <TeamPositionDetails teamMember={teamPositionView as TeamPosition} />
      </KDialogWithChildren>
    </React.Fragment>
  );
};
