import React from 'react';

import Loader from 'components/Loader/Loader';
import { selectIsRefreshing } from 'store/session/selectors';
import { useAppSelector } from 'utils/hooks/storeHooks';

const withSplashScreen = <P extends Record<string, unknown>>(Component: React.ComponentType<P>) =>
  function WithWrapper(props: P) {
    const status = useAppSelector(selectIsRefreshing);
    return status ? <Loader show /> : <Component {...props} />;
  };

export default withSplashScreen;
