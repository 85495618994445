import React, { CSSProperties, FC, ReactElement } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowTypeMap,
  Typography,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { ExpandableArrow } from 'components/Expandable/ExpandableArrow/ExpandableArrow';
import { HeaderSortConfiguration } from 'utils/tables/tableUtils';

import styles from './KTable.module.scss';

export type KTableHeader = {
  align: string;
  id: string;
  label: string;
  sortConfig?: HeaderSortConfiguration;
  style?: CSSProperties;
};

export type KTableRow = {
  rowId: string;
  section?: string;
  sectionRows: ReactElement<TableRowTypeMap>[];
};

interface KTableProps {
  EmptyStateComponent?: ReactElement;
  headers: KTableHeader[];
  rows: KTableRow[];
}

const KTable: FC<KTableProps> = ({ headers, rows, EmptyStateComponent }: KTableProps) => (
  <TableContainer className={styles.tableContainer} component={Paper}>
    <Table>
      <TableHead className={styles.headerContainer}>
        <TableRow>
          {headers.map(({ id, sortConfig, align, label, style }: KTableHeader) => (
            <TableCell
              className={clsx(styles.headerCell, styles[`${align}`])}
              key={`table-${id}-column`}
              style={style}
              onClick={(e) => sortConfig && sortConfig.onClick(e.currentTarget, sortConfig.columnFieldName)}
            >
              <div
                className={clsx(sortConfig && styles.headerCellContent, sortConfig?.isExpanded && styles.expandedMenu)}
              >
                {label}
                {sortConfig && <ExpandableArrow isExpanded={sortConfig.isExpanded} />}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      {rows.map(({ rowId, section, sectionRows }) => (
        <TableBody key={rowId}>
          {!isEmpty(section) && (
            <TableRow>
              <TableCell className={styles.section} colSpan={headers.length}>
                <Typography>{section}</Typography>
              </TableCell>
            </TableRow>
          )}
          {sectionRows}
        </TableBody>
      ))}
    </Table>
    {rows.length === 0 && EmptyStateComponent}
  </TableContainer>
);

export default KTable;
