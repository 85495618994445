import React from 'react';
import { IconButton, TableCell } from '@material-ui/core';
import clsx from 'clsx';

import { ExpandableArrow } from 'components/Expandable/ExpandableArrow/ExpandableArrow';
import { ExpandableCommonProps } from 'components/Expandable/types';

import styles from './ExpandableCell.module.scss';

interface ExpandableCellProps {
  children: React.ReactElement;
  className: string;
  isExpandable: boolean;
  onClick: () => void;
}

export const ExpandableCell = React.forwardRef<HTMLDivElement, ExpandableCellProps & ExpandableCommonProps>(
  ({ isExpanded, children, className, onClick, isExpandable }, ref) =>
    isExpandable ? (
      <TableCell className={clsx(styles.expandableCell, className)} ref={ref}>
        <div className={styles.expandableCellContent}>
          <IconButton onClick={() => onClick()}>
            <ExpandableArrow isExpanded={isExpanded} />
          </IconButton>
          {children}
        </div>
      </TableCell>
    ) : (
      <TableCell className={className} ref={ref}>
        {children}
      </TableCell>
    ),
);
