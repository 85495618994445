import { RootState } from 'store/store';

export const selectAllTeamPositions = (state: RootState) => state.teamPosition.allTeamPositions;
export const selectProjectSubmitStatus = (state: RootState) => state.teamPosition.projectSubmitStatus;
export const selectTeamPositionCreationStatus = (state: RootState) => state.teamPosition.teamPositionCreationStatus;
export const selectTeamPositionDeleteStatus = (state: RootState) => state.teamPosition.teamPositionDeleteStatus;
export const selectTeamPositions = (state: RootState) => state.teamPosition.teamPositions;
export const selectTeamPositionsHaveEmployee = (state: RootState) => state.teamPosition.teamPositionsHaveEmployee;
export const selectTeamPositionsStatus = (state: RootState) => state.teamPosition.teamPositionsStatus;
export const selectTeamPositionUpdateStatus = (state: RootState) => state.teamPosition.teamPositionUpdateStatus;
export const selectTotalMembers = (state: RootState) => state.teamPosition.totalMembers;
