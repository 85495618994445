import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, Input, InputLabel, SvgIcon, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';

import { ReactComponent as uploadIcon } from 'assets/images/upload.svg';

import styles from './UploadFile.module.scss';

interface UploadFileProps {
  id?: string;
  isDisabled?: boolean;
  uploadFile: File[];
  onChange: (_files: File[] | FileList) => void;
}

const UploadFile: FC<UploadFileProps> = ({ isDisabled = false, uploadFile, onChange, id }: UploadFileProps) => {
  const [files, loadFiles] = useState<File[]>(uploadFile);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files || [];
    loadFiles(Array.from(files));
    onChange(files);
  };

  const handleClear = () => {
    loadFiles([]);
    onChange([]);
  };

  useEffect(() => {
    loadFiles(uploadFile);
  }, [uploadFile]);

  const fileNames = (files: File[]) => files[0].name;

  const classes = () => {
    if (isDisabled) {
      return styles.attachBoxDisabled;
    }

    if (files.length) {
      return clsx(styles.attachBox, styles.uploadedBox);
    }

    return styles.attachBox;
  };

  return (
    <Box className={classes()}>
      {files.length ? (
        <Box className={styles.uploadedFileBox} display='flex' width='100%'>
          <Typography className={clsx(styles.attachText, styles.uploadedText)}>{fileNames(files)}</Typography>
          <IconButton aria-label='upload picture' className={styles.iconClear} component='span' onClick={handleClear}>
            <ClearIcon color='secondary' />
          </IconButton>
        </Box>
      ) : (
        <Fragment>
          <InputLabel className={styles.label} disabled={isDisabled} htmlFor={id || 'multi'}>
            <Box alignItems='center' display='flex' justifyContent='space-between' width='100%'>
              <Typography className={isDisabled ? styles.attachTextDisabled : styles.attachText}>
                Attach File
              </Typography>
              <IconButton
                aria-label='upload picture'
                className={styles.uploadIcon}
                color='primary'
                component='span'
                disabled={isDisabled}
              >
                <SvgIcon className={isDisabled ? styles.fillDisabled : undefined} component={uploadIcon} />
              </IconButton>
            </Box>
          </InputLabel>
          <Input
            className={styles.inputFile}
            disabled={isDisabled}
            id={id || 'multi'}
            inputProps={{ accept: '.jpg, .pdf, .png', multiple: true }}
            type='file'
            onChange={handleChange}
          />
        </Fragment>
      )}
    </Box>
  );
};

export default UploadFile;
