import { Collapse, makeStyles, MenuProps } from '@material-ui/core';

const createTableMenuStyleMaker = (width: number) =>
  makeStyles({
    paper: {
      width: width || 'auto',
    },
  });

export const paperStylesOf = (width: number | undefined): string => {
  const createStyles = createTableMenuStyleMaker(width as number);
  const { paper } = createStyles();
  return paper;
};

export const commonTableMenuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  transformOrigin: { vertical: 'top', horizontal: 'center' },
  TransitionComponent: Collapse,
};
