import React from 'react';
import { Container } from '@material-ui/core';

import { SKELETON_ROWS_FALLBACK } from 'constants/ProjectDashboard/dashboardConstants';

import { DashboardHeadingSkeleton } from './DashboardHeadingSkeleton/DashboardHeadingSkeleton';
import { SearchSkeleton } from './SearchSkeleton/SearchSkeleton';
import { TableSkeleton } from './TableSkeleton/TableSkeleton';

import styles from './ProjectDashboardSkeleton.module.scss';

export const ProjectDashboardSkeleton: React.FC = () => (
  <Container className={styles.projectDashboardSkeleton} maxWidth={false}>
    <DashboardHeadingSkeleton />
    <SearchSkeleton />
    <TableSkeleton skeletonRows={SKELETON_ROWS_FALLBACK} />
  </Container>
);
