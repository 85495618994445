import { noop } from 'lodash';

import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { technologyService } from 'services/technologyService';
import { AppThunk } from 'store/store';
import { setTechnologiesData, setTechnologiesRequestStatus } from 'store/technologies';
import { DispatchStatusCallbacks } from 'types/Dispatch/Dispatch';
import { TechnologyModel } from 'types/models/TechnologyModel';
import { fieldSorter } from 'utils/fieldSorter';
import { mapTechnologyModelToTechnologies } from 'utils/parsers/technologies';

export const getAllTechnologies = (): AppThunk => async (dispatch) => {
  dispatch(setTechnologiesRequestStatus(RequestStatus.LOADING));
  try {
    const { count } = await technologyService.getAll();
    const opts: ServiceOptions = {
      limit: count,
    };
    const { data } = await technologyService.getAll(opts);
    const sortedTechnologies = fieldSorter(data, 'name');

    const technologies = mapTechnologyModelToTechnologies(sortedTechnologies);
    dispatch(setTechnologiesData(technologies));
    dispatch(setTechnologiesRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setTechnologiesRequestStatus(RequestStatus.FAILED));
  }
};

export const createTechnology =
  (technologyData: TechnologyModel, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setTechnologiesRequestStatus(RequestStatus.LOADING));
    try {
      const {
        data: { name },
      } = await technologyService.create(technologyData);
      dispatch(setTechnologiesRequestStatus(RequestStatus.SUCCESS));
      onSuccess(name);
    } catch (error) {
      dispatch(setTechnologiesRequestStatus(RequestStatus.FAILED));
      onError();
    }
  };
