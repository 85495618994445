export enum BudgetValidations {
  MIN_VALUE = 0,
  MAX_VALUE = 1000000,
}

export enum BillRateValidations {
  MIN_VALUE = 0,
  MAX_VALUE = 1000000,
}

export enum TechnologiesValidations {
  MIN_VALUE = 1,
}

export enum WeeklyForecastValidations {
  MIN_VALUE = 0,
  MAX_VALUE = 40,
}
