/* eslint-disable @typescript-eslint/no-explicit-any */
interface StringIndex {
  [key: string]: any;
}

export function fieldSorter<T extends StringIndex>(
  data: Array<T>,
  criteria: string,
  optionalCriteria?: string,
): Array<T> {
  if (optionalCriteria) {
    return [...data].sort(
      (a: T, b: T) =>
        a[criteria].toUpperCase().localeCompare(b[criteria].toUpperCase()) ||
        a[optionalCriteria].toUpperCase().localeCompare(b[optionalCriteria].toUpperCase()),
    );
  }
  return [...data].sort((a: T, b: T) => (a[criteria].toUpperCase() > b[criteria].toUpperCase() ? 1 : -1));
}
