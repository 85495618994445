import { enFormatDate } from './date';

const getPassedTimeText = (date: string) => {
  if (date.length > 0) {
    const passedTime = new Date().getTime() - new Date(date).getTime();
    if (passedTime / 86400000 < 1) {
      if (passedTime / 3600000 < 1) {
        return 'less than 1 hour';
      }
      return `${Math.floor(passedTime / 3600000)} hours ago`;
    }
    return enFormatDate(new Date(date));
  }
  return 'No available date';
};

export default getPassedTimeText;
