import React from 'react';
import { camelCase } from 'lodash';

import { PIFTooltip, TooltipKind } from 'components/Tooltips/PIFTooltip';
import { StatusProps } from 'modules/Project/components/Status/types';
import { ProjectStatus } from 'types/Project';
import { IndexSignature } from 'types/utils';

import styles from './StatusCell.module.scss';

interface TooltipMetadata {
  kind: TooltipKind;
  title: React.ReactElement;
}

const TOOLTIP_METADATA: IndexSignature<TooltipMetadata> = {
  [ProjectStatus.APPRAISING]: {
    kind: 'm',
    title: (
      <React.Fragment>
        A project that is waiting for the <i>Head of Finance</i> to be Appraised.
      </React.Fragment>
    ),
  },
  [ProjectStatus.APPROVED]: {
    kind: 'm',
    title: (
      <React.Fragment>
        Represents that a project is viable and was Approved by <i> Finance </i>.
      </React.Fragment>
    ),
  },
  [ProjectStatus.DRAFT]: {
    kind: 'lg',
    title: (
      <React.Fragment>
        A project made by <i>PMO or Sales</i> that hasn’t been finished or Submitted.
      </React.Fragment>
    ),
  },
  [ProjectStatus.PENDING]: {
    kind: 'xl',
    title: (
      <React.Fragment>
        A project that is waiting for the <i>Head of Engineering or Director of Salesforce Mexico</i> to Review it.
      </React.Fragment>
    ),
  },
  [ProjectStatus.REJECTED]: {
    kind: 'xl',
    title: (
      <React.Fragment>
        Represents that a project is not viable and was Rejected either by{' '}
        <i>Head of Engineering, Director of Salesforce Mexico</i> or <i>Finance</i>.
      </React.Fragment>
    ),
  },
};

interface StatusCellProps {
  withTooltip?: boolean;
}

export const StatusCell: React.FC<StatusProps & StatusCellProps> = ({ status, withTooltip = true }) => {
  const { kind, title } = TOOLTIP_METADATA[status];

  return TOOLTIP_METADATA[status] && withTooltip ? (
    <PIFTooltip kind={kind} title={title}>
      <div className={styles[camelCase(status)]}>{status}</div>
    </PIFTooltip>
  ) : (
    <div className={styles[camelCase(status)]}>{status}</div>
  );
};
