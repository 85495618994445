import { BaseService, ResponseData } from 'services/baseService';
import { BaseModel } from 'types/models/baseModel';
import { NewProjectReview } from 'types/Project/NewProjectReview';
import { ProjectCost } from 'types/Project/ProjectCost';
import { ProjectModel } from 'types/Project/ProjectModel';

class ProjectInitiationFormService extends BaseService<ProjectModel> {
  controllerName = 'pif';

  async submitReview(projectReview: NewProjectReview): Promise<ResponseData<BaseModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post<ResponseData<BaseModel>>(`/${this.controllerName}/review`, {
      ...projectReview,
    });
    return data;
  }

  async submitAppraise(projectAppraise: NewProjectReview): Promise<ResponseData<BaseModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post<ResponseData<BaseModel>>(`/${this.controllerName}/submit`, {
      ...projectAppraise,
    });
    return data;
  }

  async getCostById(id: string | number): Promise<ResponseData<ProjectCost>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.get<ResponseData<ProjectCost>>(`/${this.controllerName}/${id}/cost`);
    return data;
  }
}

export const projectInitiationFormService = new ProjectInitiationFormService();
