import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { SKELETON_ROWS_FALLBACK } from 'constants/ProjectDashboard/dashboardConstants';

import styles from './TableSkeleton.module.scss';

interface TableSkeletonProps {
  skeletonRows?: number;
}

export const TableSkeleton: React.FC<TableSkeletonProps> = ({ skeletonRows }) => (
  <Container className={styles.tableSkeleton} maxWidth={false}>
    <Grid className={styles.paginationHeaderSkeleton}>
      <Skeleton height='16px' variant='rect' width='154px' />
    </Grid>
    <Grid className={styles.rowsSkeleton}>
      {Array(Math.floor(skeletonRows || SKELETON_ROWS_FALLBACK))
        .fill(null)
        .map((_, idx) => (
          <Box height='48px' key={idx.toString()} width='100%'>
            {idx % 2 === 0 && <Skeleton height='48px' variant='rect' width='100%' />}
          </Box>
        ))}
    </Grid>
    <Grid className={styles.paginationFooterSkeleton}>
      <Skeleton height='32px' variant='rect' width='370px' />
    </Grid>
  </Container>
);
