import React from 'react';
import { Button, Dialog, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { KDialogActionKind, KDialogCommonProps } from 'components/Dialogs/types';

import styles from './KDialogSmall.module.scss';

const createActionKey = (kind: KDialogActionKind, id: number): string => `KDialogSmall-Action-${kind}-${id}`;

export const KDialogSmall: React.FC<KDialogCommonProps> = ({ isOpen, title, message, onClose, actions }) => (
  <Dialog className={styles.dialog} classes={{ paper: styles.dialog }} open={isOpen} onClose={() => onClose}>
    <Typography className={styles.dialogTitle} variant='h1'>
      {title}
    </Typography>
    <Typography className={styles.dialogMessage}>{message}</Typography>
    <div className={styles.dialogActions}>
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.CANCEL)
        .map(({ label, onClick }, index) => (
          <Button
            className={styles.dialogButton}
            key={createActionKey(KDialogActionKind.CANCEL, index)}
            variant='outlined'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.PRIMARY)
        .map(({ label, onClick }, index) => (
          <Button
            className={styles.dialogButton}
            key={createActionKey(KDialogActionKind.PRIMARY, index)}
            variant='contained'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
      {actions
        .filter(({ kind }) => kind === KDialogActionKind.CAUTION)
        .map(({ label, onClick }, index) => (
          <Button
            className={clsx(styles.dialogButton, styles.cautionButton)}
            key={createActionKey(KDialogActionKind.CAUTION, index)}
            variant='contained'
            onClick={() => onClick()}
          >
            {label}
          </Button>
        ))}
    </div>
  </Dialog>
);
