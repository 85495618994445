import { createSlice } from '@reduxjs/toolkit';

import reducers from 'store/currency/reducers';
import { Currency } from 'types/Currency';
import { RequestStatus } from 'types/RequestStatus';

export interface CurrencyState {
  currency: Currency[];
  currencyRequestStatus: RequestStatus;
}

const initialState: CurrencyState = {
  currencyRequestStatus: 'loading',
  currency: [],
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers,
});

export const { setCurrencyData, setCurrencyRequestStatus } = currencySlice.actions;

export default currencySlice.reducer;
