import { ProjectTeamMember } from 'types/PIFProject';
import { NewTeamPositionModel } from 'types/Project/NewTeamPositionModel';
import { TeamPosition } from 'types/Project/TeamPosition';
import { TeamPositionModel } from 'types/Project/TeamPositionModel';

export const mapTeamPositionModeltoTeamPosition = (teamPositionModel: TeamPositionModel[]): TeamPosition[] =>
  teamPositionModel.reduce(
    (
      acc,
      {
        billRate,
        businessDaysBetweenDates,
        costRate,
        currencyId,
        employee,
        employeeId,
        id,
        endDate,
        startDate,
        projectId,
        position,
        positionId,
        skills,
        technologies,
        weeklyForecastedHours,
      },
    ) => {
      if (id) {
        acc.push({
          billRate,
          businessDaysBetweenDates,
          costRate,
          currencyId,
          employee,
          employeeId,
          id,
          endDate,
          startDate,
          projectId,
          position,
          positionId,
          skills,
          technologies,
          weeklyForecastedHours,
        });
      }
      return acc;
    },
    [] as TeamPosition[],
  );

export const mapTeamPositionsToProjectTeamMembers = (teamPosition: TeamPosition[]): ProjectTeamMember[] =>
  teamPosition.reduce<ProjectTeamMember[]>(
    (
      acc,
      {
        billRate,
        currencyId,
        employeeId,
        id,
        positionId,
        endDate,
        startDate,
        skills,
        technologies,
        weeklyForecastedHours,
      },
    ) => {
      acc.push({
        billRate,
        currencyId,
        employeeId,
        id,
        jobPosition: positionId,
        endDate,
        startDate,
        skills: skills?.map(({ id }) => id),
        technologies: technologies?.map(({ id }) => id),
        weeklyForecastedHours,
      });
      return acc;
    },
    [],
  );

export const mapProjectTeamMemberToNewTeamPositionModel = (
  TeamPosition: ProjectTeamMember,
  projectId: number,
): NewTeamPositionModel => {
  const { billRate, currencyId, id, jobPosition, endDate, startDate, skills, technologies, weeklyForecastedHours } =
    TeamPosition;
  return {
    billRate: billRate ? +billRate : 0,
    currencyId,
    id,
    endDate: endDate || '',
    startDate: startDate || '',
    projectId,
    positionId: jobPosition ? +jobPosition : 0,
    skills,
    technologies,
    weeklyForecastedHours: weeklyForecastedHours ? +weeklyForecastedHours : 0,
  };
};
