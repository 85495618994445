// Order

import { IndexSignature } from 'types/utils';

export type Order = 'ASC' | 'DESC';

export type SortingOrderArray = (string | Order)[];

// Where

export enum WhereOperator {
  AND = '$and',
  BETWEEN = '$between',
  CONTAINS = '$contains',
  ENDS_WITH = '$endsWith',
  EQ = '$eq',
  GT = '$gt',
  GTE = '$gte',
  IN = '$in',
  IS = '$is',
  I_LIKE = '$iLike',
  LIKE = '$like',
  LTE = '$lte',
  NE = '$ne',
  NOT = '$not',
  NOT_INT = '$notIn',
  NOT_LIKE = '$notLike',
  OR = '$or',
  STARTS_WITH = '$startsWith',
  SUBSTRING = '$substring',
  NOT_BETWEEN = '$notBetween',
}

export type WhereEnum = string[];

export type WhereEnumTuple = [string, WhereEnum];

export type WhereMatchTuple<K> = [keyof K, WhereOperator, string];

export type WhereNestedObject<T = unknown> = IndexSignature<T> | undefined;

export type WhereObject<T = WhereEnum | WhereNestedObject> = IndexSignature<T> | undefined;
