import { format } from 'date-fns';

export const enFormatDate = (date: Date | string) => format(new Date(date), 'MM/dd/yyyy');

export const enFormatDateIso = (date: Date | string) => format(new Date(date), 'yyyy-dd-MM');

export const enFormatDateResumes = (date: Date | string) => format(new Date(date), 'PP - HH:mm aaaa');

export const enFormatDateMD = (date: Date | string) => format(new Date(date), 'MM/dd');

export const enFormatDateHours = (date: Date) => format(date, 'HH:mm a');

export const enFormatDateMonthYear = (date: Date) => format(date, 'MMMM yyyy');

export const formatStringDatetoISOString = (stringDate: string) => {
  const date = new Date(stringDate);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString();
};
