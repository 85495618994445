import React, { ReactElement } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { Header } from 'components/Header';
import ProjectBuilder from 'modules/Project/views/ProjectBuilder';
import ProjectViewer from 'modules/Project/views/ProjectViewer';

import ProjectDashboard from './views/ProjectDashboard';

export const ProjectRouter = (): ReactElement => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={ProjectBuilder} exact path='/project/new' />
      <Route component={ProjectViewer} exact path={['/project/:id', '/project/:id/edit']} />

      <Route path={path}>
        <Header />
        <Route component={ProjectDashboard} exact path={path} />
      </Route>
    </Switch>
  );
};

const withProjectRoot = (route: string): string => `/project/${route}`;
export const createNewProjectRoute = (): string => withProjectRoot('new');
export const createProjectDetailsRouter = (projectId: string): string => withProjectRoot(`${projectId}`);
