import { Rol } from 'constants/Credentials/Rol';

import { hasRole } from './roles';

export const getRoleLabel = (roles: Rol[]) => {
  if (hasRole(roles, Rol.APPRAISER)) {
    return 'Appraiser';
  }

  if (hasRole(roles, Rol.CO_CREATOR)) {
    return 'Co-Creator';
  }

  return 'Initiator';
};
