import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardContent, Container, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';

import ProjectDetails from 'assets/images/ProjectDetails.svg';
import ProjectTeamImage from 'assets/images/ProjectTeamImage.svg';
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs';
import { KDialogSmall } from 'components/Dialogs/KDialogSmall/KDialogSmall';
import { KDialogActionKind } from 'components/Dialogs/types';
import { TopBar } from 'components/TopBar/TopBar';
import { ProjectStep } from 'constants/ProjectDashboard/pifConstants';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { ProjectDetailsForm } from 'modules/Project/components/NewProjectForm/ProjectDetailsForm/ProjectDetailsForm';
import { ProjectTeam } from 'modules/Project/components/ProjectTeam/ProjectTeam';
import StepInformation from 'modules/Project/components/StepInformation/StepInformation';
import { PIFSchema } from 'modules/Project/views/ProjectBuilder/PIFSchema';
import styles from 'modules/Project/views/ProjectBuilder/ProjectBuilder.module.scss';
import { getBusinessUnits } from 'store/businessUnit/thunk';
import { getContractTypes } from 'store/contractType/thunk';
import { getAllCurrency } from 'store/currency/thunk';
import { getAllCustomers } from 'store/customer/thunk';
import { getAllEmployees } from 'store/employee/thunks';
import { setProjectId } from 'store/projectInitiationForm';
import { selectProjectDetailsData, selectprojectId } from 'store/projectInitiationForm/selectors';
import { createProject, updateProject } from 'store/projectInitiationForm/thunks';
import { getAllSkills } from 'store/skills/thunk';
import { selectTeamPositions } from 'store/teamPosition/selectors';
import { submitProject } from 'store/teamPosition/thunks';
import { PIFProject } from 'types/PIFProject';
import { FileAttached } from 'types/PreviewAttachment';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { mapTeamPositionsToProjectTeamMembers } from 'utils/parsers/teamPosition';

import { getIdsToDelete, shouldDeleteFiles } from '../view.utils';

const initialState: PIFProject = {
  budgetForecasting: null,
  businessUnit: null,
  comments: '',
  contractType: null,
  currencyId: 1,
  customerId: null,
  endDate: null,
  projectName: '',
  projectType: null,
  startDate: null,
  approverId: null,
  totalForecastedHours: null,
  projectTeam: [],
  projectAttachments: [],
};

const breadCrumbsOptions = [
  { id: 1, label: 'Project Initiation Form', path: '/' },
  { id: 2, label: 'New Project' },
];

export const ProjectBuilder = (): ReactElement => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectprojectId);
  const projectDetailsData = useAppSelector(selectProjectDetailsData);
  const [activeStep, setActiveStep] = useState<ProjectStep>(ProjectStep.PROJECT_DETAILS);
  const [exitViewConfirmation, setExitViewConfirmation] = useState(false);
  const [requiresExitConfirmation, setRequiresExitConfirmation] = useState(false);
  const [isPositionDialogOpen, setIsPositionDialogOpen] = useState(false);
  const projectTeamData = useAppSelector(selectTeamPositions);
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<FileAttached[]>([]);

  const { projectAttachments } = projectDetailsData;

  useEffect(() => {
    setFile(projectAttachments ?? []);
  }, [projectAttachments]);

  useEffect(() => {
    dispatch(getAllCurrency());
    dispatch(getAllSkills());
    dispatch(getBusinessUnits());
    dispatch(getContractTypes());
    dispatch(getAllCustomers());
    dispatch(getAllEmployees());
    return () => {
      dispatch(setProjectId(null));
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: PIFSchema,
    onSubmit: () => {
      if (projectId) {
        dispatch(
          submitProject(projectId, {
            onSuccess: () => {
              enqueueSnackbar(
                `${values.projectName} project has been submitted for Approval!`,
                SnackbarVariant.SUCCESS,
              );
              history.push('/');
            },
            onError: () => enqueueSnackbar('Error trying to submit project', SnackbarVariant.ERROR),
          }),
        );
      }
    },
    validateOnChange: true,
  });

  const { dirty, isValid, values, submitForm, setFieldValue } = formik;
  useEffect(() => {
    setFieldValue('projectTeam', mapTeamPositionsToProjectTeamMembers(projectTeamData));
  }, [dispatch, projectTeamData, setFieldValue]);

  const handleClickNext = () => {
    const listOfIdsToDelete = getIdsToDelete(file);
    const isIdsToDelete = shouldDeleteFiles(
      values.projectAttachments instanceof FileList,
      !!projectAttachments?.length ?? false,
      !!values.projectAttachments.length,
    );

    // TODO update only on project changes
    setRequiresExitConfirmation(true);
    dispatch(
      projectId
        ? updateProject(
            values,
            projectId,
            {
              onSuccess: () => enqueueSnackbar(`Project details information has been saved.`, SnackbarVariant.SUCCESS),
              onError: () => enqueueSnackbar('Error trying to save project', SnackbarVariant.ERROR),
            },
            isIdsToDelete ? listOfIdsToDelete : [],
          )
        : createProject(values, {
            onSuccess: () => enqueueSnackbar(`Project details information has been saved.`, SnackbarVariant.SUCCESS),
            onError: () => enqueueSnackbar('Error trying to create project', SnackbarVariant.ERROR),
          }),
    );
    setActiveStep(ProjectStep.TEAM_ROASTER);
  };

  const handleClickBack = () => setActiveStep(ProjectStep.PROJECT_DETAILS);

  const handleAddPosition = () => setIsPositionDialogOpen(true);

  const handlePositionDialogClose = () => setIsPositionDialogOpen(false);

  const handleViewExit = () => {
    if (!requiresExitConfirmation && !dirty) {
      return history.goBack();
    }
    return setExitViewConfirmation(true);
  };

  const handleLeaveProject = () => history.goBack();

  const handleCancelLeaveProject = () => setExitViewConfirmation(false);

  return (
    <Fragment>
      <div className={styles.container}>
        <TopBar staticTitle='Back' onClickBack={handleViewExit} />
        <Box ml='24px' mt='16px'>
          <BreadCrumbs options={breadCrumbsOptions} separator='>' onClickLink={handleViewExit} />
        </Box>
        <Container maxWidth='lg'>
          <Grid className={styles.generalContainer} container justifyContent='center'>
            <Grid className={styles.stepInfo} item xs={12}>
              {activeStep === ProjectStep.PROJECT_DETAILS ? (
                <StepInformation
                  description='Project Details'
                  image={ProjectDetails}
                  message='Fill in all your project details so the reviewer can have a clear view of it.'
                />
              ) : (
                <StepInformation
                  description='Project Team'
                  image={ProjectTeamImage}
                  message='Add the job positions to help you succeed on your new project.'
                />
              )}
            </Grid>

            <Grid className={styles.stepContainer} item>
              <Card>
                <form onSubmit={formik.handleSubmit}>
                  {activeStep === 0 ? (
                    <Fragment>
                      <CardContent className={styles.cardContent}>
                        <ProjectDetailsForm formik={formik} />
                      </CardContent>
                      <CardActions className={styles.cardActions}>
                        <Button
                          className={styles.button}
                          disabled={!(isValid && dirty)}
                          variant='contained'
                          onClick={handleClickNext}
                        >
                          Next
                        </Button>
                      </CardActions>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <CardContent className={styles.cardContent}>
                        <ProjectTeam
                          formik={formik}
                          isOpen={isPositionDialogOpen}
                          onClick={handleAddPosition}
                          onClose={handlePositionDialogClose}
                        />
                      </CardContent>
                      <CardActions
                        className={clsx(styles.cardActions, projectTeamData.length > 0 && styles.cardActions__Table)}
                      >
                        <Button className={clsx(styles.button)} variant='outlined' onClick={handleClickBack}>
                          Back
                        </Button>
                        {projectTeamData.length > 0 ? (
                          <Button className={styles.button} variant='contained' onClick={submitForm}>
                            Submit
                          </Button>
                        ) : (
                          <Button className={styles.button} variant='contained' onClick={handleAddPosition}>
                            Add New Position
                          </Button>
                        )}
                      </CardActions>
                    </Fragment>
                  )}
                </form>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <KDialogSmall
        actions={[
          {
            label: 'Cancel',
            kind: KDialogActionKind.CANCEL,
            onClick: () => handleCancelLeaveProject(),
          },
          {
            label: 'Leave',
            kind: KDialogActionKind.PRIMARY,
            onClick: () => handleLeaveProject(),
          },
        ]}
        isOpen={exitViewConfirmation}
        message={`By doing this, the information entered will ${
          requiresExitConfirmation ? 'be saved as Draft' : 'not be saved'
        }`}
        title='Leave project?'
        onClose={() => null}
      />
    </Fragment>
  );
};
