import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Role } from 'types/Project/Role';

import { RolesState } from './index';

const setRolesData = (state: RolesState, action: PayloadAction<Role[]>): void => {
  state.roles = action.payload;
};

const setRolesRequestStatus = (state: RolesState, action: PayloadAction<RequestStatus>): void => {
  state.rolesRequestStatus = action.payload;
};

const reducers = {
  setRolesData,
  setRolesRequestStatus,
};

export default reducers;
