import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { TechnologyType } from 'types/Project/TechnologyType';

import { TechnologyTypesState } from './index';

const setTechnologyTypesData = (state: TechnologyTypesState, action: PayloadAction<TechnologyType[]>): void => {
  state.technologyTypes = action.payload;
};

const setTechnologyTypesRequestStatus = (state: TechnologyTypesState, action: PayloadAction<RequestStatus>): void => {
  state.technologyTypesRequestStatus = action.payload;
};

const reducers = {
  setTechnologyTypesData,
  setTechnologyTypesRequestStatus,
};

export default reducers;
