import React, { useCallback, useState } from 'react';
import { noop } from 'lodash';

interface AnchorStatus {
  [anchor: string]: Element | undefined;
}

export interface IAnchorContext {
  anchorStatus: AnchorStatus;
  closeMenu: (anchorName: string) => void;
  showMenu: (anchorName: string, element: Element) => void;
}

export const AnchorProvider: React.FC = ({ children }) => {
  const [anchorStatus, updateAnchorStatus] = useState<AnchorStatus>({});

  const showMenu = useCallback((anchorName: string, element: Element) => {
    updateAnchorStatus({ [anchorName]: element });
  }, []);

  const closeMenu = useCallback((anchorName: string) => {
    updateAnchorStatus({ [anchorName]: undefined });
  }, []);

  return (
    <AnchorContext.Provider
      value={{
        anchorStatus,
        showMenu,
        closeMenu,
      }}
    >
      {children}
    </AnchorContext.Provider>
  );
};

export const AnchorContext = React.createContext<IAnchorContext>({
  anchorStatus: {},
  showMenu: noop,
  closeMenu: noop,
});
