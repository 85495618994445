import { RootState } from 'store/store';

export const selectProjects = (state: RootState) => state.projectDashboard.projects;

export const selectProjectStatus = (state: RootState) => state.projectDashboard.projectRequestStatus;

export const selectProjectDeleteStatus = (state: RootState) => state.projectDashboard.projectDeleteStatus;

export const selectTotalGlobalProjects = (state: RootState) => state.projectDashboard.totalGlobalProjects;

export const selectTotalGlobalProjectsStatus = (state: RootState) => state.projectDashboard.totalGlobalProjectsStatus;

export const selectTotalProjects = (state: RootState) => state.projectDashboard.totalProjects;
