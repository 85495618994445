import { createSlice } from '@reduxjs/toolkit';

import { Credentials } from 'types/Credentials/Credentials';
import { User } from 'types/User';

import reducers from './reducer';

export const TOKEN_REFRESH_CHECK_INTERVAL = 60000;
export const TOKEN_REFRESH_THRESHOLD = 3600000; // 1 hour
export const LOCAL_STORAGE_CREDENTIALS_KEY = 'credentials';

/*
  Credentials logic:
    - On login, the front puts credentials on a cookie (jwt)
    - The app gets those credentials and saves them to localStorage
    - On consecutive app loads, the source of truth is localStorage
    - When a token gets refreshed, the credentials are updated on localStorage
    - On log out, both localStorage and cookies should be cleared
    - On production, we cannot clear cookies using JS, we need to redirect to an 
      api endpoint so the back instructs the browser to clear it with a header in the response
*/
export const getSavedCredentials = (): Credentials | undefined => {
  let credentials: Credentials | undefined;
  try {
    // Try to get from localstorage
    const localCredentials = localStorage.getItem(LOCAL_STORAGE_CREDENTIALS_KEY);
    if (localCredentials) {
      credentials = JSON.parse(localCredentials);
    }
  } catch (err) {
    console.error('Error getting credentials:', err);
  }

  return credentials;
};

export const setSavedCredentials = (credentials: Credentials): void => {
  localStorage.setItem(LOCAL_STORAGE_CREDENTIALS_KEY, JSON.stringify(credentials));
};

export const clearSavedCredentials = (): void => {
  localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS_KEY);
};

export enum SessionStatus {
  // eslint-disable-next-line no-unused-vars
  initial = 'initial',
  // eslint-disable-next-line no-unused-vars
  fulfilled = 'fulfilled',
  // eslint-disable-next-line no-unused-vars
  logOut = 'logOut',
}

export interface SessionState {
  credentials?: Credentials;
  currentUser?: User;
  errorMessage: string;
  hasError: boolean;
  isAuthenticated: boolean;
  isForgotPasswordSuccessful: boolean;
  isLoading: boolean;
  isRefreshing: boolean;
  isRegisterSuccessful: boolean;
  isSessionConfirmed: boolean;
  status: SessionStatus;
}

const initialState: SessionState = {
  credentials: undefined,
  isSessionConfirmed: false,
  isAuthenticated: false,
  status: SessionStatus.initial,
  hasError: false,
  errorMessage: '',
  isLoading: false,
  isForgotPasswordSuccessful: false,
  isRegisterSuccessful: false,
  isRefreshing: false,
};

initialState.currentUser =
  process.env.REACT_APP_DEVELOPMENT_MODE === 'true'
    ? {
        id: 7,
        name: 'Andres Contreras',
        firstName: 'Andres',
        lastName: 'Contreras',
        isActive: true,
        isEmailConfirmed: true,
        email: 'Andres.Contreras@theksquaregroup.com',
        authType: 'microsoft',
        employeeId: 1594,
      }
    : undefined;

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers,
});

export const {
  logOut,
  confirmSession,
  setCredentials,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
  setHasError,
  setErrorMessage,
  setIsForgotPasswordSuccessful,
  setIsLoading,
  setIsRegisterSuccessful,
  setIsRefreshing,
} = sessionSlice.actions;

export default sessionSlice.reducer;
