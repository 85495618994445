import { noop } from 'lodash';

import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { positionService } from 'services/positionService';
import { setPositionData, setPositionRequestStatus } from 'store/position';
import { AppThunk } from 'store/store';
import { DispatchStatusCallbacks } from 'types/Dispatch/Dispatch';
import { PositionModel } from 'types/models/PositionModel';
import { fieldSorter } from 'utils/fieldSorter';
import { mapPositionModelToPosition } from 'utils/parsers/position';

export const getAllPositions = (): AppThunk => async (dispatch) => {
  dispatch(setPositionRequestStatus(RequestStatus.LOADING));
  try {
    const { count } = await positionService.getAll();
    const opts: ServiceOptions = {
      limit: count,
    };
    const { data } = await positionService.getAll(opts);
    const sortedPositions = fieldSorter(data, 'name');

    const positions = mapPositionModelToPosition(sortedPositions);
    dispatch(setPositionData(positions));
    dispatch(setPositionRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setPositionRequestStatus(RequestStatus.FAILED));
  }
};

export const createPosition =
  (jobPositionData: PositionModel, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setPositionRequestStatus(RequestStatus.LOADING));
    try {
      const {
        data: { name },
      } = await positionService.create(jobPositionData);
      dispatch(setPositionRequestStatus(RequestStatus.SUCCESS));
      onSuccess(name);
    } catch (error) {
      dispatch(setPositionRequestStatus(RequestStatus.FAILED));
      onError();
    }
  };
