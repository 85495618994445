import { Rol } from 'constants/Credentials/Rol';
import { SessionStatus } from 'store/session';
import { RootState } from 'store/store';
import { Credentials } from 'types/Credentials/Credentials';
import { User } from 'types/User';

export const selectSessionCredentials = (state: RootState): Credentials | undefined => state.session.credentials;
export const selectSessionRoles = (state: RootState): Rol[] => state.session.credentials?.roles || [];
export const selectIsSessionConfirmed = (state: RootState): boolean => state.session.isSessionConfirmed;
export const selectLogInStatus = (state: RootState): SessionStatus => state.session.status;
export const selectCurrentUser = (state: RootState): User | undefined => state.session.currentUser;
export const selectIsAuth = (state: RootState): boolean => state.session.isAuthenticated;
export const selectHasError = (state: RootState): boolean => state.session.hasError;
export const selectErrorMessage = (state: RootState): string => state.session.errorMessage;
export const selectIsSessionLoading = (state: RootState): boolean => state.session.isLoading;
export const selectIsForgotPasswordSuccessful = (state: RootState): boolean => state.session.isForgotPasswordSuccessful;
export const selectIsRegisterSuccessful = (state: RootState): boolean => state.session.isRegisterSuccessful;
export const selectIsRefreshing = (state: RootState): boolean => state.session.isRefreshing;
export const selectSessionToken = (state: RootState): string | undefined => state?.session.credentials?.token;
