import { PayloadAction } from '@reduxjs/toolkit';

import { Position } from 'types/Project/Position';
import { RequestStatus } from 'types/RequestStatus';

import { PositionState } from './index';

const setPositionData = (state: PositionState, action: PayloadAction<Position[]>): void => {
  state.position = action.payload;
};

const setPositionRequestStatus = (state: PositionState, action: PayloadAction<RequestStatus>): void => {
  state.positionRequestStatus = action.payload;
};

const reducers = {
  setPositionData,
  setPositionRequestStatus,
};

export default reducers;
