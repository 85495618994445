import React from 'react';
import { Grid } from '@material-ui/core';

import KSkeleton from 'components/KSkeleton/KSkeleton';
import styles from 'modules/Project/components/PIFSkeleton/ProjectDetailsSkeleton/ProjectDetailsSkeleton.module.scss';

export const ProjectDetailsSkeleton = () => (
  <React.Fragment>
    <Grid className={styles.formTitle} item xs={12}>
      <KSkeleton elementHeight='24px' />
    </Grid>

    <Grid className={styles.formField} item xs={12}>
      <KSkeleton amountOfElements={4} elementHeight='46px' spacing={3} type='table' />
    </Grid>

    <Grid className={styles.formTitle} item xs={12}>
      <KSkeleton elementHeight='24px' />
    </Grid>
    <Grid className={styles.formField} item xs={12}>
      <KSkeleton amountOfElements={3} elementHeight='46px' spacing={3} type='table' />
    </Grid>

    <Grid className={styles.formTitle} item xs={12}>
      <KSkeleton elementHeight='24px' />
    </Grid>

    <KSkeleton elementHeight='139px' />
  </React.Fragment>
);
