import React from 'react';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import emptySectionImage from 'assets/images/Da_Woman-01_1.svg';
import styles from 'components/EmptyStateSection/EmptyStateSection.module.scss';

interface EmptyStateSectionProps {
  description: string;
  message?: string;
}

const EmptyStateSection: React.FC<EmptyStateSectionProps> = ({ description, message }: EmptyStateSectionProps) => (
  <Box
    alignItems='center'
    className={styles.emptyStateSection}
    display='flex'
    flexDirection='column'
    justifyContent='flex-start'
    mb='24px'
  >
    <img alt='Empty section' src={emptySectionImage} />
    <Typography className={styles.description}>{description}</Typography>
    {message && <Typography className={styles.message}>{message}</Typography>}
  </Box>
);

export default EmptyStateSection;
