import { PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { SortingOrderArray, WhereEnum, WhereObject } from 'types/Filter/Filter';
import { IndexSignature } from 'types/utils';

import { ProjectFilterState } from './index';

const resetServiceOptions = (state: ProjectFilterState): void => {
  state.options = {};
  state.checkedStatus = undefined;
  state.checkedUnits = undefined;
  state.whereValues = {};
  state.searchValue = '';
};

const setLimit = (state: ProjectFilterState, { payload }: PayloadAction<number>): void => {
  state.options.limit = payload;
};

const setProjectsCurrentPage = (state: ProjectFilterState, { payload }: PayloadAction<number>): void => {
  state.currentPage = payload;
};

const setOrder = (state: ProjectFilterState, { payload }: PayloadAction<SortingOrderArray[] | undefined>): void => {
  state.options.order = payload;
};

const setWhere = (
  state: ProjectFilterState,
  { payload }: PayloadAction<WhereObject | WhereObject<WhereEnum>>,
): void => {
  const nextState = { ...state.options.where, ...payload };
  const { where } = nextState;
  state.options.where = !isEmpty(where) ? where : undefined;
};

const setOffset = (state: ProjectFilterState, { payload }: PayloadAction<number>): void => {
  state.options.offset = payload;
};

const setInclude = (state: ProjectFilterState, { payload }: PayloadAction<unknown[]>): void => {
  state.options.include = payload;
};

const saveWhereQueryState = (
  state: ProjectFilterState,
  { payload }: PayloadAction<IndexSignature<WhereEnum>>,
): void => {
  state.whereValues = { ...state.whereValues, ...payload };
};

const setCheckedUnits = (state: ProjectFilterState, { payload }: PayloadAction<IndexSignature<boolean>>): void => {
  state.checkedUnits = { ...state.checkedUnits, ...payload };
};

const setCheckedProjectStatusValues = (
  state: ProjectFilterState,
  { payload }: PayloadAction<IndexSignature<boolean> | undefined>,
): void => {
  state.checkedStatus = { ...state.checkedStatus, ...payload };
};

const setSearchValue = (state: ProjectFilterState, { payload }: PayloadAction<string>): void => {
  state.searchValue = payload;
};

export default {
  resetServiceOptions,
  setLimit,
  setInclude,
  setWhere,
  setOffset,
  setOrder,
  saveWhereQueryState,
  setCheckedUnits,
  setCheckedProjectStatusValues,
  setSearchValue,
  setProjectsCurrentPage,
};
