import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Department } from 'types/Project/Department';

import { DepartmentsState } from './index';

const setDepartmentsData = (state: DepartmentsState, action: PayloadAction<Department[]>): void => {
  state.departments = action.payload;
};

const setDepartmentsRequestStatus = (state: DepartmentsState, action: PayloadAction<RequestStatus>): void => {
  state.departmentsRequestStatus = action.payload;
};

const reducers = {
  setDepartmentsData,
  setDepartmentsRequestStatus,
};

export default reducers;
