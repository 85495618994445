import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';

import EmptyTeamRoster from 'assets/images/EmptyTeamRoster.svg';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import KSkeleton from 'components/KSkeleton/KSkeleton';
import { Rol } from 'constants/Credentials/Rol';
import { DefaultPermissions } from 'constants/defaultPermissions';
import { RequestStatus } from 'constants/requestStatus';
import { SnackbarVariant } from 'constants/Snackbar/SnackbarVariant';
import { AddNewHeader } from 'modules/Project/components/AddNewHeader/AddNewHeader';
import AddPositionModal from 'modules/Project/components/AddPositionModal/AddPositionModal';
import { CloseEvent } from 'modules/Project/components/AddPositionModal/types';
import EmptyState from 'modules/Project/components/EmptyState/EmptyState';
import NewJobPositionForm from 'modules/Project/components/NewJobPositionForm/NewJobPositionForm';
import NewTechnologyForm from 'modules/Project/components/NewTechnologyForm/NewTechnologyForm';
import { TeamRosterTable } from 'modules/Project/components/TeamRosterTable/TeamRosterTable';
import { selectProjectDetailsData, selectprojectId } from 'store/projectInitiationForm/selectors';
import { selectSessionRoles } from 'store/session/selectors';
import {
  selectTeamPositionCreationStatus,
  selectTeamPositionDeleteStatus,
  selectTeamPositions,
  selectTeamPositionsStatus,
  selectTeamPositionUpdateStatus,
  selectTotalMembers,
} from 'store/teamPosition/selectors';
import {
  createMultipleTeamPosition,
  deleteMultipleTeamPosition,
  deleteTeamPosition,
  getAllTeamPositionsByPIF,
  getTeamPositionsByPIF,
  updateJobPosition,
  updateMultipleTeamPosition,
} from 'store/teamPosition/thunks';
import { setCurrentPage, setLimit, setOffset, setOrder } from 'store/teamRosterFilters';
import { selectCurrentPage, selectLimit, selectTeamRosterFilters } from 'store/teamRosterFilters/selectors';
import { Order } from 'types/Filter/Filter';
import { Permissions } from 'types/Permissions';
import { PIFProject, ProjectTeamMember, ProjectTeamMemberWithId } from 'types/PIFProject';
import { checkTeamPermissions } from 'utils/checkTeamPermissions';
import { offsetOf, orderTuplesOf } from 'utils/filter/filterUtils';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';
import { mapTeamPositionsToProjectTeamMembers } from 'utils/parsers/teamPosition';
import { hasRole } from 'utils/roles';

import styles from './ProjectTeam.module.scss';

interface ProjectTeamProps {
  formik: FormikProps<PIFProject>;
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
}

export const ProjectTeam: FC<ProjectTeamProps> = ({ formik, isOpen, onClick, onClose }: ProjectTeamProps) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectprojectId) || NaN;
  const teamPositionCreationStatus = useAppSelector(selectTeamPositionCreationStatus);
  const teamPositionDeleteStatus = useAppSelector(selectTeamPositionDeleteStatus);
  const teamPositionUpdateStatus = useAppSelector(selectTeamPositionUpdateStatus);
  const projectTeamData = useAppSelector(selectTeamPositions);
  const projectTeam = mapTeamPositionsToProjectTeamMembers(projectTeamData);
  const projectDetailsData = useAppSelector(selectProjectDetailsData);
  const projectTeamPositionsStatus = useAppSelector(selectTeamPositionsStatus);
  const filters = useAppSelector(selectTeamRosterFilters);
  const itemsPerPage = useAppSelector(selectLimit) || 10;
  const currentPage = useAppSelector(selectCurrentPage);
  const totalMembers = useAppSelector(selectTotalMembers);
  const roles = useAppSelector(selectSessionRoles);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [currentPermissions, setCurrentPermissions] = useState<Permissions>(DefaultPermissions);
  const [modalType, setModalType] = useState<string | null>(null);
  const [initialName, setInitialName] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const { values, setFieldValue } = formik;
  const isCoCreator = hasRole(roles, Rol.CO_CREATOR);
  const initialTeamPositionData = useRef(values.projectTeam);

  useEffect(() => {
    if (isOpen && editIndex === null) {
      projectTeam.push({
        billRate: null,
        currencyId: values.currencyId,
        employeeId: null,
        jobPosition: null,
        endDate: null,
        startDate: null,
        skills: [],
        technologies: [],
        weeklyForecastedHours: null,
      });
      setFieldValue('projectTeam', projectTeam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIndex, isOpen, setFieldValue]);

  useEffect(() => {
    dispatch(setOrder(undefined));
    dispatch(setLimit(10));
    dispatch(setOffset(undefined));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamPositionsByPIF(projectId, filters));
  }, [dispatch, projectId, filters]);

  useEffect(() => {
    const projectTeam = mapTeamPositionsToProjectTeamMembers(projectTeamData);
    initialTeamPositionData.current = projectTeam;
  }, [projectTeamData, editIndex]);

  useEffect(() => {
    if (
      teamPositionCreationStatus === RequestStatus.SUCCESS ||
      teamPositionDeleteStatus === RequestStatus.SUCCESS ||
      teamPositionUpdateStatus === RequestStatus.SUCCESS
    ) {
      dispatch(getTeamPositionsByPIF(projectId, filters));
    }
  }, [dispatch, filters, projectId, teamPositionCreationStatus, teamPositionDeleteStatus, teamPositionUpdateStatus]);

  useEffect(() => {
    if (roles && projectDetailsData) {
      setCurrentPermissions(checkTeamPermissions(roles, projectDetailsData.creationStep));
    }
  }, [roles, projectDetailsData]);

  const handleClose = (eventType: CloseEvent) => {
    if (eventType === CloseEvent.CANCEL) {
      setFieldValue('projectTeam', initialTeamPositionData.current);
    }

    if (eventType === CloseEvent.SAVE) {
      // TODO update only on team position changes

      // We need to convert the billRate to number
      const convertedTeamPositions = values.projectTeam.map((teamMember) => ({
        ...teamMember,
        billRate: teamMember.billRate ? Number(teamMember.billRate) : null,
      }));

      const teamPositionsDistribution = convertedTeamPositions.reduce<{
        creates: ProjectTeamMember[];
        updates: ProjectTeamMemberWithId[];
      }>(
        (acum, pos) => {
          if (pos.id) {
            return { ...acum, updates: [...acum.updates, pos as ProjectTeamMemberWithId] };
          }
          return { ...acum, creates: [...acum.creates, pos] };
        },
        {
          creates: [],
          updates: [],
        },
      );

      if (teamPositionsDistribution.creates.length) {
        dispatch(
          createMultipleTeamPosition(teamPositionsDistribution.creates, projectId, {
            onSuccess: (newTeamPositionIds) => {
              enqueueSnackbar('The team positions have been created', SnackbarVariant.SUCCESS);
              teamPositionsDistribution.creates.forEach((createdTeamPosition, index) => {
                if (createdTeamPosition.employeeId && isCoCreator && newTeamPositionIds) {
                  dispatch(
                    updateJobPosition(
                      newTeamPositionIds[index],
                      Number(createdTeamPosition.employeeId),
                      createdTeamPosition.startDate,
                      createdTeamPosition.endDate,
                    ),
                  );
                }
              });
            },
            onError: () => enqueueSnackbar('Error trying to create team positions', SnackbarVariant.ERROR),
          }),
        );
      }

      const updatedTeamPositions = teamPositionsDistribution.updates.filter((teamPosition) => {
        const currentTeamPosition = initialTeamPositionData.current.find(
          (initialTeamPosition) => teamPosition.id === initialTeamPosition.id,
        );
        return !isEqual(teamPosition, currentTeamPosition);
      });

      if (updatedTeamPositions) {
        dispatch(
          updateMultipleTeamPosition(updatedTeamPositions, projectId, {
            onSuccess: () => {
              enqueueSnackbar('The team positions have been updated', {
                ...SnackbarVariant.SUCCESS,
                preventDuplicate: false,
              });
              updatedTeamPositions.forEach((updatedTeamPosition) => {
                if (updatedTeamPosition.employeeId && isCoCreator) {
                  dispatch(
                    updateJobPosition(
                      updatedTeamPosition.id,
                      Number(updatedTeamPosition.employeeId),
                      updatedTeamPosition.startDate,
                      updatedTeamPosition.endDate,
                    ),
                  );
                }
              });
            },
            onError: () => enqueueSnackbar('Error trying to update team positions', SnackbarVariant.ERROR),
          }),
        );
      }

      const deletedTeamPositions = initialTeamPositionData.current.filter(
        (initialTeamPosition) =>
          !convertedTeamPositions.some((convertedTeamPosition) => convertedTeamPosition.id === initialTeamPosition.id),
      );

      if (deletedTeamPositions) {
        dispatch(
          deleteMultipleTeamPosition(deletedTeamPositions as ProjectTeamMemberWithId[], projectId, {
            onSuccess: () => {
              enqueueSnackbar('The team positions have been deleted', SnackbarVariant.SUCCESS);
            },
            onError: () => enqueueSnackbar('Error trying to delete team positions', SnackbarVariant.ERROR),
          }),
        );
      }
    }

    setEditIndex(null);
    dispatch(getAllTeamPositionsByPIF(projectId));
    onClose();
  };

  const handleDelete = (teamMemberId: string) => {
    dispatch(deleteTeamPosition(Number(teamMemberId)));
  };

  const handleEdit = (teamMemberId: string) => {
    const index = projectTeamData.findIndex(({ id }) => id.toString() === teamMemberId);
    setEditIndex(index);
    initialTeamPositionData.current = [...values.projectTeam];
    onClick();
  };

  const handlePaginationChange = (page: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, page)));
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPageChange = (itemsPerPage: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, 1)));
    dispatch(setLimit(itemsPerPage));
    dispatch(setCurrentPage(1));
  };

  const handleColumnSort = (columnName: string | string[] | undefined, order: Order): void => {
    if (columnName && typeof columnName === 'string') {
      const orderValue = { [columnName]: order };
      dispatch(setOrder(orderTuplesOf(orderValue)));
    }
  };

  const handleOpenCreateItem = (type: string | null, value: string) => {
    setModalType(type);
    setInitialName(value);
  };

  const handleCloseCreateItem = () => {
    setModalType(null);
  };

  const renderNewItemForm = () => {
    switch (modalType) {
      case 'jobPosition':
        return <NewJobPositionForm name={initialName} onCancel={handleCloseCreateItem} />;
      case 'technology':
        return <NewTechnologyForm name={initialName} onCancel={handleCloseCreateItem} />;
      default:
        return null;
    }
  };

  const getTeamPositionsTable = () => {
    if (projectTeamPositionsStatus === RequestStatus.FAILED) {
      return (
        <ErrorMessage
          subtitle="We're having trouble loading the info from the server. Check your connection and try again."
          title='Something went wrong'
        />
      );
    }
    if (projectTeamPositionsStatus === RequestStatus.SUCCESS) {
      return (
        <TeamRosterTable
          currentPage={currentPage}
          data={projectTeamData}
          formik={formik}
          itemsPerPage={itemsPerPage}
          totalItems={totalMembers}
          type='positions'
          onColumnSort={handleColumnSort}
          onDelete={handleDelete}
          onEdit={handleEdit}
          onItemsPerPageChange={handleItemsPerPageChange}
          onPaginationChange={handlePaginationChange}
        />
      );
    }
    return <KSkeleton amountOfElements={5} elementHeight='56px' spacing={4} type='table' />;
  };

  return (
    <React.Fragment>
      {projectTeamData.length === 0 ? (
        <EmptyState
          description='There is no dream team yet'
          image={EmptyTeamRoster}
          message='Start creating job positions to get your work on'
        />
      ) : (
        <Box paddingX='98px' paddingY='56px' width='100%'>
          {currentPermissions.canEdit ? (
            <AddNewHeader subtitle='Add New Position' title='Team Roster' onClick={onClick} />
          ) : (
            <Typography className={styles.title}>Team Roster</Typography>
          )}
          <Box mt='72px'>{getTeamPositionsTable()}</Box>
        </Box>
      )}
      <Dialog maxWidth={false} open={isOpen}>
        {!modalType ? (
          <AddPositionModal
            editIndex={editIndex !== null ? editIndex : projectTeamData.length}
            formik={formik}
            isEdited={editIndex !== null}
            onClose={handleClose}
            onCreateItem={handleOpenCreateItem}
          />
        ) : (
          renderNewItemForm()
        )}
      </Dialog>
    </React.Fragment>
  );
};
