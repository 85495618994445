import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Typography } from '@material-ui/core';

import { BreadCrumb } from 'types/BreadCrumb';

import styles from './BreadCrumbs.module.scss';

interface BreadCrumbsProps {
  options: BreadCrumb[];
  separator: string;
  onClickLink?: () => void;
}
export const BreadCrumbs: FC<BreadCrumbsProps> = ({ options, separator, onClickLink }: BreadCrumbsProps) => {
  const handleClick = (event: React.MouseEvent) => {
    if (onClickLink) {
      event.preventDefault();
      onClickLink();
    }
  };

  const getBreadcrumbs = () =>
    options.map(({ id, label, path }) => {
      if (path) {
        return (
          <Link className={styles.link} key={id} to={path} onClick={handleClick}>
            {label}
          </Link>
        );
      }
      return (
        <Typography className={styles.text} key={id} variant='body1'>
          {label}
        </Typography>
      );
    });

  return (
    <Breadcrumbs aria-label='breadcrumb' classes={{ separator: styles.separator }} separator={separator}>
      {getBreadcrumbs()}
    </Breadcrumbs>
  );
};
