import { createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import reducers from './reducers';

export interface ErrorsState {
  error?: AxiosResponse;
}

const initialState: ErrorsState = {};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers,
});

export const { setStatusCode } = errorsSlice.actions;

export default errorsSlice.reducer;
