import * as yup from 'yup';

import { FormFieldErrorMessages } from 'constants/FormFieldErrorMessages';
import { Position } from 'types/Project/Position';

const validName = new RegExp(/(?!^\d+$)^.+$/);

export const JobPositionSchemaFactory = (jobPositionsData: Position[]) =>
  yup.object().shape({
    name: yup
      .string()
      .matches(validName, FormFieldErrorMessages.Required)
      .required('')
      .trim()
      .test('name', function isUnique(value) {
        if (!value) {
          return true;
        }

        const exists = jobPositionsData.find(
          ({ name }) => name.toLowerCase().replace(/\s+/g, '') === value.trim().toLowerCase().replace(/\s+/g, ''),
        );

        if (exists) {
          return this.createError({
            message: 'This position already exists',
            path: 'name',
          });
        }
        return true;
      }),
    acronym: yup.string().matches(validName, FormFieldErrorMessages.Required).required(''),
  });
