import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { employeeService } from 'services/employeeService';
import { fileService } from 'services/fileService';
import {
  setEmployeeCount,
  setEmployeeData,
  setEmployeeRequestStatus,
  setEmployees,
  setEmployeesStatus,
} from 'store/employee';
import { AppThunk } from 'store/store';
import { fieldSorter } from 'utils/fieldSorter';
import { mapEmployeeModelToEmployee, mapEmployeesModelToEmployees } from 'utils/parsers/employee';

export const getAllEmployees = (): AppThunk => async (dispatch) => {
  dispatch(setEmployeesStatus(RequestStatus.LOADING));
  try {
    const { count } = await employeeService.getAll();
    dispatch(setEmployeeCount(count));

    const opts: ServiceOptions = {
      limit: count,
    };
    const { data } = await employeeService.getAll(opts);
    const sortedEmployees = fieldSorter(data, 'firstName', 'lastName');

    const employee = mapEmployeesModelToEmployees(sortedEmployees);
    dispatch(setEmployees(employee));
    dispatch(setEmployeesStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setEmployeesStatus(RequestStatus.FAILED));
  }
};

export const getEmployeeById =
  (id: number): AppThunk =>
  async (dispatch) => {
    dispatch(setEmployeeRequestStatus(RequestStatus.LOADING));
    try {
      const employeeModel = await employeeService.getById(id);
      const file = employeeModel.data.profileImageId
        ? await fileService.getFileById(employeeModel.data.profileImageId as number).catch(() => null)
        : null;
      const employee = mapEmployeeModelToEmployee({ ...employeeModel.data, file: file?.data });
      dispatch(setEmployeeData(employee));
      dispatch(setEmployeeRequestStatus(RequestStatus.SUCCESS));
    } catch (error) {
      dispatch(setEmployeeRequestStatus(RequestStatus.FAILED));
    }
  };
