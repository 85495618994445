import React, { FC } from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';

import styles from 'modules/Project/components/StepInformation/StepInformation.module.scss';

interface StepInformationProps {
  description?: string;
  image: string;
  message?: string;
}

const StepInformation: FC<StepInformationProps> = ({ description, image, message }: StepInformationProps) => (
  <Card className={styles.card}>
    <CardContent className={styles.cardContent}>
      <div>
        <img alt='Info' className={styles.image} src={image} />
      </div>
      <Box ml='32px'>
        <Box>
          <Typography className={styles.description} variant='h1'>
            {description}
          </Typography>
        </Box>
        <Box maxWidth='340px'>
          <Typography className={styles.message} variant='body1'>
            {message}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

export default StepInformation;
