import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { KMenuProps } from 'components/Menus/types';
import { DefaultPermissions } from 'constants/defaultPermissions';
import { Permissions } from 'types/Permissions';

interface EditRowMenuProps {
  permissions?: Permissions;
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
}

export const EditRowMenu: React.FC<EditRowMenuProps & KMenuProps> = ({
  id,
  anchorEl,
  permissions,
  onClose,
  onView,
  onEdit,
  onDelete,
}) => {
  const { canView, canEdit, canDelete } = permissions || DefaultPermissions;
  const handleOnClick = (command: () => void): void => {
    command();
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} id={id} open={anchorEl !== undefined} onClose={onClose}>
      {canView && onView && (
        <MenuItem onClick={() => handleOnClick(onView)}>
          <VisibilityIcon />
          <span>View</span>
        </MenuItem>
      )}
      {canEdit && onEdit && (
        <MenuItem onClick={() => handleOnClick(onEdit)}>
          <EditIcon />
          <span>Edit</span>
        </MenuItem>
      )}
      {canDelete && onDelete && (
        <MenuItem onClick={() => handleOnClick(onDelete)}>
          <DeleteIcon />
          <span>Delete</span>
        </MenuItem>
      )}
    </Menu>
  );
};
