import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Box, Button, TextField, Typography } from '@material-ui/core';

import MSLogo from 'assets/images/ms-symbol.svg';
import Loader from 'components/Loader/Loader';
import { API_BASE_URL } from 'services/baseService';
import { setHasError, setIsLoading } from 'store/session';
import { selectHasError, selectIsAuth, selectIsSessionLoading } from 'store/session/selectors';
import { logInWithEmail, logInWithExchange } from 'store/session/thunks';
import { useAppSelector } from 'utils/hooks/storeHooks';

import styles from './Login.module.scss';

// eslint-disable-next-line no-unused-vars
enum SSOLoginStatus {
  // eslint-disable-next-line no-unused-vars
  NotFound = 'notFound',
  // eslint-disable-next-line no-unused-vars
  Unauthorized = 'unauthorized',
}

const Login = (): ReactElement => {
  const location = useLocation();
  const isAuthenticated = useSelector(selectIsAuth);
  const isLoading = useSelector(selectIsSessionLoading);
  const hasError = useAppSelector(selectHasError);
  const dispatch = useDispatch();

  const [hasSSOError, setHasSSOError] = useState(false);
  const [ssoErrorMessage, setSsoErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Reset session error state on load
    dispatch(setHasError(false));
  }, [dispatch]);

  // Handle SSO callback success
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token !== null) {
      // Get correct auth credentials and redirect to dashboard
      dispatch(logInWithExchange(token));
    }
  }, [location, dispatch]);

  // Handle SSO callback failures
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const status = query.get('status');
    if (status != null) {
      switch (status) {
        case SSOLoginStatus.NotFound:
          setHasSSOError(true);
          setSsoErrorMessage('Sorry, You are not registered in this application.');
          break;
        case SSOLoginStatus.Unauthorized:
          setHasSSOError(true);
          setSsoErrorMessage('Sorry, You are not allowed to access this application.');
          break;
        default:
          setHasSSOError(true);
          setSsoErrorMessage(`Unknown error: ${status}`);
          break;
      }
    }
  }, [location]);

  const handleLoginMs = () => {
    setHasSSOError(false);
    dispatch(setIsLoading(true));
    window.location.replace(`${API_BASE_URL}/microsoftauth/login?platform=pif`);
  };

  const hanldeLoginEmail = (event: React.MouseEvent) => {
    event.preventDefault();
    setHasSSOError(false);
    dispatch(setIsLoading(true));
    dispatch(logInWithEmail(email, password));
  };

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string);
  };

  const handleChangePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPassword(event.target.value as string);
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <Box className={styles.main} display='flex' justifyContent='flex-end'>
      {/* TODO: Chamge for error banner component */}
      {hasSSOError && <div>{ssoErrorMessage}</div>}
      <div className={styles.actionArea}>
        <Typography color='textPrimary' variant='h2'>
          <Box fontWeight='fontWeightBold' m={0}>
            Hello,
          </Box>
        </Typography>
        <Box fontWeight='fontWeightBold' mb={1}>
          <Typography color='primary' variant='h6'>
            Welcome to Project Initiation Form!
          </Typography>
        </Box>
        {hasError && (
          <Box mb={3}>
            <Typography className={styles.errorMessage}>
              Sorry, either your username or password is incorrect! Try again
            </Typography>
          </Box>
        )}
        {process.env.REACT_APP_AUTH_TYPE === 'email' ? (
          <form>
            <Box display='flex' flexDirection='column'>
              <TextField
                className={styles.textFieldEmail}
                label='email'
                name='email'
                value={email}
                variant='outlined'
                onChange={handleChangeEmail}
              />
              <TextField
                className={styles.textFieldPassword}
                label='password'
                name='password'
                type='password'
                value={password}
                variant='outlined'
                onChange={handleChangePassword}
              />
              <Button className={styles.logIn} type='submit' variant='contained' onClick={hanldeLoginEmail}>
                Log in
              </Button>
            </Box>
          </form>
        ) : (
          <Button
            color='secondary'
            startIcon={<img alt='Microsoft Logo' src={MSLogo} />}
            variant='outlined'
            onClick={handleLoginMs}
          >
            Sign in with Microsoft
          </Button>
        )}
      </div>
      <Loader show={isLoading} />
    </Box>
  );
};

export default Login;
