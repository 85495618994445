import { BaseService } from 'services/baseService';
import { BaseModel } from 'types/models/baseModel';
import { TeamPositionModel } from 'types/Project/TeamPositionModel';

import { ResponseData } from './authService';

class TeamPositionService extends BaseService<TeamPositionModel> {
  controllerName = 'pif-project-positions';

  async submit(projectId: number | string): Promise<ResponseData<BaseModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.post<ResponseData<BaseModel>>(`/${this.controllerName}/submit`, {
      projectId,
    });
    return data;
  }

  async assignEmployee(
    id: number,
    employeeId: number | string,
    startDate: string | null,
    endDate?: string | null,
  ): Promise<ResponseData<BaseModel>> {
    this.api.defaults.headers = this.getDefaultHeaders();
    const { data } = await this.api.put<ResponseData<BaseModel>>(`/${this.controllerName}/${id}/assignEmployee`, {
      employeeId,
      startDate,
      endDate,
    });
    return data;
  }
}

export const teamPositionService = new TeamPositionService();
