import { PayloadAction } from '@reduxjs/toolkit';

import { NotificationsState } from 'store/notifications';
import { NotificationEmployee } from 'types/models/NotificationModel';
import { RequestNotificationDropdown } from 'types/Notification';
import { RequestStatus } from 'types/RequestStatus';

const setRequestNotifications = (state: NotificationsState, action: PayloadAction<NotificationEmployee[]>): void => {
  state.requestNotifications = action.payload;
};

const setRequestNotificationsDropdown = (
  state: NotificationsState,
  action: PayloadAction<RequestNotificationDropdown[]>,
): void => {
  state.requestNotificationsDropdown = action.payload;
};

const setRequestNotificationsRecord = (
  state: NotificationsState,
  action: PayloadAction<NotificationEmployee[]>,
): void => {
  state.requestNotificationsRecord = action.payload;
};

const setStatusNotificationsDropdown = (
  state: NotificationsState,
  action: PayloadAction<NotificationEmployee[]>,
): void => {
  state.statusNotificationsDropdown = action.payload;
};

const setStatusNotificationsRecord = (
  state: NotificationsState,
  action: PayloadAction<NotificationEmployee[]>,
): void => {
  state.statusNotificationsRecord = action.payload;
};

const setDropdownNotificationRequestStatus = (
  state: NotificationsState,
  action: PayloadAction<RequestStatus>,
): void => {
  state.dropdownRequestStatus = action.payload;
};

const setRecordNotificationRequestStatus = (state: NotificationsState, action: PayloadAction<RequestStatus>): void => {
  state.recordRequestStatus = action.payload;
};

const setNotificationsUpdateStatus = (state: NotificationsState, action: PayloadAction<RequestStatus>): void => {
  state.updateStatus = action.payload;
};

const setOffsetIncrement = (state: NotificationsState, action: PayloadAction<number>): void => {
  state.offsetIncrement = action.payload;
};

const setIsPageLimit = (state: NotificationsState, action: PayloadAction<boolean>): void => {
  state.isPageLimit = action.payload;
};

const reducers = {
  setDropdownNotificationRequestStatus,
  setIsPageLimit,
  setNotificationsUpdateStatus,
  setOffsetIncrement,
  setRecordNotificationRequestStatus,
  setRequestNotifications,
  setRequestNotificationsDropdown,
  setRequestNotificationsRecord,
  setStatusNotificationsDropdown,
  setStatusNotificationsRecord,
};

export default reducers;
