import { CustomerModel } from 'types/models/CustomerModel';
import { Customer } from 'types/Project/Customer';

export const mapCustomerModelToCustomer = (CustomerModel: CustomerModel[]): Customer[] =>
  CustomerModel.reduce<Customer[]>((acc, { name, id, isActive }) => {
    if (id) {
      acc.push({
        isActive,
        id,
        label: name,
      });
    }
    return acc;
  }, []);
