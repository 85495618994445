import React, { FC, useState } from 'react';
import NumberFormat from 'react-number-format';
import { FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldInputProps, FormikErrors, FormikValues, getIn } from 'formik';
import { isNil } from 'lodash';

import styles from 'modules/Project/components/KMoneyField/KMoneyField.module.scss';
import { Currency } from 'types/Currency';

interface KMoneyFieldProps {
  currencyName: string;
  currencyOptions: Currency[];
  errors: FormikErrors<FormikValues>;
  isDisabled?: boolean;
  label: string;
  name: string;
  values: FormikValues;
  getFieldProps: (field: string) => FieldInputProps<number>;
  setFieldValue: (field: string, value: number) => void;
}

interface NumberFormatterProps {
  decimalScale: number;
  name: string;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const NumberFormatter = (props: NumberFormatterProps) => {
  const { inputRef, onChange, decimalScale = 0, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={decimalScale}
      fixedDecimalScale
      getInputRef={inputRef}
      isNumericString
      thousandSeparator
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
};

export const KMoneyField: FC<KMoneyFieldProps> = ({
  currencyName,
  currencyOptions,
  errors,
  isDisabled = false,
  label,
  name,
  values,
  getFieldProps,
  setFieldValue,
}: KMoneyFieldProps) => {
  const [currency, setCurrency] = useState<string>(
    currencyOptions.find(({ id }) => id === getIn(values, currencyName))?.currency || currencyOptions[0].currency,
  );

  const [symbol, setSymbol] = useState<string>(
    currencyOptions.find(({ id }) => id === getIn(values, currencyName))?.currencySymbol ||
      currencyOptions[0].currencySymbol,
  );

  const [shrink, setShrink] = useState<boolean>(false);

  const handleChangeCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
    const currency = event.target.value as string;
    const { currencySymbol, id } =
      currencyOptions.find((option: Currency) => option.currency === currency) || currencyOptions[0];
    setCurrency(currency);
    setSymbol(currencySymbol.toString());
    setFieldValue(currencyName, id);
  };

  const disabledExpandMoreIcon = () => <ExpandMoreIcon style={{ display: 'none' }} />;

  const hasValue = () => {
    const val = getIn(values, name);
    return !isNil(val);
  };

  return (
    <Grid container>
      <Grid className={styles.numberInputContainer} item>
        <TextField
          {...getFieldProps(name)}
          InputLabelProps={{ shrink: hasValue() ? true : shrink, className: styles.label }}
          InputProps={{
            inputComponent: NumberFormatter as never,
            classes: { root: styles.numberInput },
            inputProps: { prefix: symbol },
          }}
          aria-label={label}
          error={!!getIn(errors, name)}
          fullWidth
          helperText={getIn(errors, name)}
          id={name}
          label={label}
          name={name}
          placeholder={`${symbol}0`}
          type='text'
          value={getIn(values, name)}
          variant='outlined'
          onBlur={(_event: React.ChangeEvent) => setShrink(false)}
          onFocus={(_event: React.ChangeEvent) => setShrink(true)}
        />
      </Grid>
      <Grid>
        <FormControl>
          <Select
            IconComponent={isDisabled ? disabledExpandMoreIcon : ExpandMoreIcon}
            MenuProps={{
              anchorOrigin: {
                horizontal: 'left',
                vertical: 'bottom',
              },
              elevation: 0,
              getContentAnchorEl: null,
              MenuListProps: { disablePadding: true },
              PaperProps: { className: styles.dropdown },
            }}
            classes={{ root: styles.currency, icon: styles.icon, iconOpen: styles.iconOpen }}
            disableUnderline
            disabled={isDisabled}
            id='select-currency'
            renderValue={(value) => {
              const item = currencyOptions.find(({ currency }) => currency === value);
              return item?.currencyCode;
            }}
            value={currency}
            variant='filled'
            onChange={handleChangeCurrency}
          >
            {currencyOptions.map(({ id, currency }) => (
              <MenuItem key={`${id}-currency`} value={currency}>
                {currency}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
