import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Option } from 'types/Form/FormCommons';

import reducers from './reducers';

export interface BusinessUnitState {
  businessUnitByRoleOptions: Option<number>[];
  businessUnitOptions: Option<number>[];
  businessUnitStatus: RequestStatus;
}

const initialState: BusinessUnitState = {
  businessUnitOptions: [],
  businessUnitByRoleOptions: [],
  businessUnitStatus: RequestStatus.LOADING,
};

export const projectsSlice = createSlice({
  name: 'businessUnit',
  initialState,
  reducers,
});

export const { setBusinessUnitStatus, setBusinessUnitOptions, setBusinessUnitsByRoleOptions } = projectsSlice.actions;

export default projectsSlice.reducer;
