import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';

import { TeamRosterAppraiserTable } from 'modules/Project/components/TeamRosterTable/TeamRosterAppraiserTable';
import { selectprojectId } from 'store/projectInitiationForm/selectors';
import { selectTeamPositions, selectTotalMembers } from 'store/teamPosition/selectors';
import { getTeamPositionsByPIF } from 'store/teamPosition/thunks';
import { setCurrentPage, setLimit, setOffset, setOrder } from 'store/teamRosterFilters';
import { selectCurrentPage, selectLimit, selectTeamRosterFilters } from 'store/teamRosterFilters/selectors';
import { Order } from 'types/Filter/Filter';
import { TeamPosition } from 'types/Project/TeamPosition';
import { IndexSignature } from 'types/utils';
import { offsetOf, orderTuplesOf } from 'utils/filter/filterUtils';
import { useAppDispatch, useAppSelector } from 'utils/hooks/storeHooks';

import { OverviewCard } from './OverviewCard/OverviewCard';

import styles from './TeamRosterAppraisal.module.scss';

interface TeamRosterAppraisalProps {
  className: string;
  teamData: TeamPosition[];
}

interface PositionSummary {
  id: number;
  totalHours: number;
  totalMembers: number;
}

const MAX_POSITION_CARDS = 6;

export const TeamRosterAppraisal: React.FC<TeamRosterAppraisalProps> = ({ className, teamData }) => {
  const dispatch = useAppDispatch();
  const [isExpanded, updateExpanded] = useState<boolean>(false);
  const itemsPerPage = useAppSelector(selectLimit) || 10;
  const currentPage = useAppSelector(selectCurrentPage);
  const totalMembers = useAppSelector(selectTotalMembers);
  const projectTeamData = useAppSelector(selectTeamPositions);
  const filters = useAppSelector(selectTeamRosterFilters);
  const projectId = useAppSelector(selectprojectId) || NaN;

  useEffect(() => {
    dispatch(setOrder(undefined));
    dispatch(setLimit(10));
    dispatch(setOffset(undefined));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamPositionsByPIF(projectId, filters));
  }, [dispatch, projectId, filters]);

  const toggleExpanded = (): void => {
    updateExpanded((prevState) => !prevState);
  };

  const handleColumnSort = (columnName: string | string[] | undefined, order: Order): void => {
    if (columnName) {
      if (typeof columnName === 'string') {
        const orderValue = { [columnName]: order };
        dispatch(setOrder(orderTuplesOf(orderValue)));
      } else {
        const orderValue = columnName.reduce((acc, value) => ({ ...acc, [value]: order }), {});
        dispatch(setOrder(orderTuplesOf(orderValue)));
      }
    }
  };

  const handlePaginationChange = (page: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, page)));
    dispatch(setCurrentPage(page));
  };

  const handleItemsPerPageChange = (itemsPerPage: number): void => {
    dispatch(setOffset(offsetOf(itemsPerPage, 1)));
    dispatch(setLimit(itemsPerPage));
    dispatch(setCurrentPage(1));
  };

  const positionGroups = useMemo(
    () =>
      teamData.reduce<IndexSignature<PositionSummary>>((acc, next) => {
        const nextId = next.id;
        const position = next.position?.name as string;
        const nextHours = next.weeklyForecastedHours;
        if (acc[position]) {
          const prevId = acc[position].id;
          const prevHours = acc[position].totalHours;
          const prevMembers = acc[position].totalMembers;
          acc[position] = {
            id: prevId,
            totalHours: prevHours + nextHours,
            totalMembers: prevMembers + 1,
          };
        } else {
          acc[position] = {
            id: nextId,
            totalMembers: 1,
            totalHours: nextHours,
          };
        }
        return acc;
      }, {}),
    [teamData],
  );

  const totalCards = Object.keys(positionGroups).length;

  return (
    <Grid className={clsx(styles.teamRosterAppraisal, className)}>
      <div className={styles.teamRosterAppraisal_Section}>
        <Box className={styles.teamRosterAppraisalTitleContainer}>
          <span className={styles.teamRosterAppraisal_SectionTitle}>Team Roster</span>
        </Box>
        <Grid className={styles.teamRosterAppraisalPositionCardsContainer} container item xs={12}>
          {Object.entries(positionGroups)
            .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
            .slice(0, isExpanded ? totalCards : MAX_POSITION_CARDS)
            .map(([positionName, { id, totalMembers, totalHours }]) => (
              <OverviewCard
                key={`position-${id}-overview`}
                positionName={positionName}
                totalHours={totalHours}
                totalMembers={totalMembers}
              />
            ))}
        </Grid>
        {totalCards > MAX_POSITION_CARDS && (
          <Button className={styles.showMoreButton} disableRipple variant='text' onClick={toggleExpanded}>
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
        )}
      </div>
      <div>
        <Box className={styles.teamRosterAppraisalTitleContainer}>
          <span className={styles.teamRosterAppraisal_SectionTitle}>Description</span>
        </Box>
        <Box>
          <TeamRosterAppraiserTable
            currentPage={currentPage}
            data={projectTeamData}
            itemsPerPage={itemsPerPage}
            totalItems={totalMembers}
            type='positions'
            onColumnSort={handleColumnSort}
            onItemsPerPageChange={handleItemsPerPageChange}
            onPaginationChange={handlePaginationChange}
          />
        </Box>
      </div>
    </Grid>
  );
};
