import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import reducers from 'store/projectInitiationForm/reducers';
import { ProjectDetails } from 'types/Project/Project';

export interface ProjectsState {
  pifCreationStatus: RequestStatus;
  pifUpdateStatus: RequestStatus;
  projectDetailsData: ProjectDetails;
  projectDetailsStatus: RequestStatus;
  projectId: number | null;
  projectSubmitReviewStatus: RequestStatus;
}

const initialState: ProjectsState = {
  pifCreationStatus: RequestStatus.LOADING,
  projectId: null,
  pifUpdateStatus: RequestStatus.LOADING,
  projectDetailsData: {} as ProjectDetails,
  projectDetailsStatus: RequestStatus.LOADING,
  projectSubmitReviewStatus: RequestStatus.IDLE,
};

export const projectsSlice = createSlice({
  name: 'pif',
  initialState,
  reducers,
});

export const {
  setProjectCreationStatus,
  setProjectDetailsData,
  setProjectDetailsStatus,
  setProjectId,
  setProjectUpdateStatus,
  setProjectSubmitReviewStatus,
} = projectsSlice.actions;

export default projectsSlice.reducer;
