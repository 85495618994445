import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { SnackbarProvider } from 'notistack';
import { theme } from 'styles/MuiTheme';

import Loader from 'components/Loader/Loader';
import withSplashScreen from 'components/withSplashScreen/withSplashScreen';
import Login from 'modules/Auth/views/Login/Login';
import NotificationCenterRouter from 'modules/NotificationCenter';
import Project from 'modules/Project';
import { SessionStatus } from 'store/session';
import { selectIsSessionConfirmed, selectLogInStatus } from 'store/session/selectors';
import { initSession } from 'store/session/thunks';

import { AnchorProvider } from './context/AnchorProvider';
import PrivateRoute from './modules/Auth/components/PrivateRoute';

import styles from './styles/snackbars.module.scss';

const App = (): ReactElement => {
  const dispatch = useDispatch();
  const hasSessionBeenChecked = useSelector(selectIsSessionConfirmed);
  const sessionStatus = useSelector(selectLogInStatus);

  // Handle close individual Snackbars
  const notificationsStaticRef = React.useRef<SnackbarProvider>(null);
  const handleSnackbarClose = (key: React.ReactText) => () => {
    notificationsStaticRef.current?.closeSnackbar(key);
  };
  const snackBarDuration = 5000;

  useEffect(() => {
    if (!hasSessionBeenChecked) {
      dispatch(initSession());
    }
  }, [hasSessionBeenChecked, dispatch, sessionStatus]);

  if (sessionStatus === SessionStatus.initial) {
    return <Loader show />;
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        action={(key) => (
          <IconButton aria-label='close' color='inherit' size='small' onClick={handleSnackbarClose(key)}>
            <Close fontSize='small' />
          </IconButton>
        )}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={snackBarDuration}
        classes={{
          root: styles.snackbarRoot,
        }}
        iconVariant={{
          error: <ErrorIcon />,
          info: <InfoIcon />,
          success: <CheckCircleIcon />,
          warning: <WarningIcon />,
        }}
        maxSnack={3}
        ref={notificationsStaticRef}
      >
        <StylesProvider injectFirst>
          <AnchorProvider>
            <CssBaseline />
            <Router>
              {process.env.REACT_APP_DEVELOPMENT_MODE === 'true' ? (
                <Switch>
                  <Route component={Login} path='/login' />
                  <Route component={NotificationCenterRouter} path='/notificationCenter' />
                  <Route component={Project} path='/' />
                </Switch>
              ) : (
                <Switch>
                  <Route component={Login} path='/login' />
                  <PrivateRoute component={NotificationCenterRouter} path='/notificationCenter' />
                  <PrivateRoute component={Project} path='/' />
                </Switch>
              )}
            </Router>
          </AnchorProvider>
        </StylesProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default withSplashScreen(App);
