import { noop } from 'lodash';

import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { projectAppraisalService } from 'services/projectAppraisalService';
import { projectInitiationFormService } from 'services/projectInitiationFormService';
import { AppThunk } from 'store/store';
import { DispatchStatusCallbacks } from 'types/Dispatch/Dispatch';
import { ProjectAppraisalStatus, ProjectStatus } from 'types/Project';
import { ProjectAppraisalModel } from 'types/Project/ProjectAppraisal/ProjectAppraisalModel';
import { mapProjectModelToProject } from 'utils/parsers/project';
import { rejectionNotesSignatureOf } from 'utils/parsers/projectAppraisal';

import {
  setProjectDeleteStatus,
  setProjectRequestStatus,
  setProjectsData,
  setTotalGlobalProjects,
  setTotalGlobalProjectsStatus,
  setTotalProjects,
} from './index';

export const getProjects =
  (opts: ServiceOptions, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectRequestStatus(RequestStatus.LOADING));
    try {
      const { data: projects, count } = await projectInitiationFormService.getAll(opts);

      const rejectedProjectCalls: Promise<ProjectAppraisalModel>[] = projects
        .filter(({ creationStep }) => creationStep === ProjectStatus.REJECTED)
        .map(({ id }) => projectAppraisalService.getByProjectId(id, ProjectAppraisalStatus.REJECTED));

      if (rejectedProjectCalls.length > 0) {
        const projectAppraisals = await Promise.all(rejectedProjectCalls);
        dispatch(setProjectsData(mapProjectModelToProject(projects, rejectionNotesSignatureOf(projectAppraisals))));
      } else {
        dispatch(setProjectsData(mapProjectModelToProject(projects)));
      }

      dispatch(setTotalProjects(count));
      dispatch(setProjectRequestStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectRequestStatus(RequestStatus.FAILED));
      onError(error);
    }
  };

export const getTotalGlobalProjects = (): AppThunk => async (dispatch) => {
  dispatch(setTotalGlobalProjectsStatus(RequestStatus.LOADING));
  try {
    const { count } = await projectInitiationFormService.getAll();

    dispatch(setTotalGlobalProjects(count));
    dispatch(setTotalGlobalProjectsStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setTotalGlobalProjectsStatus(RequestStatus.FAILED));
  }
};

export const deleteProject =
  (id: string, { onSuccess = noop, onError = noop }: DispatchStatusCallbacks): AppThunk =>
  async (dispatch) => {
    dispatch(setProjectDeleteStatus(RequestStatus.LOADING));
    try {
      await projectInitiationFormService.delete(id);
      dispatch(setProjectDeleteStatus(RequestStatus.SUCCESS));
      onSuccess();
    } catch (error) {
      dispatch(setProjectDeleteStatus(RequestStatus.FAILED));
      onError(error);
    }
  };
