import { createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Department } from 'types/Project/Department';

import reducers from './reducers';

export interface DepartmentsState {
  departments: Department[];
  departmentsRequestStatus: RequestStatus;
}

const initialState: DepartmentsState = {
  departments: [],
  departmentsRequestStatus: RequestStatus.LOADING,
};

export const departmentsSlice = createSlice({
  name: 'department',
  initialState,
  reducers,
});

export const { setDepartmentsData, setDepartmentsRequestStatus } = departmentsSlice.actions;

export default departmentsSlice.reducer;
