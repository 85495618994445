import { RequestStatus } from 'constants/requestStatus';
import { ServiceOptions } from 'services/baseService';
import { technologyTypeService } from 'services/technologyTypeService';
import { AppThunk } from 'store/store';
import { setTechnologyTypesData, setTechnologyTypesRequestStatus } from 'store/technologyTypes';
import { fieldSorter } from 'utils/fieldSorter';
import { mapTechnologyTypeModelToTechnologyTypes } from 'utils/parsers/technologyTypes';

export const getAllTechnologyTypes = (): AppThunk => async (dispatch) => {
  dispatch(setTechnologyTypesRequestStatus(RequestStatus.LOADING));
  try {
    const { count } = await technologyTypeService.getAll();
    const opts: ServiceOptions = {
      limit: count,
    };
    const { data } = await technologyTypeService.getAll(opts);
    const sortedTechnologyTypes = fieldSorter(data, 'name');

    const technologies = mapTechnologyTypeModelToTechnologyTypes(sortedTechnologyTypes);
    dispatch(setTechnologyTypesData(technologies));
    dispatch(setTechnologyTypesRequestStatus(RequestStatus.SUCCESS));
  } catch (error) {
    dispatch(setTechnologyTypesRequestStatus(RequestStatus.FAILED));
  }
};
