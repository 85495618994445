import { TechnologyModel } from 'types/models/TechnologyModel';
import { Technology } from 'types/Project/Technology';

export const mapTechnologyModelToTechnologies = (technologyModels: TechnologyModel[]): Technology[] =>
  technologyModels.reduce<Technology[]>(
    (acc, { id, name, description }) => (id ? [...acc, { id, name, description }] : acc),
    [],
  );

export const mapTechnologiesToOptions = (technologies: Technology[], currentTechnologies: number[]) =>
  technologies
    .filter((item) => currentTechnologies?.includes(item.id))
    .map(({ id, name }) => ({ label: name, value: id }));
