export enum KDialogActionKind {
  CANCEL,
  PRIMARY,
  CAUTION,
}

export interface KDialogAction {
  disabled?: boolean;
  kind: KDialogActionKind;
  label: string;
  onClick: () => void;
}

export interface KDialogCommonProps {
  actions: KDialogAction[];
  isLarge?: boolean;
  isOpen: boolean;
  message: string;
  title: string;
  onClose: () => void;
}
