import { Rol } from 'constants/Credentials/Rol';
import { Permissions } from 'types/Permissions';
import { ProjectStatus } from 'types/Project';

import { hasRole } from './roles';

export const checkTeamPermissions = (roles: Rol[], projectStatus: ProjectStatus): Permissions => {
  const canModify =
    (hasRole(roles, Rol.PROJECT_INITIATOR) &&
      (projectStatus === ProjectStatus.DRAFT ||
        projectStatus === ProjectStatus.PENDING ||
        projectStatus === ProjectStatus.REJECTED)) ||
    (hasRole(roles, Rol.CO_CREATOR) &&
      (projectStatus === ProjectStatus.PENDING || projectStatus === ProjectStatus.APPRAISING));
  return {
    canDelete: canModify,
    canEdit: canModify,
    canView: true,
  };
};
