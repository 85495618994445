import React, { useState } from 'react';
import { Box, Container, IconButton, Popover, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

import { CircleIconButton } from 'components/CircleIconButton/CircleIconButton';
import { Filler } from 'components/Filler/Filler';
import { PIFTooltip, TooltipKind } from 'components/Tooltips/PIFTooltip';
import { StatusCell } from 'modules/Project/components/Status/StatusCell/StatusCell';
import { StatusNumber } from 'modules/Project/components/Status/StatusNumber/StatusNumber';
import { ProjectStatus } from 'types/Project';

import styles from './DashboardHelpPopover.module.scss';

interface iconTooltipMetadata {
  kind: TooltipKind;
  title: React.ReactElement;
}

const ICON_TOOLTIP_METADATA: iconTooltipMetadata = {
  kind: 's',
  title: <React.Fragment>Information</React.Fragment>,
};

export const DashboardHelpPopover: React.FC = () => {
  const [anchor, updateAnchor] = useState<Element>();

  const handleClosePopover = (): void => {
    updateAnchor(undefined);
  };

  return (
    <React.Fragment>
      <Box className={styles.dashboardHelpMenu}>
        <PIFTooltip kind={ICON_TOOLTIP_METADATA.kind} title={ICON_TOOLTIP_METADATA.title}>
          <span>
            <CircleIconButton
              className={styles.dashboardHelpMenuHelpIcon}
              onClick={(e) => updateAnchor(e.currentTarget)}
            >
              <i>i</i>
            </CircleIconButton>
          </span>
        </PIFTooltip>
      </Box>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        classes={{ paper: styles.dashboardHelpMenuPaper }}
        getContentAnchorEl={null}
        id='DashboardHelpPopover'
        open={anchor !== undefined}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClosePopover}
      >
        <Container className={styles.dashboardHelpMenuContent}>
          <Box className={styles.dashboardHelpMenuContentHeading}>
            <Typography variant='h1'>How Project Initiator Form works?</Typography>
            <Box>
              <IconButton className={styles.dashboardHelpMenuContentButton} onClick={handleClosePopover}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box className={styles.dashboardHelpMenuContentSteps}>
            <Box className={styles.dashboardHelpMenuContentSteps_Step}>
              <Box className={styles.dashboardHelpMenuContentSteps_Step__heading}>
                <StatusNumber status={ProjectStatus.DRAFT} />
                <StatusCell status={ProjectStatus.DRAFT} withTooltip={false} />
              </Box>
              <Box className={clsx(styles.dashboardHelpMenuContentSteps_Step__content, styles.draftContent)}>
                <Filler variant='vertical' />
                <p>
                  A project made by <i>PMO or Sales</i> that hasn’t been finished or Submitted.
                </p>
              </Box>
            </Box>
            <Box className={styles.dashboardHelpMenuContentSteps_Step}>
              <Box className={styles.dashboardHelpMenuContentSteps_Step__heading}>
                <StatusNumber status={ProjectStatus.PENDING} />
                <StatusCell status={ProjectStatus.PENDING} withTooltip={false} />
              </Box>
              <Box className={clsx(styles.dashboardHelpMenuContentSteps_Step__content, styles.onHoldContent)}>
                <Filler variant='vertical' />
                <p>
                  A project that is waiting for the <i>Head of Engineering or Director of Salesforce Mexico</i> to
                  Review it.
                </p>
              </Box>
            </Box>
            <Box className={styles.dashboardHelpMenuContentSteps_Step}>
              <Box className={styles.dashboardHelpMenuContentSteps_Step__heading}>
                <StatusNumber status={ProjectStatus.APPRAISING} />
                <StatusCell status={ProjectStatus.APPRAISING} withTooltip={false} />
              </Box>
              <Box className={clsx(styles.dashboardHelpMenuContentSteps_Step__content, styles.onHoldContent)}>
                <Filler variant='vertical' />
                <p>
                  A project that is waiting for the <i>Head of Finance</i> to be Appraised.
                </p>
              </Box>
            </Box>
            <Box className={styles.dashboardHelpMenuContentSteps_Step}>
              <Box className={styles.dashboardHelpMenuContentSteps_Step__heading}>
                <StatusNumber status={ProjectStatus.APPROVED} />
                <StatusCell status={ProjectStatus.APPROVED} withTooltip={false} />
              </Box>
              <Box className={clsx(styles.dashboardHelpMenuContentSteps_Step__content, styles.approvedContent)}>
                <p>
                  Represents that a project is viable and was Approved by <i>Finance</i>.
                </p>
              </Box>
            </Box>
            <Box className={styles.dashboardHelpMenuContentSteps_Step}>
              <Box className={styles.dashboardHelpMenuContentSteps_Step__heading}>
                <StatusNumber status={ProjectStatus.REJECTED} />
                <StatusCell status={ProjectStatus.REJECTED} withTooltip={false} />
              </Box>
              <Box className={clsx(styles.dashboardHelpMenuContentSteps_Step__content, styles.rejectedContent)}>
                <p>
                  Represents that a project is not viable and was Rejected by{' '}
                  <i>Head of Engineering/Director of Salesforce Mexico or Finance</i>.
                </p>
              </Box>
            </Box>
          </Box>
        </Container>
      </Popover>
    </React.Fragment>
  );
};
