import { FileAttached } from 'types/PreviewAttachment';

export const getIdsToDelete = (files: FileAttached[]) => {
  if (!files.length) return [];
  return files.map(({ id }) => id || -1);
};

export const shouldDeleteFiles = (isFileList: boolean, hasFileLength: boolean, hasFileListLength: boolean) => {
  if (!isFileList && hasFileLength) {
    if (!hasFileListLength) {
      return true;
    }
  }
  if (isFileList && hasFileLength) {
    return true;
  }
  return false;
};
