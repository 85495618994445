import { differenceInBusinessDays } from 'date-fns';

interface CalculateTeamMemberTotalHoursParams {
  endDate: Date;
  startDate: Date;
  weeklyForecastedHours: number;
}

export const calculateWeeklyForecastedHours = ({
  startDate,
  endDate,
  weeklyForecastedHours,
}: CalculateTeamMemberTotalHoursParams): number => {
  const numberOfDays = differenceInBusinessDays(endDate, startDate);
  const businessDaysInAWeek = 5;
  const dailyForecastedHours = weeklyForecastedHours / businessDaysInAWeek;
  return Math.round(numberOfDays * dailyForecastedHours);
};
