import React, { useEffect, useState } from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import styles from './KPaginationHeader.module.scss';

interface KPaginationHeaderProps {
  currentPage: number;
  displayRowsPerPage: boolean;
  filterArray: number[];
  itemsPerPage: number;
  totalItems: number;
  typePerPage: string;
  typeShowed: string;
  onChangeItemsPerPage: (items: number) => void;
}

export const KPaginationHeader: React.FC<KPaginationHeaderProps> = ({
  typePerPage = 'Rows',
  onChangeItemsPerPage,
  itemsPerPage,
  filterArray,
  currentPage,
  totalItems,
  typeShowed,
  displayRowsPerPage,
}) => {
  const [intervalStart, updateIntervalStart] = useState<number>(0);
  const [intervalEnd, updateIntervalEnd] = useState<number>(0);

  const handleChangeItemsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeItemsPerPage(event.target.value as number);
  };

  useEffect(() => {
    updateIntervalStart((currentPage - 1) * itemsPerPage + 1);
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    updateIntervalEnd(Math.min(currentPage * itemsPerPage, totalItems));
  }, [currentPage, itemsPerPage, totalItems]);

  return (
    <Box className={styles.paginationHeader}>
      {totalItems > 0 && (
        <Box className={styles.paginationHeaderShowingCount}>
          <Typography>{`Showing ${intervalStart}-${intervalEnd} of ${totalItems} ${typeShowed}(s)`}</Typography>
        </Box>
      )}
      {displayRowsPerPage && (
        <Box className={styles.paginationHeaderRowsForm}>
          <Typography className={styles.textPerPage}>{`${typePerPage} per page:`}</Typography>
          <FormControl className={styles.selectContainer}>
            <Select
              IconComponent={ExpandMore}
              MenuProps={{
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                getContentAnchorEl: null,
                transformOrigin: {
                  vertical: 'center',
                  horizontal: 'right',
                },
                classes: { paper: styles.menuPaper },
              }}
              className={styles.selectContainer__select}
              classes={{ selectMenu: styles.selectContainer__input, icon: styles.selectContainer__filterIcon }}
              disableUnderline
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={(value) => value as number}
              value={itemsPerPage}
              onChange={handleChangeItemsPerPage}
            >
              {filterArray.map((number) => (
                <MenuItem className={styles.menuItem} key={`${number}-Items`} value={number}>
                  View {number} {typePerPage.toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};
