import { PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'constants/requestStatus';
import { Project } from 'types/Project/Project';

import { ProjectsState } from './index';

const setProjectsData = (state: ProjectsState, { payload }: PayloadAction<Project[]>): void => {
  state.projects = payload;
};

const setProjectRequestStatus = (state: ProjectsState, { payload }: PayloadAction<RequestStatus>): void => {
  state.projectRequestStatus = payload;
};

const setProjectDeleteStatus = (state: ProjectsState, { payload }: PayloadAction<RequestStatus>): void => {
  state.projectDeleteStatus = payload;
};

const setTotalGlobalProjects = (state: ProjectsState, { payload }: PayloadAction<number>): void => {
  state.totalGlobalProjects = payload;
};

const setTotalGlobalProjectsStatus = (state: ProjectsState, { payload }: PayloadAction<RequestStatus>): void => {
  state.totalGlobalProjectsStatus = payload;
};

const setTotalProjects = (state: ProjectsState, { payload }: PayloadAction<number>): void => {
  state.totalProjects = payload;
};

const reducers = {
  setProjectsData,
  setProjectRequestStatus,
  setProjectDeleteStatus,
  setTotalGlobalProjects,
  setTotalGlobalProjectsStatus,
  setTotalProjects,
};

export default reducers;
